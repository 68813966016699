import { useEffect, useState, useRef } from "react";
import AppProvider from "../api/appProvider";
import { CurrencyFormat } from "../components/pages/EntryPage/defaultflags";
export function usePrevious(value) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
export function useIsMounted() {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}

export const usePriceCalculator = ({ designDetails, fullpath, showPrice }) => {
  const [price, setPrice] = useState({ Currency: "", Value: "" })

  useEffect(() => {
    if (!fullpath || !window.flags.hasOrderSheet || !showPrice) return;
    if (designDetails.OrderProperties)
      AppProvider.calculatePriceOfRug({ file: fullpath, props: designDetails }).then(price => {
        if (price.Value && price.Value !== "") {
          const value = getPriceToDisplay(price.Value, window.flags.ordersheet.currencyFormat);
          price.Value = value;
          setPrice(price)
        }
      })
  }, [designDetails, fullpath, showPrice])
  return price
}
const FormatCurrency = n => {
  return n.toFixed(2).replace(/./g, function (c, i, a) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
  });
};

const FormatCurrencyEU = num => {
  return String(num).replace(/[,.]/g, function (x) {
    return x === "," ? "." : ",";
  });
};
const getPriceToDisplay = (price, currencyFormat) => {
  let displayPrice = price;
  if (currencyFormat === CurrencyFormat.EU) {
    displayPrice = FormatCurrencyEU(price);
  } else if (currencyFormat === CurrencyFormat.US) {
    displayPrice = FormatCurrency(price);
  }
  return displayPrice;
};

function getSize(isClient) {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };
}
export function useWindowSize() {
  const isClient = typeof window === "object";
  
  const [windowSize, setWindowSize] = useState(getSize(isClient));
  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWindowSize(getSize(isClient));
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient]); // Empty array ensures that effect is only run on mount and unmount
  return windowSize;
}
export const useIntersect = (options) => {
  const [elements, setElements] = useState([]);
  const [entries, setEntries] = useState([]);

  const observer = useRef(null);

  const { root, rootMargin, threshold } = options || {}

  useEffect(() => {
    if (elements.length) {
      console.log('-----CONNECTING OBSERVER------');
      observer.current = new IntersectionObserver((ioEntries) => {
        setEntries(ioEntries);
      }, {
        threshold,
        root,
        rootMargin
      });

      elements.forEach(element => {
        observer.current.observe(element);
      });
    }
    return () => {
      if (observer.current) {
        console.log('-----DISCONNECTING OBSERVER------');
        observer.current.disconnect();
      }
    }
  }, [elements, root, rootMargin, threshold]);

  return [observer.current, setElements, entries];
};