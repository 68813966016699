import { H6 } from "@blueprintjs/core";
import React, { PropTypes, useEffect, useState } from "react";
import AppProvider from "../../../api/appProvider";
import {
  generateHash,
  getDesigns,
  handleDownloadDesign
} from "../../../middleware/visualization.middleware";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designlist.reducer";
import { mainUiActions, useUiDispatch } from "../../../reducers/mainui.reducer";
import { useVisualizationState, visViewModes } from "../../../reducers/visualizations.reducer";
import { makeid } from "../../../utils/utils";
import AtButton from "../../atoms/AtButton";
import AtRadio from "../../atoms/AtRadio";

let createCombinationRunning = false;
let createCombinationProps = {};
let origFiles = [];
let totalFilesCount = 0;
let designDownloading = false;

const CreateCombo = props => {
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const visState = useVisualizationState();
  const dispatchUiState = useUiDispatch();
  const { similarDesignsFolder, selectedFile, selectedFolder } = designListState;

  const designOptions = ["All Designs", "This Design", "This Folder"];
  const roomOptions = ["All Rooms", "This Room", "Design View only", "Random Room"];
  const [designOption, setDesignOption] = useState(designOptions[0]);
  const [roomOption, setRoomOption] = useState(roomOptions[2]);

  useEffect(() => {
    if (selectedFile && createCombinationRunning) {
      createCombinations({ repeat: true });
    }
  }, [selectedFile]);

  const changeDesign = async ({ folder }) => {
    let files;
    if (!origFiles.length) {
      files = await getDesigns({ folder }); //tree[0].files;
    } else {
      files = origFiles;
    }
    let newFile;
    if (totalFilesCount === 0) {
      let nextFileIndex = files.findIndex(file => file.FullPath === selectedFile.fullPath);
      totalFilesCount = nextFileIndex + 1;
      newFile = files[totalFilesCount];
    } else {
      ++totalFilesCount;
      newFile = files[totalFilesCount];
    }
    if (!newFile) {
      totalFilesCount = 0;
      createCombinationRunning = false;
      alert("all designs complete");
      return;
    }
    if (!newFile.designProps) {
      const designProps = await AppProvider.getDesignThumbnails({ designs: [newFile] });
      newFile = { ...newFile, ...designProps };

      dispatchDesignList({
        type: designListActions.SELECT_DESIGN,
        payload: { selectedFile: newFile, activeVariation: null }
      });
    } else {
      dispatchDesignList({
        type: designListActions.SELECT_DESIGN,
        payload: { selectedFile: newFile, activeVariation: null }
      });
    }
  };
  const downloadDesignViews = async props => {
    props.repeat ? (props = createCombinationProps) : (createCombinationProps = props);
    const { folder, thisDesignOnly, repeat } = props;

    createCombinationRunning = false;
    const variationsList = [selectedFile, ...similarDesignsFolder.files];
    const variationsPropsList = await AppProvider.getDesignThumbnails({ designs: variationsList });
    let index = 0;
    let thisloopid = makeid(5);
    const animationLoop = thisloopid;
    let loop = setInterval(async () => {
      if (animationLoop !== thisloopid) {
        clearInterval(loop);
      }
      if (designDownloading) {
        return;
      }
      if (!variationsPropsList[index] || !variationsPropsList[index].designProps) return;
      designDownloading = true;
      const watermarkOptions = {
        hasWatermark: window.flags.designView.hasDesignWaterMark,
        logoUrl: window.InterfaceElements.LogoUrl,
        ...window.flags.designView.watermarkOptions
      };
      //renaming design name in cubinia-blue, cubinia-light gray
      const designName = variationsPropsList[index].name;
      const baseDesign = selectedFile.name;
      var renamedDesign = designName;
      if (designName !== baseDesign) {
        const secondpart = designName.replace(`${baseDesign} `, "");
        renamedDesign = `${baseDesign}-${secondpart}`;
      }
      await handleDownloadDesign({
        zoomlevel: 2,
        watermarkOptions,
        designDetails: variationsPropsList[index].designProps,
        designName: renamedDesign,
        fullpath: variationsPropsList[index].fullPath,
        hash: generateHash(
          variationsPropsList[index].designProps,
          variationsPropsList[index].fullPath
        ),
        felt: 0
      });

      designDownloading = false;
      if (index === variationsList.length - 1) {
        clearInterval(loop);
        createCombinationRunning = true;

        if (thisDesignOnly) {
          totalFilesCount = 0;
          createCombinationRunning = false;
          alert("Download of this design, complete");
          //return;
        } else {
          //roomsIndex = 0;
          changeDesign({ folder });
        }
      } else ++index;
    }, 2 * 1000);
  };
  const createCombinations = () => {
    if (visState.viewMode !== visViewModes.ROOM_VIEW && roomOption !== roomOptions[2]) {
      const toastProps = {
        message: "Please select an illustration first",
        intent: "warning"
      };
      dispatchUiState({ type: mainUiActions.SET_TOAST_PROPS, payload: toastProps });
      return;
    }
    let folder = null,
      thisRoomOnly = false,
      thisDesignOnly = false,
      randomRoom = false;

    folder = designOption === designOptions[2] ? designListState?.selectedFolder?.name : null;
    thisRoomOnly = roomOption === roomOptions[1] ? true : false;
    randomRoom = roomOption === roomOptions[3] ? true : false;
    thisDesignOnly = designOption === designOptions[1] ? true : false;

    if (
      window.createCombination &&
      visState.viewMode === visViewModes.ROOM_VIEW &&
      roomOption !== roomOptions[2]
    )
      window.createCombination({
        folder: folder,
        thisRoomOnly,
        thisDesignOnly: thisDesignOnly,
        randomRoom: randomRoom
      });
    else {
      downloadDesignViews({
        folder: folder,
        thisRoomOnly,
        thisDesignOnly: thisDesignOnly,
        designview: true
      });
    }
  };

  return (
    <>
      <div className="at-create-combo-area">
        <div className="bp3-heading">Options to create combinations</div>
        {designOptions && roomOptions && (
          <div className={`at-create-combo-options-area`}>
            <AtRadio
              groupLabel={"Designs"}
              inline={false}
              onChange={event => {
                setDesignOption(event.currentTarget.value);
              }}
              selectedValue={designOption}
              radioLabels={designOptions}
              radioValues={designOptions}
            />
            <AtRadio
              groupLabel={"Rooms"}
              inline={false}
              onChange={event => {
                setRoomOption(event.currentTarget.value);
              }}
              selectedValue={roomOption}
              radioLabels={roomOptions}
              radioValues={roomOptions}
            />
          </div>
        )}
      </div>
      <div>
        <H6>A Room should be selected to download Room views.</H6>
      </div>
      {window.flags.interface.showInhouseTools?.showCreateCombination && (
        <AtButton
          intent="tertiary"
          onClick={createCombinations}
          text={"Start Creating Combinations"}
        ></AtButton>
      )}
    </>
  );
};

CreateCombo.propTypes = {};

export default CreateCombo;
