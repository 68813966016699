/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  useUiState,
  useUiDispatch,
  mainUiActions,
  pageViews
} from "../../../reducers/mainui.reducer";
import {
  useVisualizationState,
  visualizationsActions,
  visViewModes
} from "../../../reducers/visualizations.reducer";
import { useMedia } from "react-use";
import { AtSpinnerOverlay } from "../../atoms/AtSpinner";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import MyRoom from "../../organisms/MyRoomNew";
// import MyRoom from "../../../MyRoom";
import AppProvider from "../../../api/appProvider";
import RoomViewNew from "../../organisms/RoomViewNew";
import { readJSON } from "../../../utils/utils";
import Visualization from "../Visualization";
import IllustrationView from "../../organisms/IllustrationView";
import VisualizationJpeg from "../Visualization/VisualizationJpeg";
import VisualizationWall from "../Visualization/VisualizationWall";
import ColorSelectionBox from "../ColorSelectionBox";
import InteractivePerspectiveView from "../../organisms/InteractivePerspectiveView";
import classNames from "classnames";
import { H5, Portal, Menu, MenuItem, MenuDivider, Overlay } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
import Thumbnail from "../../molecules/Thumbnail";
import strings from "../../../strings";
import {
  roomViewActions,
  useDispatchRoomView,
  useRoomViewState
} from "../../../reducers/roomview.reducer";
import CatalogView from "./CatalogView";
import WordCloud from "../WordCloud";
import { readImage } from "../../../utils/domUtils";
import { getDesignName } from "../../../utils/treeutils";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designlist.reducer";
import VisualizationCtfResize from "../Visualization/VisualizationCtfResize";
// const customdesignurl =
//   "https://res.cloudinary.com/mrprabal/image/upload/gallery/Festival/14.Holi_-the-festival-of-colours_-began-with-the-installation-of-Chir_-a-wooden-pole_-in-Basantapur_-Kathmandu.jpg";

const Stage = props => {
  const { className, defaultViewMode, setIsFullScreen = () => {} } = props;

  const uiState = useUiState();
  const visualizationState = useVisualizationState();
  const dispatchUiState = useUiDispatch();
  const designDetailState = useDesignDetailState();
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const isDeviceSmall = useMedia("(max-width: 768px)");
  const { customDesignUrl, designHeight, designWidth, unit: designUnit } = window.initialData;

  const stageViewMode = defaultViewMode || visualizationState.viewMode;

  const handleDoubleClick = e => {
    // e.preventDefault();
    // e.stopPropagation();
    if (isDeviceSmall) return;
    const hideSidebars = uiState.showLeftSidebar && uiState.showRightSidebar;
    dispatchUiState({
      type: mainUiActions.SET_SHOW_SIDEBAR,
      payload: { left: !hideSidebars, right: !hideSidebars }
    });
  };
  const setMouseHoveredOnUi = e =>
    dispatchUiState({ type: mainUiActions.SETMOUSE_HOVERED_ONUI, payload: e });
  const hasHash = designDetailState.hash || customDesignUrl;
  const renderView = viewMode => {
    switch (viewMode) {
      case visViewModes.ROOM_VIEW:
        return hasHash && <IllustrationView3D setIsFullScreen={setIsFullScreen} />;
      case visViewModes.MY_ROOM:
        return hasHash && <MyRoom />;
      case visViewModes.DESIGN_VIEW_DEEPZOOM:
      case visViewModes.DESIGN_VIEW_ZERO_ZOOM:
        if (window.InterfaceElements.IsWallToWall)
          return (
            <VisualizationWall wallToWallLockAxis={window.flags.designView.wallToWallLockAxis} />
          );
        if (window.InterfaceElements.IsJpeg) return <VisualizationJpeg />;
        if (window.flags.visualizations.changeCanvasSizeInCtf) return <VisualizationCtfResize />;
        return <Visualization />;
      case visViewModes.DESIGN_VIEW_DEEPZOOM_RX:
        if (window.InterfaceElements.IsWallToWall)
          return <VisualizationWall wallToWallLockAxis="x" />;
        if (window.InterfaceElements.IsJpeg) return <VisualizationJpeg />;
        return <Visualization />;
      case visViewModes.DESIGN_VIEW_PERSPECTIVE:
        return designDetailState.hash && <InteractivePerspectiveView />;
      case visViewModes.DESIGN_VIEW_FOLDEDBACK:
        return designDetailState.hash && <InteractivePerspectiveView view="FoldedBackView" />;
      case visViewModes.CATALOG_VIEW:
        return designDetailState.hash && <CatalogView />;
      default:
        return <></>;
      // return <DesignView />;
    }
  };
  const renderWordCloud = () => {
    return <WordCloud WordCloudData={window.WordCloudData} />;
  };

  const prepareThumbCustomUrl = async () => {
    if (
      customDesignUrl &&
      designListState &&
      Object.keys(designListState.selectedFile).length &&
      designListState.selectedFile.fullPath !== customDesignUrl
    ) {
      const image = await readImage(customDesignUrl);
      const designDimensions = {
        Height: image.height,
        Width: image.width,
        PhysicalHeight: designHeight || image.height,
        PhysicalWidth: designWidth || image.width,
        Unit: designUnit || "px",
        KLRatio: 1
      };
      const name = getDesignName(customDesignUrl);
      const designProps = designDimensions;
      const fullPath = customDesignUrl;
      dispatchDesignList({
        type: designListActions.SELECT_DESIGN,
        payload: { name, designProps, fullPath, thumbUrl: customDesignUrl, id: 123 }
      });
    }
  };

  useEffect(() => {
    {
      window.flags.showThumbnailForCustomDesignUrl && prepareThumbCustomUrl();
    }
  }, [customDesignUrl, designListState]);

  return (
    <div
      className={classNames("stage-container", className)}
      id={props.isRoomviewInFullScreen ? "" : "stage_container"}
      onDoubleClick={handleDoubleClick}
      onMouseOver={() => {
        if (uiState.mouseHoveredOnUi) setMouseHoveredOnUi(false);
      }}
      onMouseOut={() => {
        if (!uiState.mouseHoveredOnUi) setMouseHoveredOnUi(true);
      }}
    >
      {uiState.showWordCloud && window.WordCloudData !== "" && renderWordCloud()}
      {!uiState.showWordCloud && renderView(stageViewMode)}
      {visualizationState.viewMode === visViewModes.ROOM_VIEW &&
        window.flags.interface.showTextInRoomView && (
          <div className="stage-container-text">
            Die generierten Grafiken entsprechen nicht den tatsächlichen Abmessungen und dienen
            lediglich zur Veranschaulichung.
          </div>
        )}

      {/* {renderView()} */}
      {!window.initialData.hideLoadingOverlay ? (
        <AtSpinnerOverlay show={designDetailState.loading} />
      ) : null}
    </div>
  );
};

const IllustrationView3D = props => {
  const visualizationState = useVisualizationState();
  const { activeNode } = visualizationState;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const uiState = useUiState();
  const roomviewState = useRoomViewState();
  const dispatchRoomView = useDispatchRoomView();
  const { floorOptions } = roomviewState;

  const [scaledUpHeight, setScaledUpHeight] = useState(0);
  const [scaledUpWidth, setScaledUpWidth] = useState(0);

  const [roomData, setRoomData] = useState(null);
  const [csbProps, setCsbProps] = useState({});
  const [firstLoadingOnRender, setFirstLoadingOnRender] = useState(true);

  useEffect(() => {
    let testData = false;

    if (!activeNode && activeNode.id === "0") return;
    dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: true });
    let config, baseUrl, roomData;
    const loadRoom = async () => {
      if (testData && process.env.NODE_ENV === "development") {
        roomData = {
          Dir: "temp/bedroom-1",
          Name: "Test",
          Files: [
            "/config.json",
            "/Shot_1.bg.jpg",
            // "/Shot_1.hl.jpg",
            "/Shot_1.m.png",
            // "/Shot_1.pch.png",
            // "/Shot_1.pch.sh.jpg",
            "/Shot_1.sh.jpg"
            // "Shot_1.pch.grey.jpg",
            // "/Shot_1.gl.jpg",
          ]
        };
        baseUrl = roomData.Dir;
        config = await readJSON(`${baseUrl}/config.json`);
      } else {
        try {
          roomData = await AppProvider.fetchRoomDetails({ file: activeNode.FullPath });
          if (!roomData || !roomData.Dir) return;
          roomData = { ...roomData, Name: activeNode.Name };
        } catch (error) {
          return;
        }
        baseUrl = roomData.baseUrl;
        config = await readJSON(`${baseUrl}/config.json`);
      }
      const defaultFloorOption = {
        show: false,
        floors: [],
        activeFloor: {}
      };
      const { floorOptions = defaultFloorOption, scene1 } = config;
      const roomOptions = {
        carpetOptions: {
          rotation: scene1.surface1.rotation,
          position: scene1.surface1.position
        },
        floorOptions
      };
      dispatchRoomView({
        type: roomViewActions.SET_ROOM_OPTIONS,
        payload: roomOptions
      });
      if (activeNode.shot) roomData.shot = activeNode.shot;
      setRoomData({ ...roomData, config, baseUrl });
    };
    loadRoom();
  }, [activeNode]);
  useEffect(() => {
    if (
      designDetailState.designDimsOrig == null ||
      !window.flags.visualizations.showScaleOptionDetails
    )
      return;
    const dims = designDetailState.designDimsOrig;
    setScaledUpHeight(dims.PhysicalHeight);
    setScaledUpWidth(dims.PhysicalWidth);
  }, [designDetailState.designDimsOrig, visualizationState.activeNode]);

  const onRenderingComplete = () => {
    dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: false });
    setFirstLoadingOnRender(false);
  };
  const handleAvailableSizesChange = sizes => {
    dispatchDesignDetails({ type: designDetailActions.SET_AVAIL_SIZES, payload: sizes });
  };
  const handleColorSwatchClick = color => {
    setCsbProps({ ...csbProps, activeColor: color });
  };

  return (
    <div className="illustration-container">
      {firstLoadingOnRender && window.flags.homeTemplate == pageViews.CREATEYOURRUG && (
        <AtSpinnerOverlay show="true" />
      )}
      {window.flags.homeTemplate == pageViews.STUDIOENTRY && <img id="DesignCanvasImage"></img>}
      {roomData &&
        designDetailState &&
        (roomData.config.version > 2 ? (
          <IllustrationView
            isIdle={uiState.isIdle}
            activeColor={csbProps.activeColor}
            handleActiveClick={() => {
              setCsbProps({ show: true });
            }}
            onRenderingComplete={onRenderingComplete}
            roomData={roomData}
            handleAvailableSizesChange={handleAvailableSizesChange}
            selectedSize={designDetailState.illustrationSize}
          />
        ) : (
          <RoomViewNew
            activeColor={csbProps.activeColor}
            handleActiveClick={() => {
              setCsbProps({ show: true });
            }}
            isIdle={uiState.isIdle}
            onRenderingComplete={onRenderingComplete}
            setFirstLoadingOnRender={setFirstLoadingOnRender}
            roomData={roomData}
            setScaledUpHeight={setScaledUpHeight}
            setScaledUpWidth={setScaledUpWidth}
            // activeFloor={floorOptions.activeFloor}
            // carpetRotation={carpetOptions.rotation}
            customDesignUrl={window.initialData.customDesignUrl}
            customDesignOutline={window.initialData.customDesignOutline}
            setIsFullScreen={props.setIsFullScreen}
          />
        ))}
      {csbProps.show && (
        <div
          className="at-illustration-popups-container"
          onClick={() => {
            setCsbProps({ ...csbProps, show: false });
          }}
        >
          <ColorSelectionBox
            onColorSwatchClick={handleColorSwatchClick}
            renderTexturedPreview={false}
            onClick={e => {
              e.stopPropagation();
            }}
            onClose={() => {
              setCsbProps({ ...csbProps, show: false });
            }}
          />
        </div>
      )}
      <Overlay
        autoFocus={false}
        enforceFocus={false}
        className="at-illustration-popups-container"
        isOpen={floorOptions.show}
        hasBackdrop={false}
        usePortal={false}
        onClose={() => {
          dispatchRoomView({
            type: roomViewActions.SET_SHOW_FLOOR_OPTION,
            payload: false
          });
        }}
      >
        <div className="at-illustration-popup">
          <H5>{strings.visualization.selectFloor}</H5>
          {floorOptions.floors.map((floor, i) => (
            <Thumbnail
              thumb={{ thumbUrl: floor.path, name: floor.name }}
              active={floor.path === floorOptions.activeFloor.path}
              key={i}
              aspect="landscape"
              onThumbnailClick={e => {
                dispatchRoomView({
                  type: roomViewActions.SET_ACTIVE_FLOOR_OPTION,
                  payload: floor
                });
              }}
            />
          ))}
        </div>
      </Overlay>
      {roomData && (
        <IllustrationOptions
          roomData={roomData}
          scaledUpHeight={scaledUpHeight}
          scaledUpWidth={scaledUpWidth}
        />
      )}
    </div>
  );
};
export default Stage;

const IllustrationOptions = props => {
  const { roomData, scaledUpHeight, scaledUpWidth } = props;
  const { config } = roomData;
  const { roomType, scene1 } = config;
  const dispatchRoomView = useDispatchRoomView();
  const roomviewState = useRoomViewState();
  const { floorOptions, carpetOptions } = roomviewState;
  const [showMenu, setShowMenu] = useState(false);

  const hasOptions = roomType !== "illustration" || floorOptions.floors.length > 0;
  const hasIlluOptions = floorOptions.floors.length > 0;
  const tooltipProps = { position: "bottom" };

  const setCarpetOption = carpetOption =>
    dispatchRoomView({
      type: roomViewActions.SET_CARPET_OPTION,
      payload: carpetOption
    });
  const handleRotation = angle => {
    const { rotation } = carpetOptions;
    let newRotation = [rotation[0], rotation[1], rotation[2] + angle];
    const newCarpetOptions = { ...carpetOptions, rotation: newRotation };
    setCarpetOption(newCarpetOptions);
  };
  const handleResetRotation = () => {
    const newCarpetOptions = { ...carpetOptions, rotation: scene1.surface1.rotation };
    setCarpetOption(newCarpetOptions);
  };

  const handleShowFloor = () =>
    dispatchRoomView({
      type: visualizationsActions.SET_SHOW_FLOOR_OPTION,
      payload: true
    });
  const handleResetPosition = () => {
    const newCarpetOptions = { ...carpetOptions, position: scene1.surface1.position };
    setCarpetOption(newCarpetOptions);
    window.scaleCarpet(0);
  };
  window.resetCarpet = async () => {
    handleResetPosition();
  };
  const renderFloorOption = path => (
    <div className="at-illu-option-item">
      {path ? (
        <img src={path} alt="" className="at-illu-option-item-image" />
      ) : (
        <div className="at-illu-option-item-image" />
      )}
      {strings.visualization.changeFloor}
    </div>
  );
  const scaleOptions = (
    <div className="at-carpet-option-scale">
      {strings.visualization.scale}
      <div className="at-carpet-option-scale-controls">
        {window.flags.visualizations.showScaleOptionDetails && (
          <p>
            {" "}
            {scaledUpWidth}' X {scaledUpHeight}'
          </p>
        )}
        <AtButton
          tooltip={strings.visualization.scaleUp}
          tooltipProps={tooltipProps}
          icon="expand"
          onClick={() => window.scaleCarpet(1)}
        />
        <AtButton
          tooltip={strings.visualization.scaleDown}
          tooltipProps={tooltipProps}
          icon="collapse"
          onClick={() => window.scaleCarpet(-1)}
        />
        <AtButton
          tooltip={strings.tools.reset}
          icon="reset"
          tooltipProps={tooltipProps}
          onClick={() => window.scaleCarpet(0)}
        />
      </div>
    </div>
  );
  const rotationOptions = (
    <div className="at-carpet-option-rotation">
      {strings.visualization.rotation}
      <div className="at-carpet-option-rotation-controls">
        <AtButton
          tooltip={strings.visualization.rotateCW}
          tooltipProps={tooltipProps}
          icon="rotate-cw"
          onClick={() => handleRotation(-15)}
        />
        <AtButton
          tooltip={strings.visualization.rotateCCW}
          tooltipProps={tooltipProps}
          icon="rotate-ccw"
          onClick={() => handleRotation(15)}
        />
        <AtButton
          tooltip={strings.tools.reset}
          icon="reset"
          tooltipProps={tooltipProps}
          onClick={handleResetRotation}
        />
      </div>
    </div>
  );

  const container = document.getElementById("right-sidebar")
    ? document.getElementById("right-sidebar")
    : document.getElementById("stage_container");

  const saveCustomDesignRoom = () => {
    window.downloadRendered3dIll();
  };

  return (
    <Portal container={container} className="at-illu-options-portal">
      {showMenu && (
        <Menu className="at-illu-options-menu">
          {hasIlluOptions && (
            <>
              <MenuDivider title={strings.visualization.illustration} />
              {floorOptions.floors.length > 0 && (
                <MenuItem
                  text={renderFloorOption(floorOptions.activeFloor.path)}
                  onClick={handleShowFloor}
                />
              )}
            </>
          )}
          {roomType !== "illustration" && (
            <>
              <MenuDivider title="Carpet" />
              {window.flags.visualizations.showScaleOption && (
                <MenuItem className="at-carpet-option py-0" text={scaleOptions} />
              )}
              <MenuItem className="at-carpet-option" text={rotationOptions} />
              <MenuItem
                className="at-carpet-option"
                text={
                  <AtButton
                    text={strings.visualization.resetPosition}
                    onClick={handleResetPosition}
                    tooltip={strings.visualization.resetPosition}
                    tooltipProps={tooltipProps}
                  />
                }
              />
            </>
          )}
        </Menu>
      )}
      {hasOptions && (
        <AtButton
          minimal
          className="at-illu-options-button"
          icon="plus"
          onClick={() => setShowMenu(!showMenu)}
        />
      )}

      {window.flags.saveCustomDesignRoom && (
        <AtButton
          minimal
          className="at-saveicon-customdesign"
          icon="save"
          onClick={() => saveCustomDesignRoom()}
        />
      )}
    </Portal>
  );
};
