import { Icon, Menu, MenuItem, Popover } from "@blueprintjs/core";
import React from "react";
import strings from "../../../strings";
import AtButton from "../../atoms/AtButton";

const AutoColor = props => {
  const {
    showAutoColorDropdown,
    handleAutoRecolor,
    autoColorOptions,
    onOptionClick,
    allowPaletteFromImage,
    handlePaletteFromFileInput
  } = props;

  const onPaletteFromFileClick = e => {
    if (handlePaletteFromFileInput) handlePaletteFromFileInput(e);
  };
  return (
    <AtButton id="at-design-color-options-autocolor" small minimal>
      <span onClick={handleAutoRecolor}>{strings.tools.autoRecolor}</span>
      {showAutoColorDropdown && (
        <Popover
          popoverClassName="autocolor-popover"
          disabled={!showAutoColorDropdown}
          boundary="viewport"
          content={
            <Menu className="autocolor-menu">
              {autoColorOptions &&
                autoColorOptions.map((autoColorOption, i) => (
                  <MenuItem
                    className="autocolor-option"
                    key={i}
                    id={`autocolor-option-${i}`}
                    text={
                      strings.autoColorOptions[autoColorOption.value]
                        ? strings.autoColorOptions[autoColorOption.value]
                        : autoColorOption.title
                    }
                    style={{ background: autoColorOption.background.replace(/;/g, " ") }}
                    onClick={e => {
                      if (onOptionClick) onOptionClick(autoColorOption);
                    }}
                  />
                ))}
              {allowPaletteFromImage && (
                <MenuItem
                  className="autocolor-option from-image"
                  id={`autocolor-option-from-image`}
                  text={strings.autoColorOptions.fromImage}
                  style={{ background: "#333" }}
                  onClick={onPaletteFromFileClick}
                ></MenuItem>
              )}
            </Menu>
          }
          minimal
          position="bottom"
          portalContainer={document.getElementById("app-main")}
        >
          <Icon className="color-details-material-icon" icon="caret-down" />
        </Popover>
      )}
    </AtButton>
  );
};

AutoColor.propTypes = {};

export default AutoColor;
