/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Suspense } from "react";
import AppProvider from "../../../api/appProvider";
import { useFullscreen, useToggle, useIdle, useKey, useMedia, useOrientation } from "react-use";
import { ReactComponent as Icons } from "../../symbol-defs.svg";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { Toast, Toaster } from "@blueprintjs/core";
import uuid from "uuid";
import {
  visualizationsActions,
  visViewModes,
  useVisualizationState,
  useDispatchVisualization
} from "../../../reducers/visualizations.reducer";
import {
  useUiDispatch,
  useUiState,
  mainUiActions,
  pageViews
} from "../../../reducers/mainui.reducer";
import FavoritesDialog from "../../organisms/Favorites/FavoritesDialog";
import VrDialog from "../../organisms/VrDialog";
import QuickLinkDialog from "../../organisms/QuicklinkDialog";
import RenameDialog from "../../organisms/RenameDialog";
import { isMobileDevice } from "../../../utils/utils";
import ConfirmationDialog from "../../organisms/ConfirmationDialog";
import strings from "../../../strings";
import ErrorPage from "../ErrorPage";
import {
  useDesignListState,
  useDispatchDesignList,
  designListActions
} from "../../../reducers/designlist.reducer";
import MapColorsDialog from "../../organisms/MapColorsDialog";
import { useHomeMount } from "./hooks";
import LoadingOverlay from "../LoadingOverlay";
import AppContainerStepper from "./AppContainerStepper";
import AppContainerPaneled from "./AppContainerPaneled";
import Stage from "../../organisms/StageNew";
import EntryDialog from "../../organisms/EntryDialog";
import LeftSidebarTabed from "../../templates/LeftSidebar/LeftSidebarTabed";
import { getCustomClass, handleSwipeOnEdges } from "../../../utils/domUtils";
import classNames from "classnames";
import {
  fetchNewPropsAndPostToParent,
  getExplorugData,
  postDetailsToParent
} from "../../../middleware/ordersheet.middleware";
import { useOrdersheetDetailState } from "../../../reducers/ordersheetdetails.reducer";
import { usePriceCalculator } from "../../../hooks";
import FolderStoryDialog from "../../organisms/FolderStoryDialog";
import InfoDialog from "../../organisms/InfoDialog";
import { useSwipeable } from "react-swipeable";
import ImageInputDialog from "../../organisms/ImageInputDialog";
import SelectSizeDropdown from "../../organisms/SelectSizeDropdown";

const Rugshare = React.lazy(() => import("../../templates/Rugshare"));
const CinematicStager = React.lazy(() => import("./../../cinematic/CinematicStager"));
const checkTargetNodeInput = e => {
  return e.target.nodeName === "TEXTAREA" || e.target.nodeName === "INPUT";
};
function usePrevious(value) {
  const ref = useRef({});
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
const Home = props => {
  const { template } = props;
  const { flags } = window;

  const { initDesignColors } = window.initialData;
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const visState = useVisualizationState();
  const dispatchVisState = useDispatchVisualization();
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const [isFullScreen, toggleFullscreen] = useToggle(false);
  const orientation = useOrientation();
  const isIdle = useIdle(5e3, false, [
    "mousemove",
    "mousedown",
    "resize",
    "keydown",
    "touchstart",
    "wheel",
    uiState.showLoadingOverlay
  ]);
  const ordersheetDetailState = useOrdersheetDetailState();

  const { designDetails, fullpath } = designDetailState;
  const prevFullpath = usePrevious(fullpath);
  const prevDesignColors = usePrevious(designDetails?.DesignColors);

  const message = window.initialData.message;
  const price = usePriceCalculator({
    ...designDetailState,
    showPrice: window.InterfaceElements.ShowPrice && !window.initialData.customDesignUrl
  });

  const handlePageSwipe = data => {
    if (!data) return;
    const payload = handleSwipeOnEdges(data);
    if (payload) {
      dispatchUiState({
        type: mainUiActions.SET_SHOW_SIDEBAR,
        payload: payload
      });
    }
  };
  const handlers = useSwipeable({
    onSwipedLeft: e => handlePageSwipe(e),
    onSwipedRight: e => handlePageSwipe(e),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true
  });

  const getExpDataAndPostToParent = () => {
    const {
      passDesignNameAsDesignId,
      passQualityFromFolderName,
      sendOrigDesignColors,
      passTotalArea
    } = window.flags.explorugData;
    getExplorugData({
      designDetailState: designDetailState,
      ordersheetDetailState: ordersheetDetailState,
      showPrice: window.InterfaceElements.ShowPrice,
      price: price,
      passDesignNameAsDesignId: passDesignNameAsDesignId,
      passQualityFromFolderName: passQualityFromFolderName,
      sendOrigDesignColors: sendOrigDesignColors,
      passTotalArea: passTotalArea,
      origDesignColors: designDetailState.history[0]
        ? designDetailState.history[0].designDetails.DesignColors
        : designDetailState.designDetails.DesignColors
    }).then(data => {
      postDetailsToParent(data);
    });
  };

  useEffect(() => {
    if (message && message.indexOf("explorug props") !== -1) {
      console.log("message is changed", message);
      fetchNewPropsAndPostToParent({ designDetailState: designDetailState });
    }
    if (message && message.indexOf("downloadimage") !== -1) {
      console.log("message for image download ", message);
      handleDownloadImage();
      //download
    }
  }, [message]);

  useEffect(() => {
    if (window.flags.postToParentOnEveryColorChange && fullpath && fullpath === prevFullpath) {
      //console.log("prevFullpath", prevFullpath, "fullpath", fullpath);
      if (
        designDetails?.DesignColors &&
        JSON.stringify(prevDesignColors) !== JSON.stringify(designDetails.DesignColors)
      ) {
        // console.log("prevDesignColors", JSON.stringify(prevDesignColors), " current: ",JSON.stringify(designDetails.DesignColors))
        fetchNewPropsAndPostToParent({ designDetailState: designDetailState });
      }
    }
  }, [designDetails]);

  useEffect(() => {
    dispatchUiState({ type: mainUiActions.SET_IS_IDLE, payload: isIdle });
  }, [isIdle]);
  const refHome = useRef(null);

  const [isCinematic, setisCinematic] = useState(sessionStorage.getItem("cinematic") || false);
  const isDeviceSmall = useMedia("(max-width: 767px)");
  useHomeMount();
  useFullscreen(refHome, isFullScreen, { onClose: () => toggleFullscreen(false) });

  useKey("ArrowUp", e => {
    if (!checkTargetNodeInput(e))
      dispatchVisState({ type: visualizationsActions.SELECT_PREV_ILLUSTRATION });
  });
  useKey("ArrowDown", e => {
    if (!checkTargetNodeInput(e))
      dispatchVisState({ type: visualizationsActions.SELECT_NEXT_ILLUSTRATION });
  });
  useKey("f", e => {
    if (!checkTargetNodeInput(e)) handleFullScreen();
  });
  useKey("z", e => {
    if (!checkTargetNodeInput(e))
      if (e.ctrlKey) dispatchDesignDetails({ type: designDetailActions.UNDO_COLOR_SELECTION });
  });
  useEffect(() => {
    const mode = sessionStorage.getItem("mode");
    const initView = sessionStorage.getItem("initview") || "";
    if (uiState.showLoadingOverlay === false && designDetailState.loading === false) {
      if (isDeviceSmall || mode === "myroom" || initView === "myroom") {
        dispatchUiState({
          type: mainUiActions.SET_SHOW_SIDEBAR,
          payload: { left: false, right: false }
        });
      } else {
        // if (
        //   !window.flags.interface.showSidebarsByDefault.left ||
        //   !window.flags.interface.showSidebarsByDefault.right
        // ) {
        dispatchUiState({
          type: mainUiActions.SET_SHOW_SIDEBAR,
          payload: {
            left: window.flags.interface.showSidebarsByDefault.left,
            right: window.flags.interface.showSidebarsByDefault.right
          }
        });
        // } else {
        //   dispatchUiState({
        //     type: mainUiActions.SET_SHOW_SIDEBAR,
        //     payload: { left: true, right: true }
        //   });
        // }
      }
      if (
        (window.flags.panelStateMobile && isMobileDevice) ||
        (window.flags.interface.alwaysShowRightSideBarOnly && window.innerWidth < 850) //done for talisman mills, show rightbar only when width less than 850
      ) {
        dispatchUiState({
          type: mainUiActions.SET_SHOW_SIDEBAR,
          payload: { left: false, right: true }
        });
      }
      if (window.flags.showCustomDialogAtStart && window.flags.customDialogBoxContent !== "") {
        const shownAlready = localStorage.getItem("showedCustomDialogAtStart") || "";
        if (shownAlready === "") {
          localStorage.setItem("showedCustomDialogAtStart", 1);
          dispatchUiState({
            type: mainUiActions.SET_SHOW_ENTRY_DIALOG,
            payload: true
          });
        }
      }
    }
  }, [uiState.showLoadingOverlay]);
  useEffect(() => {
    if (isDeviceSmall && uiState.showLeftSidebar) {
      if (
        orientation.angle === 0 ||
        orientation.angle === 180 ||
        orientation.type === -"portrait-primary"
      )
        dispatchUiState({
          type: mainUiActions.SET_SHOW_SIDEBAR,
          payload: { right: false }
        });
    }
  }, [uiState.showLeftSidebar]);

  useEffect(() => {
    if (isDeviceSmall && uiState.showRightSidebar) {
      if (
        orientation.angle === 0 ||
        orientation.angle === 180 ||
        orientation.type === -"portrait-primary"
      )
        dispatchUiState({
          type: mainUiActions.SET_SHOW_SIDEBAR,
          payload: { left: false }
        });
    }
  }, [uiState.showRightSidebar]);

  useEffect(() => {
    if (!isMobileDevice) return;
    if (orientation.type === "portrait-primary") {
      dispatchUiState({
        type: mainUiActions.SET_SHOW_SIDEBAR,
        payload: { left: false, right: false }
      });
      return;
    }
    if (
      orientation.type === "landscape-primary" &&
      !uiState.showRightSidebar &&
      !uiState.showLeftSidebar
    ) {
      dispatchUiState({
        type: mainUiActions.SET_SHOW_SIDEBAR,
        payload: { left: true, right: true }
      });
      return;
    }
  }, [orientation]);

  useEffect(() => {
    const { selectedFile, activeVariation } = designListState;
    let {
      designProps: designDetails,
      name: designName,
      fullPath,
      customizationFlag
    } = selectedFile;
    if (!designDetails) return;
    if (window.flags.designVariations.preserveVariations || activeVariation.id) {
      if (activeVariation.designProps) designDetails = activeVariation.designProps;
      designName = activeVariation.name;
      fullPath = activeVariation.fullPath;
    }
    dispatchVisState({
      type: visualizationsActions.FILTER_ILLUSTRATIONS_FOR_DESIGN,
      payload: selectedFile.fullPath
    });
    if (initDesignColors && initDesignColors.length === designDetails.DesignColors.length) {
      dispatchDesignDetails({
        type: designDetailActions.SET_DETAILS,
        payload: {
          designDetails: { ...designDetails, DesignColors: initDesignColors },
          customizationFlag: true,
          label: designName,
          fullpath: fullPath,
          setCustomConversion:
            window.flags.ordersheet.customConversion.setCustomConversion || false,
          customInToCmFactor: window.flags.ordersheet.customConversion.customInToCmFactor
        }
      });
    } else {
      if (!window.flags.designVariations.containsOriginalDesign)
        dispatchDesignDetails({
          type: designDetailActions.SET_DETAILS,
          payload: {
            designDetails,
            label: designName,
            fullpath: fullPath,
            customizationFlag,
            setCustomConversion:
              window.flags.ordersheet.customConversion.setCustomConversion || false,
            customInToCmFactor: window.flags.ordersheet.customConversion.customInToCmFactor
          }
        });
    }
    showLoadingAnimation(50);
  }, [designListState.selectedFile.id]);
  // useEffect(() => {
  //   if (!designDetailState.fullpath) return;
  //   AppAnalytics.sendDesignDetails({
  //     customize: designDetailState.customizationFlag,
  //     designPath: designDetailState.fullpath
  //   })
  //     .then(status => {
  //       //console.log("AppAnalytics.sendDesignDetails ~ status", status);
  //     })
  //     .catch(error => {
  //       console.log(" AppAnalytics DesignDetails ~ error", error);
  //     });
  // }, [designDetailState.designDetails]);

  const setToastProps = props => {
    dispatchUiState({ type: mainUiActions.SET_TOAST_PROPS, payload: props });
  };
  const showLoadingAnimation = (setPercent, timer = 2000) => {
    let percentValue = setPercent;
    if (uiState.showLoadingOverlay)
      dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: percentValue });
    setTimeout(() => {
      percentValue = percentValue >= 80 ? percentValue : percentValue + 20;
      if (uiState.showLoadingOverlay) {
        dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: percentValue });
      }
    }, timer);
  };
  const handleFavorite = ({ node, newName }) => {
    const felt = window.flags.isFelt || 0;
    var thumbUrl;
    const { designDetails, fullpath, hash } = designDetailState;
    const thumbnailWidth = 300;
    const thumbnailHeight = 400;
    AppProvider.getRenderedDesign({
      designDetails,
      fullpath,
      hash,
      felt
    }).then(canvas => {
      const thumbnailCanvas = document.createElement("canvas");
      thumbnailCanvas.width = thumbnailWidth;
      thumbnailCanvas.height = thumbnailHeight;
      const thumbnailContext = thumbnailCanvas.getContext("2d");
      thumbnailContext.drawImage(canvas, 0, 0, thumbnailWidth, thumbnailHeight);
      thumbUrl = thumbnailCanvas.toDataURL("image/jpeg", 0.95);
      const favNode = {
        currentUserID: window.window.InterfaceElements.CurrentUserID,
        name: newName,
        thumbUrl: thumbUrl,
        fullPath: designDetailState.fullpath,
        designProps: designDetailState.designDetails,
        customizationFlag: designDetailState.customizationFlag,
        hash: designDetailState.hash,
        view: visState.activeNode.FullPath,
        id: uuid.v4()
      };
      dispatchDesignList({ type: designListActions.ADD_FAVORITE, payload: favNode });
      let getPageName = sessionStorage.getItem("page");
      if (getPageName == "undefined") {
        getPageName = sessionStorage.getItem("username");
      }
      localStorage.setItem(
        "favorite designs" + "-" + getPageName,
        JSON.stringify([...designListState.favorites, favNode])
      );
      setToastProps({
        message: "Added to Favorites",
        intent: "success"
      });
    });
    // AppAnalytics.sendFavorites({
    //   designPath: designDetailState.fullpath
    // })
    //   .then(status => {
    //     //console.log("sendFavorites -> status", status);
    //   })
    //   .catch(error => {
    //     console.log(" AppAnalytics Favorites ~ error", error);
    //   });
  };
  const replaceOriginal = () => {
    const { fullpath, designDetails } = designDetailState;
    dispatchUiState({ type: mainUiActions.SET_ISBUSY, payload: true });
    AppProvider.saveOnServer({ file: fullpath, props: designDetails }).then(result => {
      AppProvider.clearCache({ mode: "designs", files: [fullpath] }).then(() => {
        AppProvider.getDesignThumbnails({ designs: [{ fullPath: fullpath }] }).then(thumbs => {
          dispatchDesignList({
            type: designListActions.UPDATE_FILE_PROP,
            payload: { ...thumbs[0] }
          });
        });
        if (result) {
          dispatchUiState({
            type: mainUiActions.SET_TOAST_PROPS,
            payload: {
              message: strings.tools.designSaveSuccess,
              intent: "success"
            }
          });
          dispatchUiState({ type: mainUiActions.SET_ISBUSY, payload: false });
        } else {
          dispatchUiState({
            type: mainUiActions.SET_TOAST_PROPS,
            payload: {
              message: strings.tools.designSaveFail,
              intent: "danger"
            }
          });
        }
      });
    });
  };
  const handleFullScreen = () => {
    if (isDeviceSmall) {
      dispatchUiState({
        type: mainUiActions.SET_SHOW_SIDEBAR,
        payload: { left: false, right: false }
      });
    } else {
      dispatchUiState({
        type: mainUiActions.SET_SHOW_SIDEBAR,
        payload: { left: isFullScreen, right: isFullScreen }
      });
    }
    dispatchUiState({
      type: mainUiActions.SET_SHOW_SIDEBAR,
      payload: { left: isFullScreen, right: isFullScreen }
    });
    let fcDelay = 1000;
    if (!uiState.showLeftSidebar && !uiState.showRightSidebar && !isFullScreen) {
      fcDelay = 0;
    }
    if (uiState.showLeftSidebar && uiState.showRightSidebar && isFullScreen) {
      fcDelay = 0;
    }
    if (document.body.requestFullscreen) {
      setTimeout(() => {
        toggleFullscreen();
      }, fcDelay);
    } else {
      toggleFullscreen();
    }
  };
  const handleArp = () => {
    localStorage.setItem("designpath", designDetailState.fullpath);
    localStorage.setItem("key", sessionStorage.getItem("apikey"));
    localStorage.setItem("domain", AppProvider.domain);
    window.open("https://explorug.com/v3dev/arp/default.aspx", "_blank");
  };

  const handleCinematic = () => {
    setisCinematic(true);
  };

  const handleDownloadImage = () => {
    //setIsBusy(true);
    dispatchUiState({
      type: mainUiActions.SET_ISBUSY,
      payload: true
    });

    setToastProps({
      message: "Saving image...",
      intent: "success"
    });
    const download = async () => {
      switch (visState.viewMode) {
        case visViewModes.ROOM_VIEW:
          await window.downloadRendered3dIll();
          break;
        case visViewModes.MY_ROOM:
          await window.SaveAsImage();
          break;

        case visViewModes.DESIGN_VIEW_PERSPECTIVE:
        case visViewModes.DESIGN_VIEW_FOLDEDBACK:
          await window.downloadRenderedPerspectiveIll();
          break;

        case visViewModes.CATALOG_VIEW:
          await window.downloadRenderedCatalog();
          break;
        default:
          window.downloadRenderedDesign();
          break;
      }
      reset_isBusy();
    };
    download();
    // if (visState.viewMode === visViewModes.ROOM_VIEW) {
    //   window.downloadRendered3dIll().then(() => {
    //     reset_isBusy();
    //   });
    // } else if (visState.viewMode === visViewModes.MY_ROOM) {
    //   window.SaveAsImage().then(() => {
    //     reset_isBusy();
    //   });
    // } else if (visState.viewMode === visViewModes.DESIGN_VIEW_PERSPECTIVE) {
    //   window.downloadRenderedPerspectiveIll().then(() => {
    //     reset_isBusy();
    //   });
    // } else {
    //   window.downloadRenderedDesign().then(() => {
    //     reset_isBusy();
    //   });
    // }
  };

  useEffect(() => {
    window.addEventListener("message", async event => {
      //done for iframe post on save image or room
      function handleToast() {
        dispatchUiState({
          type: mainUiActions.SET_ISBUSY,
          payload: true
        });
        setToastProps({
          message: "Saving image...",
          intent: "success"
        });
      }
      if (event.data == "saveRoom") {
        handleToast();
        await window.downloadRendered3dIll();
      }
      if (event.data == "saveDesign") {
        handleToast();
        await window.downloadRenderedDesign();
      }
      reset_isBusy();
    });
  }, []);

  const reset_isBusy = () => {
    setTimeout(() => {
      dispatchUiState({
        type: mainUiActions.SET_ISBUSY,
        payload: false
      });
    }, 2000);
  };
  useEffect(() => {
    if (isDeviceSmall) {
      if (designDetailState.loading) {
        dispatchUiState({
          type: mainUiActions.SET_SHOW_SIDEBAR,
          payload: { right: false, left: false }
        });
      }
    }
  }, [designDetailState.loading]);
  const setOpenRugShare = show => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_RUGSHARE, payload: show });
  };

  const rugshareCondition = window.InterfaceElements.IsAdmin
    ? window.InterfaceElements.ShowRugshare
    : window.InterfaceElements.ShowRugshare && flags.hasRugshare;
  const renderTemplate = () => {
    if (window.initialData.customDesignUrl)
      return (
        <div className={classNames("custom-design-mode", getCustomClass())}>
          <Stage />
          <LeftSidebarTabed />
          {sessionStorage.getItem("sizeoption") &&
            !uiState.showLoadingOverlay &&
            sessionStorage.getItem("sizeoption").indexOf("|") !== -1 && (
              <div
                className="at-size-dropdown"
                style={{
                  display: "inline-block",
                  position: "relative",
                  zIndex: "99",
                  color: "#585858",
                  top:
                    visState.viewMode == visViewModes.MY_ROOM
                      ? "calc(100vh - 18rem)"
                      : "calc(100vh - 16rem)"
                }}
              >
                <SelectSizeDropdown />
              </div>
            )}
        </div>
      );
    switch (template) {
      case pageViews.HOME:
        return (
          <AppContainerPaneled
            handleDownloadImage={handleDownloadImage}
            handleArp={handleArp}
            handleCinematic={handleCinematic}
            isFullScreen={isFullScreen}
            handleFullScreen={handleFullScreen}
            customClass={getCustomClass()}
          />
        );
      case pageViews.STEPPER:
        return (
          <AppContainerStepper
            handleDownloadImage={handleDownloadImage}
            handleArp={handleArp}
            handleCinematic={handleCinematic}
            isFullScreen={isFullScreen}
            handleFullScreen={handleFullScreen}
            customClass={getCustomClass()}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <React.Fragment>
      {/* <CinematicStager /> */}

      <div
        {...handlers}
        ref={refHome}
        id="app-main"
        className={classNames("app-main", getCustomClass())}
        style={{ display: "block" }}
      >
        <Icons />
        {rugshareCondition && (
          <Suspense fallback={<></>}>
            <Rugshare onClose={() => setOpenRugShare(false)} isOpen={uiState.showRugShare} />
          </Suspense>
        )}

        {window.flags.customDialogBoxContent !== "" && (
          <EntryDialog content={window.flags.customDialogBoxContent} />
        )}

        <FavoritesDialog favorites={designListState.favorites} handleFavorite={handleFavorite} />
        <FolderStoryDialog story={uiState.folderStoryDialogContent} />
        <ImageInputDialog />
        <VrDialog />
        <QuickLinkDialog />
        <RenameDialog />
        <ConfirmationDialog
          dialogTitle={strings.tools.saveAndReplaceOnserver}
          handleConfirm={replaceOriginal}
          isOpen={uiState.showConfirmationDialog}
        />
        <InfoDialog
          dialogTitle={""}
          infoText={"Sorry, this view is unavailable"}
          confirmText={"Go to Top View"}
          handleConfirm={() => {
            dispatchVisState({
              type: visualizationsActions.SET_VIEW_MODE,
              payload: window.flags.visualizations.defaultView
            });
          }}
          handleClose={() => {
            dispatchVisState({
              type: visualizationsActions.SET_VIEW_MODE,
              payload: window.flags.visualizations.defaultView
            });
          }}
          isOpen={uiState.showInfoDialog}
        />
        <MapColorsDialog />
        {renderTemplate()}
        <Toaster position="bottom">
          {uiState.toastProps && (
            <Toast
              message={uiState.toastProps.message}
              intent={uiState.toastProps.intent}
              timeout={uiState.toastProps.timeout || 5000}
              onDismiss={() => setToastProps(null)}
            />
          )}
        </Toaster>
        {uiState.showLoadingOverlay && <LoadingOverlay />}
      </div>
      {uiState.error && <ErrorPage errorType={uiState.error} />}
      {isCinematic && designListState.tree != null && window.flags.hasCinematic && (
        <Suspense fallback={<></>}>
          <CinematicStager
            logoUrl={`${AppProvider.domain}${window.InterfaceElements.LogoUrl}`}
            close={() => setisCinematic(false)}
          />
        </Suspense>
      )}
    </React.Fragment>
  );
};
export default Home;
