import React, { Suspense, useEffect, useState } from "react";
import SidePanel from "../SidePanel";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";
import DesignDetailSection from "../../organisms/DesignDetailSection";
import { usePriceCalculator } from "../../../hooks";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import Divider from "../../atoms/Divider";
import ColorAreaSwatch from "../../molecules/ColorAreaSwatch";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";
import AppProvider from "../../../api/appProvider";
import uuid from "uuid";
import classNames from "classnames";
import { convertUnit_Arr, displayAreaInFt } from "../../../utils/utils";
import { getFromSessionStorage } from "../../../utils/domUtils";
import { getExplorugData, postDetailsToParent } from "../../../middleware/ordersheet.middleware";
import {
  ordersheetDetailActions,
  useDispatchordersheetDetail,
  useOrdersheetDetailState
} from "../../../reducers/ordersheetdetails.reducer";
import {
  useDesignListState,
  useDispatchDesignList,
  designListActions
} from "../../../reducers/designlist.reducer";
import DesignVariations from "../../organisms/DesignVariations";
import { useMount } from "react-use";
import BrandLogo from "../../molecules/BrandLogo";
import SidebarActionButtons from "../../organisms/SidebarActionButtons";
import OrdersheetButton from "../OrderSheet/OrdersheetBtn";
import PriceEstimationBtn from "../OrderSheet/PriceEstimationBtn";
import { RugTechniques } from "../OrderSheet/Davisrugs/DavisRugsOptions";
import SaveOnServer from "../../organisms/SaveOnServer";
import DesignDetailControls from "../../organisms/DesignDetailSection/DesignDetailControls";
import SendToApp from "../../molecules/SendToApp";
import { getDesignName } from "../../../utils/treeutils";

import CreateCombo from "../../organisms/CreateCombinationUI";
import TearSheet from "../../molecules/TearSheet";
import { showOrdersheetOutside } from "../../../utils/flagUtils";
const OrderSheet = React.lazy(() => import("../OrderSheet"));

const RightSidebarTabbed = props => {
  const { handleDownloadImage, handleCinematic, isFullScreen, handleFullScreen } = props;

  const uiState = useUiState();
  const designDetailState = useDesignDetailState();
  const { customFields } = designDetailState;

  const designListState = useDesignListState();
  const dispatchUiState = useUiDispatch();
  const dispatchDesignList = useDispatchDesignList();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatchOrdersheetDetail = useDispatchordersheetDetail();

  const ordersheetDetailState = useOrdersheetDetailState();

  const { showRightSidebar, isIdle } = uiState;
  const price = usePriceCalculator({
    ...designDetailState,
    showPrice: window.InterfaceElements.ShowPrice && !window.initialData.customDesignUrl
  });

  const [designPrice, setDesignPrice] = useState(null);
  const [showBuyNowButton, setShowBuyNowButton] = useState(false);
  const { hasCustomizationSteps } = window.flags.accountSpecific;

  const {
    setCustomConversion = false,
    customInToCmFactor
  } = window.flags.ordersheet.customConversion;

  const [currentStep, setCurrentStep] = useState(
    hasCustomizationSteps.hasStepwiseButtons ? hasCustomizationSteps.defaultStepNum : 0
  ); //for jaipur-like setup (stepwise customization)

  useMount(() => {
    if (showOrdersheetOutside()) {
      if (customFields.fields) {
        setOpenOrderSheet(true);
        return;
      }
      setCustomFieldsLoading(true);
      AppProvider.fetchCustomFieldOrdersheet()
        .then(data => {
          setCustomFieldsLoading(false);
          setCustomFields(data);
          setOpenOrderSheet(true);
        })
        .catch(err => {})
        .finally(() => {
          setCustomFieldsLoading(false);
        });
    }

    if (hasCustomizationSteps && hasCustomizationSteps.hasStepwiseButtons) {
      const handleMessage = e => {
        const { priceDetails } = e.data;
        if (priceDetails) {
          dispatchOrdersheetDetail({
            type: ordersheetDetailActions.SET_PRICEDETAILS_JAIPUR,
            payload: priceDetails
          });
        }
      };
      window.addEventListener("message", handleMessage);
    }
  }, []);

  useEffect(() => {
    if (window.flags.showBuyNow) {
      checkDesignAvailability();
    }
  }, [designDetailState.originalName]);

  useEffect(() => {
    if (hasCustomizationSteps && hasCustomizationSteps.hasStepwiseButtons) {
      setCurrentStep(1);
    }
  }, [designDetailState.fullpath]);

  const setCustomFieldsLoading = value => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS_LOADING, payload: value });
  };
  const setCustomFields = fields => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS, payload: fields });
  };
  const setOpenOrderSheet = show =>
    dispatchUiState({ type: mainUiActions.SET_SHOW_ORDERSHEET, payload: show });
  const setOpenRugShare = show => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_RUGSHARE, payload: show });
  };

  const handleToggleSidebar = () => {
    dispatchUiState({
      type: mainUiActions.SET_SHOW_SIDEBAR,
      payload: { right: !showRightSidebar }
    });
  };

  const handleCreateStripeDesign = () => {
    dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: true });
    AppProvider.getStripedDesign().then(initialDesignPath => {
      const p = initialDesignPath.split("/").filter(item => item && item !== "");
      const designNamewithExt = p[p.length - 1];
      const darr = designNamewithExt.split(".");
      let designName = darr.slice(0, darr.length - 1).join(".");

      const name = initialDesignPath.split("/").find(item => item.substr(0, 1) === ".");
      if (name) {
        designName = `${name.substr(1)} ${designName}`;
      }

      p.pop();
      const path = p.join("/");
      AppProvider.getDesignThumbnails({ designs: [{ fullPath: initialDesignPath }] }).then(thum => {
        const thumbnail = thum[0];
        if (thumbnail.fullPath === initialDesignPath) {
          dispatchDesignList({
            type: designListActions.SELECT_DESIGN,
            payload: {
              id: uuid.v4(),
              name: designName,
              fullPath: initialDesignPath,
              location: path,
              type: "file",
              thumbUrl: thumbnail.thumbUrl,
              designProps: thumbnail.designProps
            }
          });
        }
      });
    });
  };
  const handleCheckStockItem = () => {
    const designName = getDesignName(designDetailState.fullpath);
    const designCode = designName.split("~")[0];
    callParent("check stock item - " + designCode);
  };
  const handleAddtoCart = (text = "") => {
    const {
      passDesignNameAsDesignId,
      passQualityFromFolderName,
      sendOrigDesignColors,
      passTotalArea
    } = window.flags.explorugData;
    getExplorugData({
      designDetailState: designDetailState,
      ordersheetDetailState: ordersheetDetailState,
      showPrice: window.InterfaceElements.ShowPrice,
      price: price,
      passDesignNameAsDesignId: passDesignNameAsDesignId,
      passQualityFromFolderName: passQualityFromFolderName,
      sendOrigDesignColors: sendOrigDesignColors,
      passTotalArea: passTotalArea,
      origDesignColors: designDetailState.history[0]
        ? designDetailState.history[0].designDetails.DesignColors
        : designDetailState.designDetails.DesignColors
    }).then(data => {
      if (text !== "") {
        data = Object.assign({}, data, { text: text });
      }
      postDetailsToParent(data);
    });
  };
  const handlePaypalBuy = () => {
    window.open(
      "http://ramrodesigns.com/virtual-exibition/designpurchase.php?name=" +
        designDetailState.originalName.replace(/ /g, "_") +
        "&key=" +
        sessionStorage.getItem("apikey") +
        "&client=ramro&status=purchase",
      "_blank"
    );
  };
  const checkDesignAvailability = () => {
    if (!designDetailState.originalName || designDetailState.originalName === "") return;
    AppProvider.checkBuyAvailable({
      name: designDetailState.originalName.replace(/ /g, "_"),
      key: sessionStorage.getItem("apikey"),
      client: window.flags.designDetailSection.exhibitorName || "ramrodesigns",
      status: "check"
    }).then(data => {
      const designData = data.indexOf("|") > -1 ? data.split("|") : data;
      const status = designData[0] || designData;

      const designRate = designData[1] || "";
      if (designRate !== "") {
        const designPriceJson = {
          Currency: designRate.split(" ")[0] || "",
          Value: designRate.split(" ")[1] || ""
        };
        setDesignPrice(designPriceJson);
      }
      if (status === 0 || status === "0") {
        console.log("no such design");
        setShowBuyNowButton(false);
      } else if (status.toLowerCase() === "available") {
        setShowBuyNowButton(true);
      } else {
        setShowBuyNowButton(false);
      }
    });
  };
  const renderDesignColorInfoText = () => {
    let showThis = false;
    if (window.flags.showWarningInfoInFooter) {
      if (designDetailState.designDetails.DesignColors) {
        const DesignColors = designDetailState.designDetails.DesignColors;
        const collection = DesignColors[0].ColorName.split("-")[1]; //colorname examples: "1181-200-1"
        const colorRow = DesignColors[0].ColorName.split("-")[2];
        const sameCollection = DesignColors.every(
          color => color.ColorName.split("-")[1] === collection
        );
        const sameColorRow = DesignColors.every(
          color => color.ColorName.split("-")[2] === colorRow
        );
        showThis = !sameCollection || !sameColorRow;
      }
    }
    return (
      <div
        className={classNames("bp3-text-small at-sidebar__footer-infotext", { shown: showThis })}
      >
        {strings.color.infotext}
      </div>
    );
  };

  const renderInfoText = () => {
    return (
      <div className={classNames("at-sidebar__footer-infotextHTML")}>
        {window.flags.infoInFooter !== "" && (
          <div
            id="at-sidebar__footer__infoInFooter"
            dangerouslySetInnerHTML={{ __html: window.flags.infoInFooter }}
          ></div>
        )}
      </div>
    );
  };

  const openCustomborderTool = () => {
    let key = sessionStorage.getItem("apikey");
    let mat = 0,
      KLRatio = 1,
      kpu = 1,
      lpu = 1;
    const [wid, hgt] = convertUnit_Arr(designDetailState.designDetails.Unit, "ft", [
      designDetailState.designDetails.PhysicalWidth,
      designDetailState.designDetails.PhysicalHeight
    ]);

    if (window.flags.designSpecific && window.flags.designSpecific.length) {
      mat = designDetailState.designDetails.DesignColors[0].Material || 0;
      KLRatio = designDetailState.designDetails.KLRatio || 1;
      const PhysicalWidth = designDetailState.designDetails.PhysicalWidth;
      const PhysicalHeight = designDetailState.designDetails.PhysicalHeight;

      const [w, h] = convertUnit_Arr(designDetailState.designDetails.Unit, "cm", [
        PhysicalWidth,
        PhysicalHeight
      ]);
      kpu = (designDetailState.designDetails.Width / w).toFixed(4);
      lpu = (designDetailState.designDetails.Height / h).toFixed(4);
    }
    let matParams = mat + "|" + mat + "|" + mat;
    const params =
      "?key=" +
      key +
      "&page=linkoutdoor&mat=" +
      matParams +
      "&innerborder=false&klratio=" +
      KLRatio +
      "&lpu=" +
      lpu +
      "&w=" +
      wid +
      "&h=" +
      hgt +
      "&unit=ft" +
      "&kpu=" +
      kpu +
      "&origborderdesign=" +
      designDetailState.originalName;
    const url = window.flags.customBorderUrl + params;
    console.log(url);
    window.open(url, "_self");
  };
  const handleHistoryBack = () => {
    if (getGoBackString() === strings.tools.close) {
      window.parent.postMessage("close", "*");
    } else {
      window.parent.postMessage("goback", "*");
      window.history.back();
    }
  };
  const showCustomBorderTool = () => {
    if (!window.flags.designSpecific || !window.flags.designSpecific.length) return false;
    let showBorderTool = false;
    if (!designDetailState.originalName) return showBorderTool;
    if (window.flags.designSpecific && window.flags.designSpecific.length) {
      let currentDesign = window.flags.designSpecific.filter(
        elem => elem.name.toLowerCase() === designDetailState.originalName.toLowerCase()
      );
      if (currentDesign && currentDesign.length) {
        showBorderTool = currentDesign[0].showBorder === true ? true : false;
      }
    }
    showBorderTool = showBorderTool || getFromSessionStorage("customborder");
    return showBorderTool;
  };
  const getGoBackString = () => {
    let str = strings.tools.goback;
    let gobacktext = getFromSessionStorage("gobacktext");
    if (gobacktext && gobacktext !== "") {
      str = gobacktext.toLowerCase() === "close" ? strings.tools.close : strings.tools.goback;
    }
    return str;
  };
  const handleFavorite = () => {
    dispatchUiState({
      type: mainUiActions.SET_SHOW_FAV_DIALOG,
      payload: true
    });
  };

  const handleSteps = (stepNum = 0) => {
    if (!allowNextStep()) return;
    if (stepNum === 3 && uiState.allowColorCustomization) {
      handleAddtoCart();
    }
    setCurrentStep(stepNum);
  };
  const callParent = (message = "") => {
    if (message !== "") window.parent.postMessage(message, "*");
  };
  const getShape = () => {
    let shapeText = "";
    if (!ordersheetDetailState.formFields || !ordersheetDetailState.formFields.fields) {
      return shapeText;
    } else {
      const formFields = ordersheetDetailState.formFields.fields;

      const field = formFields.find(field => {
        return field.fieldName === window.flags.ordersheet.hasShapeOptions.customFieldId;
      });
      if (field) {
        shapeText = field.fieldValue || "";
      }
    }
    return shapeText;
  };
  const getPriceFromSession = () => {
    let priceDetails = ordersheetDetailState.priceDetails_Jaipur || ""; //getFromSessionStorage("priceDetails") || "";
    let hasPriceDetails = priceDetails !== "" ? true : false;
    if (!hasPriceDetails) return hasPriceDetails;
    else {
      if (typeof priceDetails === "string") {
        try {
          priceDetails = JSON.parse(priceDetails) || "";
        } catch {
          priceDetails = "";
        }
      }
      if (priceDetails !== "" && typeof priceDetails === "object") {
        let leadtime = priceDetails.LeadTime ? (
          <div className="at-sidebar-leadtime-from-session">
            <div className="at-summary-title">Lead time</div> <div>{priceDetails.LeadTime}</div>
          </div>
        ) : (
          <></>
        );
        let priceValue = priceDetails.Price ? (
          <div className="at-sidebar-price-from-session">
            <div className="at-summary-title">{priceDetails.Price}</div>{" "}
            <div>(Inclusive of all taxes. Shipping Free)</div>{" "}
          </div>
        ) : (
          <></>
        );
        return (
          <>
            {leadtime} {priceValue}
          </>
        );
      } else return "";
    }
  };
  const allowNextStep = () => {
    const valid =
      uiState.allowColorCustomization &&
      displayAreaInFt(
        ordersheetDetailState.ordersheetDetails,
        setCustomConversion,
        customInToCmFactor
      ) > window.flags.ordersheet.hasMinAreaCriteria;
    return valid;
  };
  const renderOrdersheet = <OrderSheet handleDownloadImage={handleDownloadImage} />;

  const displayOrderSheetButton = () => {
    return window.flags.hasOrderSheet && !showOrdersheetOutside();
  };

  return (
    <SidePanel
      id="right-sidebar"
      side="right"
      show={uiState.showRightSidebar}
      isIdle={isIdle}
      className={classNames({
        "has-ordersheet-outside":
          !uiState.showOrderSheet ||
          showOrdersheetOutside() ||
          window.flags.ordersheet.panel !== "right"
      })}
    >
      <AtButton
        className="at-sidebar__togglebtn togglebtn-right"
        icon={`panel-to-the-${showRightSidebar ? "right" : "left"}`}
        onClick={handleToggleSidebar}
        text={strings.icontext.sidebarToggleRight || null}
      />

      {window.flags.hasOrderSheet &&
        !showOrdersheetOutside() &&
        window.flags.ordersheet.panel === "right" && (
          <Suspense fallback={<></>}>{renderOrdersheet}</Suspense>
        )}
      {(!uiState.showOrderSheet ||
        showOrdersheetOutside() ||
        window.flags.ordersheet.panel !== "right") && (
        <>
          <div className="at-sidebar__content">
            <DesignDetailSection
              interFaceElements={window.InterfaceElements}
              price={
                window.flags.designDetailSection.showDesignExhibitionPrice && designPrice
                  ? designPrice
                  : price
              }
              handleRugShare={() => setOpenRugShare(true)}
              handleFavorite={handleFavorite}
              handleDownloadImage={handleDownloadImage}
            />
            {window.flags.accountSpecific.priceEstimation_davisLandings.checkKeyForPrice && (
              <div className="at-ordersheet-accountSpecific-options">
                <RugTechniques />
              </div>
            )}
            <div
              className={classNames(
                "at-sidebar__container",
                { "hide-color": currentStep === 1 },
                { "hide-size": currentStep === 2 },
                { summary: currentStep === 3 }
              )}
            >
              {hasCustomizationSteps && hasCustomizationSteps.hasStepwiseButtons && (
                <div className="at-sidebar__container__stepwiseUI">
                  <div className="at-sidebar__container__steps">
                    {hasCustomizationSteps.steps &&
                      hasCustomizationSteps.steps.map((step, index) => (
                        <div
                          key={index}
                          className={classNames("stepwise-buttons", step.className, {
                            active: currentStep - 1 === index
                          })}
                          onClick={() => handleSteps(index + 1)}
                        >
                          <div className="stepwise-buttons-button">
                            {currentStep - 1 > index ? (
                              <img
                                alt="stepwise-buttons-icon"
                                src={hasCustomizationSteps.iconPath + "Tick-mark.png"}
                              />
                            ) : (
                              <React.Fragment>
                                <img
                                  alt="stepwise-buttons-icon"
                                  src={
                                    hasCustomizationSteps.iconPath +
                                    `${
                                      currentStep - 1 < index ? "Ellipse-white.png" : "Ellipse.png"
                                    }`
                                  }
                                />
                                <p>{index + 1}</p>
                              </React.Fragment>
                            )}
                          </div>

                          <div>{step.text} </div>
                        </div>
                      ))}
                    {hasCustomizationSteps.steps && <Divider horizontal />}
                  </div>
                  <div className="at-sidebar__container__text">
                    A one-to-one personal service with our in-house design team, who are on hand to
                    help find the best rug for your space.
                  </div>

                  <div className="at-sidebar__container__shape-example">
                    <div className="shape-example-title">Size</div>
                    <div className={classNames("shape", getShape())}>
                      <div className="shape-example-text text-height">
                        {strings.ordersheet.height}
                      </div>
                      <div className="shape-example-text text-width">
                        {strings.ordersheet.width}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showCustomBorderTool() && (
                <div style={{ margin: "1rem 0" }}>
                  <AtButton
                    intent="primary"
                    tertiary
                    text="Custom Border Design"
                    onClick={openCustomborderTool}
                  />
                </div>
              )}
              {designListState.selectedFolder && designListState.selectedFile && (
                <DesignVariations />
              )}
              {!window.InterfaceElements.IsJpeg && (
                <>
                  <Divider horizontal />
                  <ColorAreaSwatch />
                </>
              )}
              {showOrdersheetOutside() && window.flags.hasOrderSheet && uiState.showOrderSheet && (
                <>
                  <Divider horizontal />
                  <Suspense fallback={<></>}>{renderOrdersheet}</Suspense>
                </>
              )}
              {currentStep === 3 && ordersheetDetailState.priceDetails_Jaipur && (
                <div
                  className={classNames("at-session-price-details", {
                    hidden: !uiState.allowColorCustomization
                  })}
                >
                  {getPriceFromSession()}
                </div>
              )}
              {window.flags.accountSpecific.hasCustomizationSteps.hasStepwiseButtons && (
                <div className="at-parentMessage-buttons">
                  <div>
                    {currentStep === 1 && (
                      <div>
                        <AtButton
                          id="btnStep1Continue"
                          intent="primary"
                          text={"Continue"}
                          className={classNames({ disabled: !allowNextStep() })}
                          //disabled={!allowNextStep()}
                          onClick={() => handleSteps(2)}
                        />
                      </div>
                    )}
                    {currentStep === 2 && (
                      <div>
                        <AtButton
                          id="btnStep2Continue"
                          intent="primary"
                          text={"Continue"}
                          onClick={() => handleSteps(3)}
                        />
                      </div>
                    )}
                    {currentStep === 3 && (
                      <div>
                        <AtButton
                          id="btnStep3Continue"
                          intent="primary"
                          text={"Add To Cart"}
                          onClick={() => handleAddtoCart("Add To Cart")}
                        />
                      </div>
                    )}
                    <DesignDetailControls
                      handleRugShare={() => setOpenRugShare(true)}
                      handleFavorite={handleFavorite}
                      handleDownloadImage={handleDownloadImage}
                      parentID="at-parentMessage-buttons"
                    ></DesignDetailControls>
                  </div>

                  <div>
                    <AtButton
                      id="at-button-check__stock"
                      className="messageParent"
                      intent="primary"
                      tertiary
                      text={"Check Stock Item"}
                      onClick={() => handleCheckStockItem()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="at-sidebar__footer">
            {window.flags.interface.showInhouseTools?.showCreateCombination && (
              <CreateCombo></CreateCombo>
            )}
            {renderDesignColorInfoText()}
            {renderInfoText()}
            {window.flags.accountSpecific.sendToApp && (
              <SendToApp label="User ID" message="Please enter User ID" buttonText="SEND TO APP" />
            )}
            {window.sessionStorage.getItem("mode") === "stripedesign" && (
              <AtButton
                intent="primary"
                onClick={handleCreateStripeDesign}
                text={strings.tools.stripedDesign}
              ></AtButton>
            )}
            {window.flags.showAddToCart && (
              <AtButton
                tertiary={window.sessionStorage.getItem("mode") === "stripedesign"}
                intent="primary"
                onClick={handleAddtoCart}
                text={strings.tools.addToCart}
              ></AtButton>
            )}
            {window.flags.showBuyNow && (
              <AtButton
                disabled={!showBuyNowButton}
                tertiary={window.sessionStorage.getItem("mode") === "stripedesign"}
                intent="primary"
                onClick={handlePaypalBuy}
                text={strings.tools.buyNow}
              ></AtButton>
            )}
            {displayOrderSheetButton() &&
              window.flags.ordersheet.panel === "right" &&
              !window.flags.ordersheet.showOrdersheetInNavbar && <OrdersheetButton />}
            {window.flags.ordersheet.showTearSheet && (
              <TearSheet type={displayOrderSheetButton() ? "tertiary" : "primary"} />
            )}
            {window.flags.showHistoryBack && (
              <AtButton
                intent="primary"
                className="at-ordersheet-goback"
                tertiary={
                  window.sessionStorage.getItem("mode") === "stripedesign" ||
                  window.flags.showAddToCart
                }
                onClick={handleHistoryBack}
                text={getGoBackString()}
              ></AtButton>
            )}
            {window.InterfaceElements.IsAdmin && <SaveOnServer />}

            <AtButton
              id="at-sidebar__footer__saveAsImage"
              intent="primary"
              tertiary
              text={strings.tools.saveAsImage}
              onClick={handleDownloadImage}
            ></AtButton>
            {window.flags.accountSpecific.priceEstimation_davisLandings.checkKeyForPrice && (
              <div>
                <PriceEstimationBtn />
              </div>
            )}
          </div>
        </>
      )}
      <div className="at-sidebar__content__logo at-rightsidebar__logo">
        <BrandLogo />
        <div className="logo-tagline">{strings.tagline}</div>
      </div>
      <SidebarActionButtons
        isFullScreen={isFullScreen}
        handleFullScreen={handleFullScreen}
        handleCinematic={handleCinematic}
        handleFavorite={handleFavorite}
        handleDownloadImage={handleDownloadImage}
        handleRugShare={() => setOpenRugShare(true)}
      />
    </SidePanel>
  );
};

RightSidebarTabbed.propTypes = {};

export default RightSidebarTabbed;
