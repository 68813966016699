import React, { useState, Suspense } from "react";
import { useMount } from "react-use";
import Home from "../Home";
import AppProvider from "../../../api/appProvider";
import { UiStateProvider, useUiState, pageViews } from "../../../reducers/mainui.reducer";
import { DesignListStateProvider } from "../../../reducers/designlist.reducer";
import { VisualizationStateProvider, visViewModes } from "../../../reducers/visualizations.reducer";
import { ColorListStateProvider } from "../../../reducers/colors.reducer";
import { DesignDetailStateProvider } from "../../../reducers/designdetails.reducer";
import { MyroomStateProvider } from "../../../reducers/myroom.reducer";
import strings, { initializeStrings } from "../../../strings";
import deepmerge from "deepmerge";
import { defaultFlags, flaginCurrentMode } from "./defaultflags";
import { RoomConfigStateProvider } from "../../organisms/IllustrationView/roomconfig.reducer";
import { decodeColorsFromString, errorTypes } from "../../../utils/utils";

import { disableScreenshot, getFromSessionStorage, loadCssFile } from "../../../utils/domUtils";
import ErrorPage from "../ErrorPage";
import { OrdersheetDetailStateProvider } from "../../../reducers/ordersheetdetails.reducer";
import { RoomviewStateProvider } from "../../../reducers/roomview.reducer";
import { processDesignPath } from "../../../utils/treeutils";
import ColorsPage from "../Colors";
import CreateYourRug from "../CreateYourRug";
import ExplorugController from "../ExplorugController";
import InitialLogoPage from "../InitialLogoPage";
import MyRugStudio from "../MyRugStudio";
import StudioEntry from "../StudioEntry";

window.flags = {};
const DesignBrowser = React.lazy(() => import("../DesignBrowser"));
const PageTorender = props => {
  const uiState = useUiState();
  switch (uiState.pageView) {
    case pageViews.HOME:
    case pageViews.STEPPER:
      return <Home {...props} template={uiState.pageView} />;
    case pageViews.FULL_PAGE_THUMB:
      return (
        <Suspense fallback={<></>}>
          <DesignBrowser />
        </Suspense>
      );
    case pageViews.CONTROLLER:
      return (
        <Suspense fallback={<></>}>
          <ExplorugController />
        </Suspense>
      );
    case pageViews.COLORS:
      return (
        <Suspense fallback={<></>}>
          <ColorsPage />
        </Suspense>
      );

    case pageViews.CREATEYOURRUG:
      return (
        <Suspense fallback={<></>}>
          <CreateYourRug />
        </Suspense>
      );

    case pageViews.MYRUGSTUDIO:
      return (
        <Suspense fallback={<></>}>
          <MyRugStudio />
        </Suspense>
      );

    case pageViews.STUDIOENTRY:
      return (
        <Suspense fallback={<></>}>
          <StudioEntry />
        </Suspense>
      );
    default:
      break;
  }
};
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
const processRoomPath = path => {
  const initpatharr = path.split("/").filter(item => item && item !== "");

  let returnPath = path;
  if (initpatharr[0].toLowerCase() !== "rooms") returnPath = `Rooms/${returnPath}`;

  const fileName = initpatharr[initpatharr.length - 1];
  const fileNameArr = fileName.split(".");
  const extension = fileNameArr[fileNameArr.length - 1];
  if (extension.toLowerCase() !== "crf3d") returnPath = `${returnPath}.crf3d`;

  return returnPath;
};

const EntryPage = () => {
  const [initialData, setInitialData] = useState({
    eCatalogDetails: null,
    initDesignColors: null,
    initDesignPath: null,
    initRoomPath: null,
    initShotName: null,
    initDesignVariation: null,
    initShapeVariation: null,
    initDesignFolder: false,
    customDesignUrl: null,
    designWidth: null,
    designHeight: null,
    triggeredDesign: null,
    loading: true,
    error: false,
    centerDesignUrl: "",
    borderSize: null,
    borderDesignWid: null,
    borderDesignHgt: null,
    unit: null
  });
  useMount(() => {
    //if explorug is in a iframe and customdesign url is used, the iframe can change the url without having to reload the whole thing
    const messagehandler = async e => {
      const {
        customdesignurl,
        dataurl,
        triggeredDesign = "",
        customClassName,
        props,
        initDesignVariation = "",
        initShapeVariation = "",
        resetUI = "",
        priceDetails,
        initview,
        physicalwidth,
        physicalheight,
        unit = "cm",
        hideLoadingOverlay,
        centerDesignUrl = "",
        borderSize,
        borderDesignWid,
        borderDesignHgt,
        message
      } = e.data;
      if (!window.flags || !Object.keys(window.flags).length) {
        setTimeout(() => {
          messagehandler(e);
        }, 1000);
        return;
      }
      if (typeof message === "string" && message !== "") {
        let msg = message + Math.round(Math.random(100) * 1000);
        window.initialData = {
          ...window.initialData,
          message: msg,
          loading: false
        };
        setInitialData({
          ...window.initialData,
          message: msg,
          loading: false
        });
      }

      if (customdesignurl) {
        sessionStorage.setItem("customdesignurl", customdesignurl);
        const {
          customDesignBaseUrl,
          corsCustomDesignUrl,
          removeBgForCustomDesign = false
        } = window.flags;

        let designUrl = customdesignurl;
        if (customDesignBaseUrl || corsCustomDesignUrl) {
          designUrl = customDesignBaseUrl
            ? `${customDesignBaseUrl}/${customdesignurl}`
            : customdesignurl;
        }
        const {
          Image: corsUrl,
          Outline: customDesignOutline
        } = await AppProvider.createCorsUrlJSON(designUrl, removeBgForCustomDesign);
        // const designUrl =
        //   customDesignBaseUrl!=='' && corsCustomDesignUrl
        //     ? `${customDesignBaseUrl}/${customdesignurl}`
        //     : customdesignurl;
        // const corsUrl = AppProvider.createCorsUrl(designUrl);

        console.log("-> corsUrl", corsUrl);
        if (physicalwidth && physicalheight) {
          window.initialData = {
            ...window.initialData,
            customDesignUrl: corsUrl,
            customDesignOutline,
            designWidth: physicalwidth,
            designHeight: physicalheight,
            unit
          };
          setInitialData({
            ...window.initialData,
            customDesignUrl: corsUrl,
            designWidth: physicalwidth,
            designHeight: physicalheight,
            unit,
            loading: false
          });
        } else {
          window.initialData = { ...window.initialData, customDesignUrl: corsUrl };
          setInitialData({ ...window.initialData, customDesignUrl: corsUrl, loading: false });
        }
      }

      if (dataurl) {
        if (dataurl == "changeToDesign" && triggeredDesign) {
          window.initialData = {
            ...window.initialData,
            triggeredDesign: triggeredDesign,
            customDesignUrl: ""
          };
          setInitialData({
            ...window.initialData,
            triggeredDesign: triggeredDesign,
            customDesignUrl: "",
            loading: false
          });
        } else {
          if (physicalwidth && physicalheight) {
            window.initialData = {
              ...window.initialData,
              triggeredDesign: "",
              customDesignUrl: dataurl,
              designWidth: physicalwidth,
              designHeight: physicalheight,
              unit
            };
            setInitialData({
              ...window.initialData,
              triggeredDesign: "",
              customDesignUrl: dataurl,
              designWidth: physicalwidth,
              designHeight: physicalheight,
              unit,
              loading: false
            });
          } else {
            window.initialData = {
              ...window.initialData,
              triggeredDesign: "",
              customDesignUrl: dataurl
            };
            setInitialData({
              ...window.initialData,
              triggeredDesign: "",
              customDesignUrl: dataurl,
              loading: false
            });
          }
        }
      }

      if (initview) {
        sessionStorage.setItem("initview", initview);
        if (initview.toLowerCase() === "myroom") {
          window.flags.visualizations.defaultView = visViewModes.MY_ROOM;
          window.initialData = { ...window.initialData, initRoomPath: "myroom" };
          setInitialData({ ...window.initialData, initRoomPath: "myroom", loading: false });
        } else if (initview.toLowerCase() === "perspectiveview") {
          if (
            window.flags.visualizations.designViews.includes(visViewModes.DESIGN_VIEW_PERSPECTIVE)
          ) {
            window.flags.visualizations.defaultView = visViewModes.DESIGN_VIEW_PERSPECTIVE;
          }
          window.initialData = { ...window.initialData, initRoomPath: "perspectiveview" };
          setInitialData({
            ...window.initialData,
            initRoomPath: "perspectiveview",
            loading: false
          });
        } else if (initview.toLowerCase() === "foldedbackview") {
          if (
            window.flags.visualizations.designViews.includes(visViewModes.DESIGN_VIEW_FOLDEDBACK)
          ) {
            window.flags.visualizations.defaultView = visViewModes.DESIGN_VIEW_FOLDEDBACK;
          }
          window.initialData = { ...window.initialData, initRoomPath: "foldedbackview" };
          setInitialData({
            ...window.initialData,
            initRoomPath: "foldedbackview",
            loading: false
          });
        } else {
          //window.flags.visualizations.defaultView = visViewModes.ROOM_VIEW;
          const triggeredView =
            initview.toLowerCase() !== "myroom" && initview.toLowerCase() !== "perspectiveview"
              ? processRoomPath(initview)
              : initview;
          window.initialData = { ...window.initialData, initRoomPath: triggeredView };
          setInitialData({ ...window.initialData, initRoomPath: triggeredView, loading: false });
        }
      } else if (initview === "") {
        sessionStorage.setItem("initview", initview);
        window.initialData = { ...window.initialData, initRoomPath: "" };
        setInitialData({ ...window.initialData, initRoomPath: "", loading: false });
      }

      if (triggeredDesign && triggeredDesign !== "") {
        window.initialData = { ...window.initialData, triggeredDesign: triggeredDesign };
        setInitialData({ ...window.initialData, triggeredDesign: triggeredDesign, loading: false });
      }
      if (physicalwidth && physicalheight) {
        sessionStorage.setItem("width", physicalwidth);
        sessionStorage.setItem("height", physicalheight);
        window.initialData = {
          ...window.initialData,
          designWidth: physicalwidth,
          designHeight: physicalheight,
          unit
        };
        setInitialData({
          ...window.initialData,
          designWidth: physicalwidth,
          designHeight: physicalheight,
          unit,
          loading: false
        });
      }
      if (borderDesignWid && borderDesignHgt) {
        sessionStorage.setItem("borderDesignWid", borderDesignWid);
        sessionStorage.setItem("borderDesignHgt", borderDesignHgt);
        sessionStorage.setItem("unit", unit);
        window.initialData = {
          ...window.initialData,
          borderDesignWid: borderDesignWid,
          borderDesignHgt: borderDesignHgt
        };
        setInitialData({
          ...window.initialData,
          borderDesignWid: borderDesignWid,
          borderDesignHgt: borderDesignHgt,
          loading: false
        });
      }
      if (borderSize || borderSize === 0) {
        sessionStorage.setItem("borderSize", borderSize);
        window.initialData = { ...window.initialData, borderSize: borderSize };
        setInitialData({ ...window.initialData, borderSize: borderSize, loading: false });
      }
      if (centerDesignUrl !== "") {
        sessionStorage.setItem("centerDesignUrl", centerDesignUrl);

        window.initialData = { ...window.initialData, centerDesignUrl: centerDesignUrl };
        setInitialData({ ...window.initialData, centerDesignUrl: centerDesignUrl, loading: false });
      }

      if (initDesignVariation !== "") {
        window.initialData = { ...window.initialData, initDesignVariation: initDesignVariation };
        sessionStorage.setItem("initvar", initDesignVariation);
        setInitialData({
          ...window.initialData,
          initDesignVariation: initDesignVariation,
          loading: false
        });
      }
      if (initShapeVariation !== "") {
        window.initialData = { ...window.initialData, initShapeVariation: initShapeVariation };
        setInitialData({
          ...window.initialData,
          initShapeVariation: initShapeVariation,
          loading: false
        });
      }
      if (customClassName) {
        sessionStorage.setItem("customclass", customClassName);
      }
      if (priceDetails) {
        let priceDetailsStr =
          typeof priceDetails === "object" ? JSON.stringify(priceDetails) : priceDetails;
        sessionStorage.setItem("priceDetails", priceDetailsStr);
      }
      if (props || props === "") {
        sessionStorage.setItem("props", props);
        window.initialData = { ...window.initialData, props: props };
        setInitialData({
          ...window.initialData,
          props: props,
          loading: false
        });
      }
      if (resetUI !== "") {
        sessionStorage.setItem("resetUI", resetUI);
      }
      if (hideLoadingOverlay !== undefined && hideLoadingOverlay !== null) {
        window.initialData = { ...window.initialData, hideLoadingOverlay: hideLoadingOverlay };
        setInitialData({
          ...window.initialData,
          hideLoadingOverlay: hideLoadingOverlay
        });
      }
    };

    window.addEventListener("message", messagehandler);
    const initializeData = ({
      initDesignPath,
      initRoomPath,
      initShotName,
      initDesignVariation,
      initShapeVariation,
      initDesignColors,
      eCatalogDetails,
      initDesignFolder
    }) => {
      let initData = { ...initialData, eCatalogDetails, initDesignFolder };

      if (initDesignPath) {
        let designPath = processDesignPath(initDesignPath);
        if (!initDesignFolder) {
          const sp = designPath.split(".").filter(item => item && item !== "");
          const ext = sp[sp.length - 1];
          if (ext.toLowerCase() !== "ctf" && ext.toLowerCase() !== "jpg") {
            designPath = `${designPath}.${window.InterfaceElements.IsJpeg ? "jpg" : "ctf"}`;
          }
        }
        initData = { ...initData, initDesignPath: designPath };
      }
      if (initDesignVariation) {
        initData = { ...initData, initDesignVariation: initDesignVariation };
      }
      if (initShapeVariation) {
        initData = { ...initData, initShapeVariation: initShapeVariation };
      }

      if (initRoomPath) {
        switch (initRoomPath.toLowerCase()) {
          case "myroom":
            window.flags.visualizations.defaultView = visViewModes.MY_ROOM;

            break;
          case "perspectiveview":
            if (
              window.flags.visualizations.designViews.includes(visViewModes.DESIGN_VIEW_PERSPECTIVE)
            )
              window.flags.visualizations.defaultView = visViewModes.DESIGN_VIEW_PERSPECTIVE;
            break;
          case "foldedbackview":
            if (
              window.flags.visualizations.designViews.includes(visViewModes.DESIGN_VIEW_FOLDEDBACK)
            )
              window.flags.visualizations.defaultView = visViewModes.DESIGN_VIEW_FOLDEDBACK;
            break;
          default:
            initData = { ...initData, initRoomPath: processRoomPath(initRoomPath) };
            window.flags.visualizations.defaultView = visViewModes.ROOM_VIEW;
            break;
        }
      }
      if (initShotName) {
        initData = { ...initData, initShotName };
      }
      if (initDesignColors) initData = { ...initData, initDesignColors };
      return initData;
    };

    const catalogId = getFromSessionStorage("catalogid");
    const mode = getFromSessionStorage("mode");
    const initDesignPath = getFromSessionStorage("initdesign");
    const initDesignFolder = getFromSessionStorage("initdesignfolder");
    const initDesignVariation = getFromSessionStorage("initvar");
    const initShapeVariation = getFromSessionStorage("initshapevar");
    const initRoomPath = getFromSessionStorage("initview");
    const initShotName = getFromSessionStorage("initshot");
    const str = getFromSessionStorage("props");
    const defaultlang = getFromSessionStorage("lang");
    const initDesignColors = str ? decodeColorsFromString(str) : null;
    const customDesignUrl = getFromSessionStorage("customdesignurl");
    const designWidth = getFromSessionStorage("width");
    const designHeight = getFromSessionStorage("height");
    const unit = getFromSessionStorage("unit");
    const template = getFromSessionStorage("template");
    const qualityFromUrl = parseFloat(getFromSessionStorage("quality"));
    const borderDesignHgt = parseFloat(getFromSessionStorage("borderDesignHgt"));
    const borderDesignWid = parseFloat(getFromSessionStorage("borderDesignWid"));
    const borderSize = parseFloat(getFromSessionStorage("borderSize"));
    const centerDesignUrl = getFromSessionStorage("centerDesignUrl");
    const panTo = getFromSessionStorage("panTo");
    const defaultZoom = getFromSessionStorage("defaultZoom");
    const pageview = getFromSessionStorage("pageview");

    const init = async () => {
      let flags;
      try {
        const response = await AppProvider.fetchInterfaceElements();
        if (response.SessionTimeout) {
          const isMultipleLogin = JSON.parse(sessionStorage.getItem("multiplelogin"));
          if (isMultipleLogin) {
            setInitialData({ error: { type: errorTypes.SESSION_TIMEOUT } });
          } else {
            sessionStorage.setItem("relogin", true);
            window.location.reload();
          }
          return;
        }
        flags = response.flags;
      } catch (error) {
        //*! could not set interface elements */
        console.error(error);
        setInitialData({ error: { type: errorTypes.INTERFACE_ERROR, err: error } });
        return;
      }

      window.flags = deepmerge(defaultFlags, flags, { arrayMerge: overwriteMerge });
      if (window.flags.redirectToPageViewAfterLogin != "" && !window.InterfaceElements.IsAdmin) {
        window.flags.homeTemplate = window.flags.redirectToPageViewAfterLogin;
      }
      //set up langauge
      const { language: obj } = window.flags;
      initializeStrings(obj.strings);

      if (obj.langOptions && obj.langOptions.length) {
        const defLang =
          defaultlang && defaultlang !== "" ? defaultlang : obj.langOptions[0].val || null;
        if (defLang) strings.setLanguage(defLang);
        else {
          strings.setLanguage("en");
          window.flags.language.langOptions = [];
        }
      }
      if (window.flags.showWordCloud) {
        try {
          const response = await AppProvider.fetchWordCloud();
          window.WordCloudData = response && response !== "" ? response : "";
        } catch (error) {}
      }
      if (window.flags.hasExternalCssUrl) {
        if (typeof window.flags.hasExternalCssUrl === "string") {
          loadCssFile({ cssUrl: window.flags.hasExternalCssUrl });
        } else {
          const companyUrl = window.InterfaceElements.CompanyUrl;
          if (companyUrl && companyUrl !== "") {
            loadCssFile({ cssUrl: window.InterfaceElements.CompanyUrl + "/explorug.css" });
          }
        }
      }

      if (template !== null && template !== "" && typeof template === "number") {
        window.flags.thumbnailTemplate = template;
      }

      let { defaultQualityIndex } = window.flags;
      defaultQualityIndex =
        qualityFromUrl || qualityFromUrl === 0 ? qualityFromUrl : defaultQualityIndex;

      window.flags.defaultQualityIndex = defaultQualityIndex;

      if (panTo || panTo === 0) {
        var panOptions = ["CENTER", "TOPLEFT", "TOPRIGHT", "BOTTOMLEFT", "BOTTOMRIGHT"];
        var selectedPan = parseFloat(panTo) || 0;
        window.flags.designView.panTo = panOptions[selectedPan];
      }
      if (defaultZoom || defaultZoom === 0) {
        var zoom = parseFloat(defaultZoom);
        zoom =
          zoom <= window.flags.designView.maxZoom && zoom >= window.flags.designView.minZoom
            ? zoom
            : window.flags.designView.defaultZoom;
        window.flags.designView.defaultZoom = zoom;
      }

      // code block to decide the page view
      if (pageview && pageview !== "") {
        //if pageview=something is passed in url
        const page = pageViews[pageview.toUpperCase()] || ""; //converting to uppercase and checking if the passed pageview is in our pageviews list
        if (page !== "" && window.flags) {
          //if that page exist
          if (page === pageViews.CREATEYOURRUG) {
            if (
              window.flags.homeTemplate === pageViews.HOME &&
              window.flags.allowCreateYourRugTemplate
            ) {
              // for v3.explorug.com, check if window.flags.allowCreateYourRugTemplate is true before allowing CYR mode
              window.flags.homeTemplate = pageViews.CREATEYOURRUG;
            } else if (window.flags.homeTemplate !== pageViews.HOME) {
              // for CYR page, window.flags.homeTemplate = pageViews.CREATEYOURRUG, no need to check the flag window.flags.allowCreateYourRugTemplate
              window.flags.homeTemplate = pageViews.CREATEYOURRUG;
            }
          } else if (page !== pageViews.CREATEYOURRUG) {
            window.flags.homeTemplate = page;
          }
        } else if (
          //for pageview=app, page will be blank since we have no app view in our pageview list
          pageview === "app"
        ) {
          window.flags.homeTemplate = window.flags?.pageViewsOptions.app.template; //when pageview=app is passed, set the view according to the pageViewsOptions.app.template flag
        } else {
          console.log("entrypage: ", window.flags);
        }
      }
      if (window.flags?.homeTemplate.toUpperCase() === pageViews.CREATEYOURRUG) {
        window.flags.fullPageThumb = 0;
        var zoom_cyr = parseFloat(window.flags.createYourRug.defaultZoom);
        if (zoom_cyr) {
          zoom_cyr =
            zoom_cyr <= window.flags.designView.maxZoom &&
            zoom_cyr >= window.flags.designView.minZoom
              ? zoom_cyr
              : window.flags.designView.defaultZoom;
          window.flags.designView.defaultZoom = zoom_cyr;
        }
      }

      const data = {
        initDesignPath,
        initRoomPath,
        initShotName,
        initDesignColors,
        eCatalogDetails: null,
        initDesignFolder,
        initDesignVariation,
        initShapeVariation,
        customDesignUrl,
        borderDesignWid,
        borderDesignHgt,
        borderSize,
        centerDesignUrl,
        unit,
        designWidth,
        designHeight
      };
      let error = null;
      let initData;
      switch (mode) {
        case "stripedesign":
          try {
            const designPath = await AppProvider.getStripedDesign();
            data.initDesignPath = designPath;
            data.eCatalogDetails = {};
            initData = initializeData(data);
          } catch (err) {
            //*! could not get striped design */
            error = { type: errorTypes.INTERFACE_ERROR, err: err };
            console.error(err);
          }
          break;
        case "myroom":
          initData = initializeData(data);
          window.flags.visualizations.defaultView = visViewModes.MY_ROOM;
          break;
        case "ecat":
          if (catalogId) {
            let eCatalogDetails;
            try {
              eCatalogDetails = await AppProvider.eCatalogDetails({ catalogId });
            } catch (err) {
              error = { type: errorTypes.INTERFACE_ERROR, err: err };
              console.error(error);
            }
            if (eCatalogDetails) {
              const { InitView, InitDesign, Colors } = eCatalogDetails;
              data.eCatalogDetails = eCatalogDetails;
              if (InitDesign && InitDesign !== "" && InitDesign !== "undefined") {
                data.initDesignPath = InitDesign;
              }
              if (InitView && InitView !== "" && InitView !== "undefined") {
                data.initRoomPath = InitView;
              } else {
                data.initRoomPath = null;
              }
              if (Colors) {
                data.initDesignColors = decodeColorsFromString(Colors);
              }
              initData = initializeData(data);
            }
          } else {
            data.eCatalogDetails = {};
            initData = initializeData(data);
            if (!initData.initDesignPath) error = { type: errorTypes.INVALID_LINK };
          }
          break;

        default:
          initData = initializeData(data);
          break;
      }
      const {
        customDesignBaseUrl,
        corsCustomDesignUrl,
        removeBgForCustomDesign = false
      } = window.flags;
      // if (customdesignurl && (customDesignBaseUrl || corsCustomDesignUrl)) {
      //   if (corsCustomDesignUrl)
      //     initData.customdesignurl = AppProvider.createCorsUrl(customdesignurl);
      //   else initData.customdesignurl = `${customDesignBaseUrl}/${customdesignurl}`;
      //   initData.designWidth = designWidth;
      //   initData.designHeight = designHeight;
      // }
      if (customDesignUrl && (customDesignBaseUrl || corsCustomDesignUrl)) {
        if (customDesignBaseUrl) {
          initData.customDesignUrl = `${customDesignBaseUrl}/${customDesignUrl}`;
        } else {
          initData.customDesignUrl = customDesignUrl;
        }
        if (corsCustomDesignUrl) {
          const {
            Image: corsUrl,
            Outline: customDesignOutline
          } = await AppProvider.createCorsUrlJSON(
            initData.customDesignUrl,
            removeBgForCustomDesign
          );
          initData.customDesignUrl = corsUrl;
          initData.customDesignOutline = customDesignOutline;
        }
        initData.designWidth = designWidth;
        initData.designHeight = designHeight;
        initData.unit = unit;
      }
      if (designWidth && designHeight) {
        initData.designWidth = designWidth;
        initData.designHeight = designHeight;
        initData.unit = unit;
      }
      if (borderDesignHgt && borderDesignWid) {
        initData.borderDesignWid = borderDesignWid;
        initData.borderDesignHgt = borderDesignHgt;
      }
      if (centerDesignUrl) {
        initData.centerDesignUrl = centerDesignUrl;
      }
      if (borderSize || borderSize === 0) {
        initData.borderSize = borderSize;
      }
      if (unit) {
        initData.unit = unit;
      }
      window.initialData = initData;
      if (
        window.flags.showEcatModeOnly &&
        !initData.eCatalogDetails &&
        !window.InterfaceElements.IsAdmin
      ) {
        error = { type: errorTypes.URL_INCOMPLETE };
      }
      setInitialData({ ...initData, loading: false, error });

      if (window.flags.hasDisabledPrintScreen) {
        disableScreenshot();
      }
    };
    init();

    return () => {
      window.removeEventListener("message");
      // window.removeEventListener('storage')
    };
  });

  return initialData.loading ? (
    <>{/* <InitialLogoPage /> */}</>
  ) : initialData.error ? (
    <ErrorPage errorType={initialData.error.type} />
  ) : (
    <UiStateProvider
      homeTemplate={window.flags?.homeTemplate}
      initPage={
        flaginCurrentMode(window.InterfaceElements.IsAdmin, window.flags.fullPageThumb) &&
        !sessionStorage.getItem("mode")
      }
    >
      <DesignListStateProvider>
        <DesignDetailStateProvider>
          <VisualizationStateProvider>
            <ColorListStateProvider>
              <MyroomStateProvider>
                <RoomConfigStateProvider>
                  <OrdersheetDetailStateProvider>
                    <RoomviewStateProvider>
                      {/* <div dangerouslySetInnerHTML={{ __html: window.explorugIcons }}></div> */}
                      <PageTorender initialData={initialData} />
                    </RoomviewStateProvider>
                  </OrdersheetDetailStateProvider>
                </RoomConfigStateProvider>
              </MyroomStateProvider>
            </ColorListStateProvider>
          </VisualizationStateProvider>
        </DesignDetailStateProvider>
      </DesignListStateProvider>
    </UiStateProvider>
  );
};

export default EntryPage;
