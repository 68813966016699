import AppProvider from "../api/appProvider";
import strings from "../strings";
import { getDesignName, getFolderNameFromFullpath, getNameFromFullpath } from "../utils/treeutils";
import { calculateTotalArea, getMaterialNameArray } from "../utils/utils";

export const getPileheightNamesList = () => {
  let pileheightNamesToOverwrite;
  if (window.flags.colorAreaSwatch.setCustomPileHgtNameOnSelection) {
    pileheightNamesToOverwrite = strings.color.customPileheightNameOnSelection;
  }
  const defaultPileheightNames =
    pileheightNamesToOverwrite?.defaultPileheightNames || strings.color.defaultPileheightNames;
  //const customPileheightNames = pileheightNamesToOverwrite?.customPileheightNames || strings.color.customPileheightNames;
  return defaultPileheightNames;
};
export const fetchNewPropsAndPostToParent = ({ designDetailState }) => {
  return new Promise((resolve, reject) => {
    AppProvider.fetchNewProps({
      props: designDetailState.designDetails
    })
      .then(data => {
        const expData = {
          file: designDetailState.fullpath,
          propsid: data
        };
        //resolve(data);
        postDetailsToParent(expData);
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const getExplorugData = ({
  designDetailState,
  ordersheetDetailState,
  showPrice,
  price,
  passDesignNameAsDesignId = false,
  passQualityFromFolderName = false,
  sendOrigDesignColors = false,
  passTotalArea = false,
  origDesignColors
}) => {
  return new Promise((resolve, reject) => {
    AppProvider.fetchOrderSheet({
      file: designDetailState.fullpath,
      props: ordersheetDetailState?.ordersheetDetails || designDetailState.designDetails,
      customFields: ordersheetDetailState?.formFields?.fields,
      materialNames: getMaterialNameArray(),
      watermark: window.flags.designView.hasDesignWaterMark,
      mode: "postback",
      uppercasing: window.flags.ordersheet.upperCasedDesignNameinPDF,
      pilenames: window.flags.ordersheet.showPileHeightNames ? getPileheightNamesList() : "",
      pilevalues: window.flags.ordersheet.showPileHeightNames
        ? window.TextureOptions.DefaultPileheightValues
        : ""
    })
      .then(data => {
        data.explorugDomain = AppProvider.domain;
        if (
          ordersheetDetailState &&
          ordersheetDetailState.ordersheetDetails &&
          ordersheetDetailState.formFields
        ) {
          data.PhysicalWidth =
            ordersheetDetailState.ordersheetDetails.PhysicalWidth || data.PhysicalWidth;
          data.PhysicalHeight =
            ordersheetDetailState.ordersheetDetails.PhysicalHeight || data.PhysicalHeight;
          data.Unit = ordersheetDetailState.ordersheetDetails.Unit || data.Unit;
          if (ordersheetDetailState.ordersheetDetails.Carving) {
            data.Carving = ordersheetDetailState.ordersheetDetails.Carving;
          }
          if (ordersheetDetailState.ordersheetDetails.OrderProperties) {
            data.OrderProperties =
              ordersheetDetailState.ordersheetDetails.OrderProperties || data.OrderProperties;
          }
          if (ordersheetDetailState.formFields && ordersheetDetailState.formFields.fields) {
            data.formFields = ordersheetDetailState.formFields;
            if (ordersheetDetailState.formFields.fields.length) {
              if (ordersheetDetailState.formFields.fields[0]) {
                let fieldName = ordersheetDetailState.formFields.fields[0].fieldName
                  .trim()
                  .replace(/ /g, "");
                data[fieldName] = ordersheetDetailState.formFields.fields[0].fieldValue || "";
              }
              if (ordersheetDetailState.formFields.fields[1]) {
                let fieldName = ordersheetDetailState.formFields.fields[1].fieldName
                  .trim()
                  .replace(/ /g, "");
                data[fieldName] = ordersheetDetailState.formFields.fields[1].fieldValue || "";
              }
            }
          }
        }
        if (showPrice && price) {
          data.price = price;
        }
        if (passDesignNameAsDesignId) {
          const designName = getDesignName(data.file);
          const designCode = designName.split("~")[0];
          data.designid = designCode;
        }
        if (passQualityFromFolderName) {
          data.quality = getFolderNameFromFullpath(designDetailState.fullpath);
        }
        if (sendOrigDesignColors) {
          data.DesignColors_Original = origDesignColors;
        }
        if (passTotalArea) {
          data.TotalArea = calculateTotalArea(ordersheetDetailState.ordersheetDetails);
        }

        resolve(data);
        //console.log(JSON.stringify(data));
        //window.parent.postMessage(data, "*");
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const postDetailsToParent = data => {
  data = JSON.parse(JSON.stringify(data));
  //console.log("data", data)
  window.parent.postMessage(data, "*");
};

export const getDefaultValueOfField = ({ addedFields, formFieldElement }) => {
  const field = addedFields.find(field => field.ordersheetFieldId === formFieldElement.fieldName);
  let fieldValue = "";
  if (field) {
    fieldValue = field.defaultValue ? field.defaultValue : "";
  }
  return fieldValue;
};

export const getDefaultFieldValue = (addedFieldElement, formFields) => {
  const ordersheetFieldId = addedFieldElement.ordersheetFieldId,
    fieldType = addedFieldElement.type,
    defaultValue = addedFieldElement.defaultValue || "";
  let fieldVal = "";
  if (fieldType === "checkbox") {
    fieldVal = !!(getFieldValue(formFields, ordersheetFieldId, defaultValue) !== ""); //if formfield value is null or blank, checkbox=false.
  } else if (fieldType === "text") {
    fieldVal = getFieldValue(formFields, ordersheetFieldId, defaultValue);
  } else if (fieldType === "select") {
    fieldVal = getFieldValue(formFields, ordersheetFieldId, defaultValue);
  }
  return fieldVal;
};

const getFieldValue = (formFields, fieldName, defaultValue = "") => {
  const field = formFields.fields.find(element => element.fieldName === fieldName) || {};
  const value = field.fieldValue && field.fieldValue !== "" ? field.fieldValue : defaultValue;
  return value;
};

export const sendOrdersheetEmail = ({
  emailTexts,
  name,
  email,
  phone,
  note,
  designPath,
  orderSheetDetails,
  formFields,
  ordersheetFlags,
  emailCustomerOnly
}) => {
  return new Promise((resolve, reject) => {
    let {
      setCustomEmail,
      customerEmailBody,
      customerEmailSubject,
      customerEmailFromAddress,
      companyEmailBody,
      companyEmailSubject,
      companyEmailFromAddress,
      AddCustomTextInDefaultEmail,
      customerEmailFrontText,
      companyEmailFrontText,
      footer = ""
    } = emailTexts;

    var emailBody_customer = null,
      emailBody_company = null;
    if (AddCustomTextInDefaultEmail) {
      var nextline = escape("<br/>");
      let phoneNum = phone ? phone : "";

      var frontLine =
        "An order for " +
        getNameFromFullpath(designPath) +
        " has been placed through exploRUG with the following details:-";
      var frontLine_customer, frontLine_company;

      frontLine_customer = customerEmailFrontText
        ? customerEmailFrontText.replace("_______", getNameFromFullpath(designPath))
        : frontLine;
      frontLine_company = companyEmailFrontText
        ? customerEmailFrontText.replace("_______", getNameFromFullpath(designPath))
        : frontLine;

      var bodyPart =
        nextline +
        nextline +
        "%0D%0A %0D%0A CUSTOMER NAME | " +
        name.trim() +
        nextline +
        "%0D%0A %0D%0A CUSTOMER EMAIL | " +
        escape(email.trim()) +
        nextline +
        " %0D%0A %0D%0A CUSTOMER PHONE | " +
        phoneNum +
        nextline +
        "CUSTOMER NOTE | " +
        escape(note) +
        nextline;

      var customerIP = "CUSTOMER IP | " + "####ip####" + nextline + nextline;
      var lastLine = "Details about the custom order are specified in the attached Order Sheet.";
      var lastLine_customer = customerEmailBody ? customerEmailBody : lastLine;
      lastLine_customer = lastLine_customer + nextline;

      footer = footer ? footer : "";
      emailBody_customer = frontLine_customer + bodyPart + nextline + lastLine_customer + footer;

      var lastline_company = companyEmailBody ? companyEmailBody : lastLine;
      lastline_company = lastline_company + nextline;
      emailBody_company = frontLine_company + bodyPart + customerIP + lastline_company + footer;
    }
    var _orderSheetDetails = { ...orderSheetDetails };
    if (ordersheetFlags?.showAreaInM2InOrdersheetOnly && orderSheetDetails.Unit === "cm") {
      _orderSheetDetails = {
        ...orderSheetDetails,
        PhysicalHeight: orderSheetDetails.PhysicalHeight / 100,
        PhysicalWidth: orderSheetDetails.PhysicalWidth / 100,
        Unit: "m"
      };
    }
    AppProvider.sendOrdersheetEmail({
      file: designPath,
      props: _orderSheetDetails,
      name: name,
      email: email,
      phone: phone,
      note: note,
      customFields: formFields,
      sameColorDifferentSymbol: window.flags.colorAreaSwatch.differentSymbolForAllColors,
      pilenames: ordersheetFlags.showPileHeightNames ? getPileheightNamesList() : "",
      pilevalues: ordersheetFlags.showPileHeightNames
        ? window.TextureOptions.DefaultPileheightValues
        : "",
      materialNames: getMaterialNameArray(),
      setCustomEmail: setCustomEmail,
      customerEmailBody: emailBody_customer,
      customerEmailSubject: customerEmailSubject,
      customerEmailFromAddress: customerEmailFromAddress
        ? escape(customerEmailFromAddress)
        : customerEmailFromAddress,
      companyEmailBody: emailBody_company,
      companyEmailSubject: companyEmailSubject,
      companyEmailFromAddress: companyEmailFromAddress
        ? escape(companyEmailFromAddress)
        : companyEmailFromAddress,
      emailCustomerOnly: emailCustomerOnly
    })
      .then(response => {
        console.log("returnnewPromise -> response", response);
        resolve(response);
      })
      .catch(err => {
        console.log("returnnewPromise -> err", err);
        reject(err);
      });
  });
};
