import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useFullscreen, useToggle } from "react-use";
import ReactGA from "react-ga";
import { useMount } from "react-use";
import "./_MyRugStudio.scss";
import { useDesignListState, useDispatchDesignList } from "../../../reducers/designlist.reducer";
import { getCustomClass, handleNavigation } from "../../../utils/domUtils";
import { useHomeMount } from "../Home/hooks";
import { ReactComponent as Icons } from "../../symbol-defs.svg";
import Stage from "../../organisms/StageNew";
import strings from "../../../strings";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";
import { useInitDesignMount } from "../../../hooks/initDesignListHook";
import {
  useDispatchVisualization,
  useVisualizationState,
  visualizationsActions,
  visViewModes
} from "../../../reducers/visualizations.reducer";
import AppProvider, { CDN_domain, getPageName } from "../../../api/appProvider";
import SidebarActionButtons from "../../organisms/SidebarActionButtons";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { getExtension } from "../../../utils/utils";
import { resolveZoomValue } from "../../../middleware/visualization.middleware";
import QRDialog from "../../organisms/QRDialog";
import { getPathOffile } from "../../../utils/arrayUtils";
import LoadingOverlay from "../LoadingOverlay";
import { Toast, Toaster } from "@blueprintjs/core";
import RoomViewHelper from "../../organisms/RoomViewNew/roomviewhelper";
import DesignVariations from "../../organisms/DesignVariations";

var scrollBarsToggle = {
  illustrations: false
};
var roomContainerClassName = ".at-vis-thumbs";
var isInitialLoad = true;
const MyRugStudio = props => {
  const visualizationState = useVisualizationState();
  const visualizationDispatch = useDispatchVisualization();
  const designDetailsState = useDesignDetailState();
  const dispatchDesignState = useDispatchDesignDetail();
  const { designDetails } = designDetailsState;
  const dispatchUiState = useUiDispatch();
  const state = useDesignListState();
  const uiState = useUiState();
  const [showScrollBars, setShowScrollBars] = useState(scrollBarsToggle);
  const [hasVariations, setHasVariations] = useState(true);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const refCreateYourRug = useRef(null);
  const [detailViewsOptions, setDetailViewsOptions] = useState([
    visViewModes.DESIGN_VIEW_ZERO_ZOOM,
    visViewModes.DESIGN_VIEW_DEEPZOOM,
    visViewModes.DESIGN_VIEW_PERSPECTIVE
  ]);
  const [currentDetailView, setCurrentDetailView] = useState(1);
  const [isRoomviewInFullScreen, toggleIsRoomviewInFullScreen] = useToggle(false);
  const refRoomViewContainer = useRef(null);
  const [hasInitialScrollDiv, setHasInitialScrollDiv] = useState(true);
  const [showRightColumn, setShowRightColumn] = useState(false);
  const [displayIcons, setDisplayIcons] = useState(true);
  const { createYourRug: cyrFlags } = window.flags;
  const showAllRooms = window.flags.visualizations.showAllRoomFiles;
  const dispatch = useDispatchVisualization();
  const { initRoomPath, initShotName } = window.initialData;

  const roomviewhelper = new RoomViewHelper();
  useFullscreen(refRoomViewContainer, isRoomviewInFullScreen, {
    onClose: () => toggleIsRoomviewInFullScreen(false)
  });
  useHomeMount();
  useInitDesignMount();
  useMount(() => {
    const handleFetchRooms = async () => {
      const rooms = await AppProvider.fetchRoomList({});
      var filteredRooms;
      if (showAllRooms) {
        filteredRooms = rooms.filter(
          item => item.Type === "file" && getExtension(item.FullPath) === "crf3d"
        );
      } else {
        filteredRooms = rooms.filter(
          item =>
            item.Type === "file" &&
            getExtension(item.FullPath) === "crf3d" &&
            getPathOffile(item.FullPath) === "/Rooms"
        );
      }
      if (!filteredRooms.length) return;
      dispatch({
        type: visualizationsActions.SET_ILLUSTRATIONS,
        payload: filteredRooms.map(item => ({ ...item, label: item.Name }))
      });
      const roomsWithThumbs = await AppProvider.getRoomThumbnails({ rooms: filteredRooms });
      dispatch({ type: visualizationsActions.SET_ILLUSTRATIONS, payload: roomsWithThumbs });
      let ind = 0;
      const views = ["foldedbackview", "perspectiveview", "myroom"];
      if (initRoomPath && views.indexOf(initRoomPath.toLowerCase()) !== -1) return;
      if (initRoomPath && initRoomPath !== "") {
        ind = roomsWithThumbs.findIndex(
          item => item.FullPath.toLowerCase() === initRoomPath.toLowerCase()
        );
        if (ind < 0) {
          dispatchUiState({
            type: mainUiActions.SET_TOAST_PROPS,
            payload: {
              message: strings.toastMessages.triggeredRoomNotFound,
              intent: "warning",
              timeout: 7000
            }
          });
          ind = 0;
        }
      }
      if (initShotName) {
        dispatch({
          type: visualizationsActions.SET_ACTIVE_NODE,
          payload: { shot: initShotName, ...roomsWithThumbs[ind] }
        });
      } else {
        dispatch({ type: visualizationsActions.SET_ACTIVE_NODE, payload: roomsWithThumbs[ind] });
      }
    };
    handleFetchRooms();
  });

  const reset_isBusy = () => {
    setTimeout(() => {
      dispatchUiState({
        type: mainUiActions.SET_ISBUSY,
        payload: false
      });
    }, 2000);
  };

  const setToastProps = props => {
    dispatchUiState({ type: mainUiActions.SET_TOAST_PROPS, payload: props });
  };

  useEffect(() => {
    window.addEventListener("message", async event => {
      //done for iframe post on save image or room
      function handleToast(type = "image") {
        dispatchUiState({
          type: mainUiActions.SET_ISBUSY,
          payload: true
        });
        // setToastProps({
        //   message: `Saving ${type}...`,
        //   intent: "success"
        // });
      }
      if (event.data == "saveRoom") {
        handleToast();
        await window.downloadRendered3dIll();
      }
      if (event.data == "resetCarpetPosition") {
        await window.scaleCarpet(0);
        await window.resetCarpet();
      }
      if (event.data == "scaleCarpetUp") {
        await window.scaleCarpet(1);
      }
      if (event.data == "scaleCarpetDown") {
        await window.scaleCarpet(-1);
      }
      // if (event.data == "saveDesign") {
      //   handleToast();
      //   await window.downloadRenderedDesign();
      // }
      // if (event.data == "saveCustomDesignRoom") {
      //   handleToast();
      //   await window.downloadCustomDesignRoomDirectly();
      // }
      if (event.data == "dataBlobForCurrentRoomCanvas") {
        await window.returnDataUrlForCurrentCanvas();
      }
      if (event.data.action == "downloadPdfOrImage") {
        //only in myrugstudio page
        if (event.data.type == "pdf") {
          handleToast("pdf");
        } else {
          handleToast();
        }
        await window.downloadPdfOrImageForRugStudio(
          event.data.roomName,
          event.data.description,
          event.data.qrurl,
          event.data.room,
          event.data.type, //"pdf or image"
          event.data.isWatermark,
          event.data.paypalName,
          event.data.paypalEmail,
          event.data.purchasedDate
        );
      }
      if (event.data.action == "downloadPdfOrImageForCustomDesign") {
        //only in myrugstudio page
        if (event.data.type == "pdf") {
          handleToast("pdf");
        } else {
          handleToast();
        }
        await window.downloadPdfOrImageForRugStudioCustomDesign(
          event.data.roomName,
          event.data.description,
          event.data.qrurl,
          event.data.room,
          event.data.type, //"pdf or image"
          event.data.isWatermark,
          event.data.paypalName,
          event.data.paypalEmail,
          event.data.purchasedDate
        );
      }
      reset_isBusy();
    });
  }, []);

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "myRugStudio";
    document.getElementsByTagName("body")[0].id = "eMotion";
    dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 30 });

    document.getElementsByTagName("body")[0].className = "myRugStudio";
    document.getElementsByTagName("body")[0].id = "eMotion";
    let scrollTo = sessionStorage.getItem("scrollTo") || "";
    if (scrollTo) setHasInitialScrollDiv(true);
    setTimeout(() => {
      let illustrations = scrollBarsToggle.illustrations;
      const elem = document.querySelector(roomContainerClassName);
      if (elem && elem.clientWidth < elem.scrollWidth) {
        illustrations = true;
      }
      setShowScrollBars({ ...showScrollBars, illustrations });
      const elemVariations = document.querySelector("#atDesignVariations");
      //console.log("setTimeout -> elemVariations", elemVariations)
      elemVariations ? setHasVariations(true) : setHasVariations(false);
      if (scrollTo !== "") {
        scrollIntoView("#" + scrollTo);
      }
      setHasInitialScrollDiv(false);
    }, 3000);
    setTimeout(() => {
      dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 60 });
    }, 1500);
  }, []);

  useEffect(() => {
    if (!state.selectedFile) return;
    // console.log("useEffect -> state.selectedFile", state.selectedFile);
    if (state && state.selectedFile && state.selectedFile.variations) {
      setHasVariations(true);
      if (
        state.selectedFile.variations?.colors?.length === 0 &&
        state.selectedFile.variations?.shapes?.length === 0
      ) {
        setHasVariations(false);
      }
    } else {
      setHasVariations(false);
    }
  }, [state.selectedFile]);

  useEffect(() => {
    if (!designDetails) return;
    const isIrregular = designDetails.IsIrregular;
    if (
      isIrregular &&
      detailViewsOptions[currentDetailView] === visViewModes.DESIGN_VIEW_FOLDEDBACK
    ) {
      dispatchUiState({
        type: mainUiActions.SET_TOAST_PROPS,
        payload: {
          message: "Sorry, folded view is unavailable for this design.",
          intent: "warning"
        }
      });
      setCurrentDetailView(0);
    }
  }, [designDetails]);

  useEffect(() => {
    if (!visualizationState.activeNode) return;
    var thumb = document
      .querySelector(".designThumb-template0.thumb-item.active")
      ?.getBoundingClientRect();
    var container = document.querySelector(roomContainerClassName);
    if (thumb && container) {
      if (thumb.left > container?.clientWidth || thumb.left < 0) {
        container.scrollLeft = thumb.left;
      }
    }
  }, [visualizationState.activeNode]);

  const handleFullScreen = () => {
    let fcDelay = 1000;
    if (!isRoomviewInFullScreen) {
      fcDelay = 0;
    }
    if (isRoomviewInFullScreen) {
      fcDelay = 0;
    }
    if (document.body.requestFullscreen) {
      setTimeout(() => {
        toggleIsRoomviewInFullScreen();
      }, fcDelay);
    } else {
      toggleIsRoomviewInFullScreen();
    }
  };

  useEffect(() => {
    if (!hasInitialScrollDiv) {
      if (isInitialLoad) {
        //do not scroll to designview if it's initial load.
        isInitialLoad = false;
        return;
      }
      setTimeout(() => {
        const scrollToDiv = cyrFlags.scrollToDesignViewAfterVariationOnClick
          ? ".stage-wrapper.deepzoom"
          : "#room-view-container";
        scrollIntoView(scrollToDiv);
      }, 1000);
    }
  }, [state.activeVariation]);

  const scrollIntoView = querySelector => {
    if (querySelector) {
      const elem = document.querySelector(querySelector);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      } else {
        console.log(querySelector, " not found");
      }
    }
  };

  return (
    <>
      <div
        ref={refCreateYourRug}
        id="app-main"
        className={classNames("app-main-emotion", getCustomClass())}
      >
        <Icons />
        <QRDialog />
        <Toaster position="bottom-left">
          {uiState.toastProps && (
            <Toast
              message={uiState.toastProps.message}
              intent={uiState.toastProps.intent}
              timeout={uiState.toastProps.timeout || 5000}
              onDismiss={() => setToastProps(null)}
              className="myRugToast"
            />
          )}
        </Toaster>

        <div className="cyr-roomViewContainer-emotion" ref={refRoomViewContainer}>
          <div className="stage-wrapper" id="room-view-container">
            <Stage
              defaultViewMode={
                visualizationState.viewMode === visViewModes.MY_ROOM
                  ? visViewModes.MY_ROOM
                  : visViewModes.ROOM_VIEW
              }
            />
          </div>
          <SidebarActionButtons
            isFullScreen={isRoomviewInFullScreen}
            handleFullScreen={handleFullScreen}
          />
          <DesignVariations />
        </div>
      </div>
      {uiState.showLoadingOverlay && <LoadingOverlay />}
    </>
  );
};

MyRugStudio.propTypes = {};

export default MyRugStudio;
