
import pako from "pako";
export const loadingTexts = [
  "Setting up a secured connection...",
  "Getting your designs ready...",
  "Shuffling through designs...",
  "Brewing bewitching color palettes...",
  "Putting the rabbits back in the hat...",
  "Drifting on a sea of silk...",
  "Thinking of stories to tell...",
  "Cleaning up loose bits..."
];

function bytesToBase64(bytes) {
  var binary = "";
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function base64ToBytes(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}
export const renderDesignName = (designName, tildeReplacement = ' ') => {
  var designNameTitle = designName &&
    designName
      .split("~")
      .join(tildeReplacement)
      .trim();
  return designNameTitle;
}
export const uncompressJSON = (str) => {
  if (!str || str === "") return "";
  return safelyParseJSON(pako.inflate(base64ToBytes(str), { to: "string" }));
};

export const comperessJSON = (colors) => bytesToBase64(pako.deflate(JSON.stringify(colors), { to: "string" }));

export const safelyParseJSON = (json) => {
  // This function cannot be optimised, it's best to
  // keep it small!
  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    // Oh well, but whatever...
  }
  return parsed; // Could be undefined!
};
export const getFilenameFromPath = path => {
  const a = path.split("/").filter(item => item && item !== "");
  return a[a.length - 1];
};
export const getDesignStory = (fileNode, parentNode) => {
  const { Children } = parentNode;
  const { Name } = fileNode;
  const filtered = Children.filter(item => item.Name[0] === ".");
  const varFolder = filtered.find(item => item.Name.toLowerCase() === `.${Name.toLowerCase()}`);
  if (!varFolder) return null;

  const Info = uncompressJSON(varFolder.Info);
  const story = Info.story ? Info.story : Info;
  return story;
};

export const getEmailMessages = emailMessages => {
  return {
    sending: { message: emailMessages.sending, intent: "" },
    sent: { message: emailMessages.sent, intent: "success" },
    fail: { message: emailMessages.fail, intent: "danger" },
    incompleteInfo: { message: emailMessages.incompleteInfo, intent: "" },
    incompleteName: { message: emailMessages.incompleteName, intent: "" },
    invalidEmail: { message: emailMessages.invalidEmail, intent: "" },
    incompletePhoneInfo: { message: emailMessages.incompletePhoneInfo, intent: "" },
    incompleteNotesInfo: { message: emailMessages.incompleteNotesInfo, intent: "" }
  };
};