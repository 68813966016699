import React, { useEffect, useState } from "react";
import {
  Card,
  Icon,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Toast,
  Toaster
} from "@blueprintjs/core";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import AppProvider from "../../../api/appProvider";
import {
  designDetailActions,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import RoomViewHelper from "../../organisms/RoomViewNew/roomviewhelper";
const AtSizeDropdown = ({ dropdownItems, defaultItemIndex = 0, addPlaceholder = false }) => {
  const [value, setValue] = useState("Select sizes");
  const [allSizeDetails, setAllSizeDetails] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [toastProps, setToastProps] = useState(null);
  const [show, setShow] = useState(false);
  const state = useDesignListState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  let roomViewHelper = new RoomViewHelper();
  useEffect(() => {
    const originalName = state.selectedFile.name;
    const loadThumbnails = async () => {
      const resVar = await AppProvider.getDesignThumbnails({
        designs: dropdownItems
      });
      setAllSizeDetails(resVar);
      const sizes = resVar.map(filteredObj => {
        const splitName = filteredObj.name.split(originalName)[1].trim();
        return splitName;
      });
      setSizeList(sizes);
    };
    loadThumbnails();
  }, []);

  useEffect(() => {
    if (!show) return;
    setShow(false);
  }, [value]);
  const handleCustomSize = () => {
    setToastProps(null);
    if (!input1 || !input2) {
      setToastProps({ message: "Please fill both the fields", intent: Intent.DANGER });
      return;
    }
    setValue(input1 + "x" + input2);
    window.changeCarpetSize(input1, input2);
  };

  const handleSizeChange = item => {
    setValue(item);
    const fullName = state.selectedFile.name + " " + item;
    const getSelectedSize = allSizeDetails.find(item => item.name == fullName);
    dispatchDesignDetails({
      type: designDetailActions.SET_DETAILS,
      payload: {
        designDetails: getSelectedSize.designProps,
        label: fullName,
        fullpath: getSelectedSize.fullPath,
        originalName: state.selectedFile.name
      }
    });
  };
  const exampleMenu = (
    <>
      <Toaster position="bottom">
        {toastProps && (
          <Toast
            message={toastProps.message}
            intent={toastProps.intent}
            onDismiss={() => setToastProps(null)}
          />
        )}
      </Toaster>
      <Menu style={{ textAlign: "center" }}>
        <MenuItem
          text="Original"
          key="original"
          onClick={() => {
            setValue("Select sizes");
            setShow(false);
            dispatchDesignDetails({
              type: designDetailActions.SET_DETAILS,
              payload: {
                designDetails: state.selectedFile.designProps,
                label: state.selectedFile.name,
                fullpath: state.selectedFile.fullPath,
                originalName: state.selectedFile.name
              }
            });
          }}
        />
        {sizeList.map(item => (
          <MenuItem text={item} key={item} onClick={() => handleSizeChange(item)} />
        ))}
        <MenuDivider />
        <span>Custom size input:</span>
        <div className="sizeInputDiv">
          <input
            type="number"
            className="sizeInput"
            value={input1}
            onChange={e => setInput1(e.target.value)}
            pattern="^[0-9]"
            min="1"
          />
          &nbsp;&nbsp;x&nbsp;&nbsp;
          <input
            type="number"
            className="sizeInput"
            value={input2}
            onChange={e => setInput2(e.target.value)}
            pattern="^[0-9]"
            min="1"
          />
        </div>
        <button className="sizeConfirmButton" onClick={handleCustomSize}>
          Confirm
        </button>
      </Menu>
    </>
  );

  return (
    <Popover content={exampleMenu} fill={true} placement="bottom-right" isOpen={show}>
      <p style={{ cursor: "pointer" }} onClick={() => setShow(!show)}>
        <b>{value}</b>
        <Icon icon="caret-down" />
      </p>
    </Popover>
  );
};

export default AtSizeDropdown;
