//this component shows ordersheet or ordersheet button when necessary
import React, { Suspense, useEffect } from "react";
import OrderSheet from ".";
import AppProvider from "../../../api/appProvider";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";
import { showOrdersheetOutside } from "../../../utils/flagUtils";
import TearSheet from "../../molecules/TearSheet";
import OrdersheetButton from "./OrdersheetBtn";

const OrdersheetArea = props => {
  const { openOrdersheetBydefault } = props;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const { customFields } = designDetailState;
  const dispatchUiState = useUiDispatch();
  const uiState = useUiState();
  const setOpenOrderSheet = show =>
    dispatchUiState({ type: mainUiActions.SET_SHOW_ORDERSHEET, payload: show });
  const setCustomFieldsLoading = value => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS_LOADING, payload: value });
  };
  const setCustomFields = fields => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS, payload: fields });
  };

  const displayOrderSheetButton = () => {
    return (
      window.flags.hasOrderSheet &&
      !showOrdersheetOutside() &&
      !uiState.showOrderSheet &&
      window.flags.createYourRug.showOrdersheetBtnBelowColorsArea
    );
  };

  useEffect(() => {
    if (showOrdersheetOutside()) {
      if (customFields.fields) {
        setOpenOrderSheet(true);
        return;
      }
      setCustomFieldsLoading(true);
      AppProvider.fetchCustomFieldOrdersheet()
        .then(data => {
          setCustomFieldsLoading(false);
          setCustomFields(data);
          setOpenOrderSheet(true);
        })
        .catch(err => {})
        .finally(() => {
          setCustomFieldsLoading(false);
        });
    }
  }, []);

  return (
    <div>
      {window.flags.hasOrderSheet &&
        (showOrdersheetOutside() || uiState.showOrderSheet) &&
        window.flags.createYourRug.showOrdersheetBtnBelowColorsArea && (
          <Suspense fallback={<></>}>
            <OrderSheet openOrdersheetBydefault={openOrdersheetBydefault} />
          </Suspense>
        )}
      <div className="cyr-sheet-btn">
        {displayOrderSheetButton() && (
          <div className="cyr-ordersheet-btn">
            <OrdersheetButton />
          </div>
        )}
        {window.flags.ordersheet.showTearSheet && (
          <TearSheet type={displayOrderSheetButton() ? "tertiary" : "primary"} />
        )}
      </div>
    </div>
  );
};

OrdersheetArea.propTypes = {};

export default OrdersheetArea;
