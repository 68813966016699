/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { AtSlider } from "../../molecules/Slider";
import { useMount } from "react-use";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import AppProvider from "../../../api/appProvider";

import {
  calculateZoomLevelFromZoom,
  getWatermarkBounds,
  makeDoubleClick
} from "./visualizationutils";
import { AreaSwatch } from "../../molecules/AreaSwatch";
import AtIcon from "../../atoms/AtIcon";
import { usePrevious } from "../../../hooks";

import { downloadImageData } from "../../../utils/canvasutils";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  useUiState,
  useUiDispatch,
  mainUiActions,
  pageViews
} from "../../../reducers/mainui.reducer";
import { getTilepoints } from "./visualizationutils";
import { convertNameToTilePoint, toastErrors } from "../../../utils/utils";

import { getFromSessionStorage, readImage } from "../../../utils/domUtils";
import { useDispatchColorList, colorListActions } from "../../../reducers/colors.reducer";
import { AtSpinner } from "../../atoms/AtSpinner";
import classNames from "classnames";
import { Portal, ButtonGroup } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
import { useVisualizationState, visViewModes } from "../../../reducers/visualizations.reducer";
import { resolveZoomValue } from "../../../middleware/visualization.middleware";

const leaflet = window.L;
let map;
let tilePoints;

const options = {
  tileSize: 256
};
let layer;
let needsPanning = true;
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function initMap({ onDesignClick, onDesignloaded, onDrag, zoomStep = 0.25 }) {
  map = leaflet.map("map", {
    crs: leaflet.CRS.Simple,
    doubleClickZoom: false,
    keyboard: false,
    scrollWheelZoom: false,
    touchZoom: true,
    fadeAnimation: false,
    bounceAtZoomLimits: false,
    zoomControl: false,
    attributionControl: false,
    wheelDebounceTime: 500,
    zoomSnap: zoomStep,
    zoomDelta: zoomStep,
    dragging:
      window.flags?.homeTemplate === pageViews.CREATEYOURRUG ? !leaflet.Browser.mobile : true,
    tap: window.flags?.homeTemplate === pageViews.CREATEYOURRUG ? !leaflet.Browser.mobile : true,
    maxBoundsViscosity: 0.5
  });
  map.on("drag", onDrag);
  const handleClick = e => {
    const zoom = map.getZoom();
    let designPoint = e.layerPoint.add(map.getPixelOrigin());
    const zoomScale = map.getZoomScale(zoom, 1);
    const { heightRatio = 1, dpi } = layer.options;
    const zoomlevel = calculateZoomLevelFromZoom(zoom, layer.options.clampZoom);
    designPoint = {
      x: (designPoint.x / zoomScale / heightRatio) * dpi,
      y: (designPoint.y / zoomScale / heightRatio) * dpi
    };
    if (onDesignClick) onDesignClick({ designPoint, ...e, zoomlevel });
  };
  const handleDoubleClick = e => {};

  const stopTriggerClickOnDoubleClick = e => {
    var funcToRun = makeDoubleClick(handleDoubleClick, handleClick);
    funcToRun(e);
  };
  map.on("click", stopTriggerClickOnDoubleClick);
}
if (leaflet)
  leaflet.GridLayer.Visualization = leaflet.GridLayer.extend({
    initialize: function(options) {
      leaflet.Util.setOptions(this, options);
    },
    options: {
      tileWidth: options.tileSize,
      tileHeight: options.tileSize,
      maxNativeZoom: 3,
      noWrap: true,
      clampZoom: options.clampZoom
    },
    getTileSize: function() {
      const { tileWidth, tileHeight, designDetails, heightRatio, dpi } = this.options;
      return new leaflet.Point(
        (tileWidth * heightRatio) / dpi,
        (tileHeight * heightRatio * designDetails.KLRatio) / dpi
      );
    },
    _setView: function(center, zoom, noPrune, noUpdate) {
      var tileZoom = this.getRoundedZoom(zoom);

      if (
        (this.options.maxZoom !== undefined && tileZoom > this.options.maxZoom) ||
        (this.options.minZoom !== undefined && tileZoom < this.options.minZoom)
      ) {
        tileZoom = undefined;
      } else {
        tileZoom = this._clampZoom(tileZoom);
      }

      var tileZoomChanged = this.options.updateWhenZooming && tileZoom !== this._tileZoom;

      if (!noUpdate || tileZoomChanged) {
        this._tileZoom = tileZoom;

        if (this._abortLoading) {
          this._abortLoading();
        }

        this._updateLevels();
        this._resetGrid();

        if (tileZoom !== undefined) {
          this._update(center);
        }

        if (!noPrune) {
          this._pruneTiles();
        }

        // Flag to prevent _updateOpacity from pruning tiles during
        // a zoom anim or a pinch gesture
        this._noPrune = !!noPrune;
      }

      this._setZoomTransforms(center, zoom);
    },
    _initTile: function(tile) {
      leaflet.DomUtil.addClass(tile, "leaflet-tile");
      tile.onselectstart = leaflet.Util.falseFn;
      tile.onmousemove = leaflet.Util.falseFn;

      // update opacity on tiles in IE7-8 because of filter inheritance problems
      if (leaflet.Browser.ielt9 && this.options.opacity < 1) {
        leaflet.DomUtil.setOpacity(tile, this.options.opacity);
      }

      if (leaflet.Browser.android && !leaflet.Browser.android23) {
        tile.style.WebkitBackfaceVisibility = "hidden";
      }
    },
    getRoundedZoom(zoom) {
      return this.options.clampZoom ? Math.ceil(zoom) : Math.round(zoom);
    },
    updateCurrentLevel: function(zoom) {
      let zoomlvltoupdate = this.getRoundedZoom(zoom);
      zoomlvltoupdate = zoomlvltoupdate > 3 ? 3 : zoomlvltoupdate;
      this._removeTilesAtZoom(zoomlvltoupdate);
      this._update();
    },
    removeCurrentlevel: function(zoom) {
      let zoomlvltoupdate = this.getRoundedZoom(zoom);
      zoomlvltoupdate = zoomlvltoupdate > 3 ? 3 : zoomlvltoupdate;
      this._removeTilesAtZoom(zoomlvltoupdate);
    },
    createTile: function(coords, done) {
      const tile = document.createElement("img");
      tile.setAttribute("role", "presentation");
      let point;
      let i;
      if (coords.z === 1) {
        i = tilePoints.findIndex(
          item => item.x === coords.x && item.y === coords.y && item.z === 1
        );
      }
      if (coords.z === 2) {
        i = tilePoints.findIndex(
          item => item.x === coords.x && item.y === coords.y && item.z === 2
        );
      }
      if (coords.z === 3) {
        i = tilePoints.findIndex(
          item => item.x === coords.x && item.y === coords.y && item.z === 4
        );
      }
      if (i !== -1) {
        point = tilePoints[i];
        if (point && point.src) {
          tile.src = "";

          setTimeout(() => {
            tile.src = point.src;
            tile.onload = () => {
              const { width, height } = tile;
              tile.style.width = `${(width * this.options.heightRatio) / this.options.dpi + 1}px`;
              tile.style.height = `${(height *
                this.options.heightRatio *
                this.options.designDetails.KLRatio) /
                this.options.dpi +
                1}px`;
              done(null, tile);
            };
            tile.onerror = error => {
              done(error, tile);
            };
          }, 2);
        }
      }
      return tile;
    }
  });
const Visualization = () => {
  const minZoom = window.flags.designView.minZoom,
    maxZoom = window.flags.designView.maxZoom,
    step = 0.25;
  const clampZoom = window.flags.designView.clampZoom;
  const felt = window.flags.isFelt || 0;

  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const visState = useVisualizationState();
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatchColorList = useDispatchColorList();
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  const {
    designDetails,
    fullpath,
    tileDetails,
    updateDesignTiles,
    updateNormapTiles,
    designName,
    designViewZoom,
    hash
  } = designDetailState;

  const zoomValue =
    window.flags.homeTemplate === pageViews.CREATEYOURRUG ? designViewZoom : resolveZoomValue();
  const [zoom, setZoom] = useState(zoomValue);
  const previousZoom = usePrevious(zoom);
  const previousFullpath = usePrevious(fullpath);
  const [customizing, setCustomizing] = useState(false);
  const dpi = window.flags.designView.dpi || 1;

  useEffect(() => {
    if (window.flags.homeTemplate === pageViews.CREATEYOURRUG) {
      if (visState.viewMode == visViewModes.DESIGN_VIEW_ZERO_ZOOM) {
        setZoom(window.flags.designView.minZoom);
      } else {
        setZoom(designViewZoom);
      }
    }
  }, [visState.viewMode]);
  const handleZoomChange = zoom => {
    if (zoom < minZoom || zoom > maxZoom) return;
    toggleScout(false);
    setZoom(zoom);
  };
  const toggleScout = value => {
    dispatchDesignDetails({ type: designDetailActions.TOOGLE_CUSTOSCOUT, payload: value });
  };
  const resetScout = () => {
    dispatchDesignDetails({
      type: designDetailActions.SET_CUSTOSCOUT_COORDS,
      payload: { show: false, designPoint: null, containerPoint: null }
    });
  };
  const setLoading = value => {
    dispatchDesignDetails({
      type: designDetailActions.SET_LOADING,
      payload: value
    });
  };
  const handleDownloadDesign = () => {
    const zoomlevel = calculateZoomLevelFromZoom(zoom, clampZoom);
    const watermarkOptions = {
      hasWatermark: window.flags.designView.hasDesignWaterMark,
      logoUrl: window.InterfaceElements.LogoUrl,
      ...window.flags.designView.watermarkOptions
    };
    return AppProvider.getRenderedDesign({
      designDetails,
      designName,
      fullpath,
      hash,
      felt,
      zoom: zoomlevel === 4 ? 2 : zoomlevel,
      watermarkOptions
      // logoUrl: window.flags.designView.hasDesignWaterMark ? window.InterfaceElements.LogoUrl : null
    }).then(canvas => {
      designDetails.IsIrregular
        ? downloadImageData(canvas, `${designName}.png`, "png")
        : downloadImageData(canvas, `${designName}.jpg`, "jpg");
    });
  };
  window.downloadRenderedDesign = handleDownloadDesign;
  const handleDesignClick = e => {
    const { containerPoint, designPoint } = e;

    dispatchDesignDetails({
      type: designDetailActions.SET_CUSTOSCOUT_COORDS,
      payload: {
        containerPoint,
        designPoint
      }
    });
    dispatchDesignDetails({
      type: designDetailActions.SET_CUSTOSCOUT_ID_TO_HIDE,
      payload: "areaSwatchRoomView"
    });
  };
  const [debouncedDesignClick] = useDebouncedCallback(handleDesignClick, 300);
  useMount(() => {
    needsPanning = true;
    initMap({
      onDesignClick: debouncedDesignClick,
      onDrag: () => toggleScout(false),
      zoomStep: step
    });
    map.on("zoomend", e => {
      handleZoomChange(map.getZoom());
    });
  });
  useEffect(() => {
    let la = true;
    const { fullpath, custoScout, designDetails } = designDetailState;
    if (!fullpath) return;
    const { designPoint } = custoScout;
    const { Width, Height, KLRatio } = designDetails;
    if (!designPoint || !Width) return;
    const { x, y } = designPoint;
    const desx = Math.round(x);
    const desy = Math.round(y / KLRatio);
    if (desx < 0 || desy < 0 || desx > Width || desy > Height) {
      toggleScout(false);
      return;
    }
    toggleScout(false);
    AppProvider.getColorAt({
      file: fullpath,
      x: desx,
      y: desy,
      Width,
      Height
    }).then(color => {
      if (!la || color === 255) return;
      dispatchDesignDetails({ type: designDetailActions.SELECT_DESIGN_COLOR, payload: color });
      toggleScout(true);
    });
    return () => {
      la = false;
    };
  }, [designDetailState.custoScout.designPoint]);
  const getFilteredTilePoints = () => {
    let zoomlevel = calculateZoomLevelFromZoom(zoom, clampZoom);
    let colorIndex;
    const filteredTilepoints = tilePoints.filter(item => item.z === zoomlevel);
    let tilePointstoUpdate = filteredTilepoints;
    if (updateDesignTiles) {
      // updateDesignTiles is changed when a color is changed. Need to update the tiles for room visualization
      colorIndex = updateDesignTiles.colorIndex;
      tilePointstoUpdate = filteredTilepoints.filter(
        item => item.updateDesignTiles !== updateDesignTiles || !item.src
      );
    }
    if (updateNormapTiles) {
      // updateNormapTiles is update when pile height is changed.. We don't use normaptiles and designtiles in design visualization but we need it for room visualization
      colorIndex = updateNormapTiles.colorIndex;
      tilePointstoUpdate = filteredTilepoints.filter(
        item => item.updateNormapTiles !== updateNormapTiles || !item.src
      );
    }
    let filteredColorTilepoints;
    if (colorIndex && colorIndex !== -1 && !window.flags.isFelt) {
      //need to update all the tiles for felt accounts
      const tileData = tileDetails[`colorTileData${zoomlevel}`];
      if (!tileData)
        throw new Error("Could not get tile data, ensure that tiledetails contains tiledata");
      const colorTileData = tileData[colorIndex];
      if (!colorTileData) {
        throw new Error("tiledata does not contain color tile data for the selected index");
      }
      const colorTilePoints = colorTileData.tiles.map(item => convertNameToTilePoint(item));
      filteredColorTilepoints = tilePointstoUpdate.filter(item =>
        colorTilePoints.some(pnt => pnt.x === item.x && pnt.y === item.y)
      );
    } else {
      filteredColorTilepoints = tilePointstoUpdate;
    }
    return filteredColorTilepoints;
  };
  const fetchVisualizationTiles = props => {
    return new Promise((resolve, reject) => {
      AppProvider.fetchVisualizationTiles(props)
        .then(resolve)
        .catch(error => {
          let err;
          if (error.response)
            err =
              error.response.status === 404 ? toastErrors.NO_CONNECTION : toastErrors.SERVER_ERROR;
          if (error.message === "Network Error") err = toastErrors.NO_CONNECTION;

          dispatchUiState({
            type: mainUiActions.SET_TOAST_PROPS,
            payload: {
              message: err.message,
              intent: "warning"
            }
          });
          reject(error);
        });
    });
  };
  useEffect(() => {
    let la = true;
    resetScout();
    const { designDetails, fullpath, hash, designDimsOrig } = designDetailState;
    if (!fullpath) return;
    const loadVisualization = async () => {
      let zoomlevel = calculateZoomLevelFromZoom(zoom, clampZoom);
      let prevZoomLevel = calculateZoomLevelFromZoom(previousZoom, clampZoom);
      const props = sessionStorage.getItem("props") || "";
      if (previousFullpath !== fullpath || props !== "") {
        setLoading(true);
        const { Height, KLRatio } = designDetails;
        const { Width } = designDimsOrig;
        map.eachLayer(function(layer) {
          map.removeLayer(layer);
        });
        const zoomlevel = calculateZoomLevelFromZoom(zoom, clampZoom);
        let heightRatio = window.innerHeight / (Height * KLRatio);
        if (heightRatio > 1) heightRatio = 1;

        const tps = getTilepoints({
          Width,
          Height,
          KLRatio,
          heightRatio,
          tileSize: options.tileSize,
          dpi
        });
        tilePoints = tps.tilePoints;
        const tilecenter = tps.tilecenter;

        const designApiProps = {
          file: fullpath,
          zoom: zoomlevel,
          props: designDetails,
          felt
        };

        layer = new leaflet.GridLayer.Visualization({
          designDetails,
          heightRatio,
          dpi,
          maxNativeZoom: 3,
          minNativeZoom: 1,
          clampZoom: clampZoom,
          minZoom,
          maxZoom
        });
        layer.addTo(map);

        const points = tilePoints.filter(point => point.z === zoomlevel);
        if (!points.length) throw new Error("Points length should not be zero");
        const pointNames = points.map(item => item.name);
        let baseDesignPath;
        try {
          baseDesignPath = await fetchVisualizationTiles({
            ...designApiProps,
            tiles: pointNames
          });
        } catch (error) {
          return;
        }
        if (!la) return;
        points.forEach(pnt => {
          const i = tilePoints.findIndex(
            item => item.x === pnt.x && item.y === pnt.y && item.z === pnt.z
          );
          let path = `${baseDesignPath}/${tilePoints[i].name}.rendered.jpg?t=${hash}`;
          tilePoints[i].src = path;
          tilePoints[i].hash = hash;
        });
        map.setZoom(zoom);
        layer.redraw();
        if (needsPanning || window.innerHeight / Height > 1) {
          needsPanning = false;
          panDesign(window.flags.designView.panTo, tilecenter);
        } else {
          const center = map.getCenter();
          map.panTo([center.lat, tilecenter.x], { animate: false });
          //map.panTo([-center.lat, -tilecenter.x], { animate: false });
        }
        let p1 = leaflet.latLng(10, tilecenter.x * 2 + 10),
          p2 = leaflet.latLng(-tilecenter.y * 2 - 10, -10),
          bounds = leaflet.latLngBounds(p1, p2);
        map.setMaxBounds(bounds);

        if (window.flags.designView.hasDesignWaterMark) {
          const opt = window.flags.designView.watermarkOptions;
          const overlayUrl = window.InterfaceElements.LogoUrl;
          readImage(overlayUrl).then(overlayImage => {
            const hgt = (overlayImage.height / overlayImage.width) * opt.width;
            let imageBounds = getWatermarkBounds({
              tilecenter,
              padding: 5,
              offset: opt.position,
              height: hgt,
              width: opt.width
            });
            let imageUrl = overlayUrl;
            leaflet.imageOverlay(imageUrl, imageBounds, { opacity: opt.opacity }).addTo(map);
          });
        }
        let tileData = null;
        try {
          tileData = await AppProvider.fetchTileDetails({
            file: designDetailState.fullpath
          });
        } catch (error) {
          console.log("error while calling tile details", error);
        }

        if (!la) return;
        dispatchDesignDetails({
          type: designDetailActions.SET_TILE_DETAILS,
          payload: tileData
        });
        // map.panTo([-map.getSize().y / (4 * zoom), tilecenter.x]);
        layer.on("load", () => {
          setLoading(false);
        });
        setLoading(false);
      } else {
        if (previousZoom !== zoom) {
          map.setZoom(zoom);
          if (prevZoomLevel !== zoomlevel) {
            const points = tilePoints.filter(point => point.z === zoomlevel && point.hash !== hash);
            if (points.length) {
              setCustomizing(true);
              points.forEach(pnt => {
                const i = tilePoints.findIndex(
                  item => item.x === pnt.x && item.y === pnt.y && item.z === pnt.z
                );
                tilePoints[i].src = null;
              });
              layer.updateCurrentLevel(zoom);
              const pointNames = points.map(item => item.name);
              const baseDesignPath = await fetchVisualizationTiles({
                file: fullpath,
                props: designDetails,
                zoom: zoomlevel,
                tiles: pointNames,
                felt
              });
              if (la) {
                points.forEach(pnt => {
                  const i = tilePoints.findIndex(
                    item => item.x === pnt.x && item.y === pnt.y && item.z === pnt.z
                  );
                  tilePoints[
                    i
                  ].src = `${baseDesignPath}/${tilePoints[i].name}.rendered.jpg?t=${hash}`;
                  tilePoints[i].hash = hash;
                });
              }

              layer.updateCurrentLevel(zoom);
              setTimeout(() => {
                setCustomizing(false);
              }, 1000);
              if (designDetailState.loading) setLoading(false);
            }
          }
        } else {
          setLoading(true);
          const filteredColorTilepoints = getFilteredTilePoints();
          if (filteredColorTilepoints.length) {
            layer.removeCurrentlevel(zoom);
            const filteredColorTilepointnames = filteredColorTilepoints.map(item => item.name);
            const baseDesignPath = await fetchVisualizationTiles({
              file: fullpath,
              zoom: zoomlevel,
              tiles: filteredColorTilepointnames,
              props: designDetails,
              felt
            });
            if (la) {
              filteredColorTilepoints.forEach(item => {
                const { x, y, name } = item;
                const index = tilePoints.findIndex(
                  item => item.x === x && item.y === y && item.z === zoomlevel
                );
                if (index === -1) {
                  console.error("index -1 for tile", x, y);
                  return;
                }
                tilePoints[index].src = `${baseDesignPath}/${name}.rendered.jpg?t=${hash}`;
                tilePoints[index].hash = hash;
              });
            }

            layer.updateCurrentLevel(zoom);
          }
          setLoading(false);
        }
      }
    };
    const debouncedLoad = debounce(() => {
      loadVisualization();
      const startMode = getFromSessionStorage("startmode") || "";
      if (startMode.toLowerCase() === "rugshare") showRugshareDialog();
    }, 400);
    debouncedLoad();
    return () => {
      //using async await nonce function ( la becomes false when it's called second time)
      la = false;
    };
  }, [hash, zoom]);

  const panDesign = (direction, tilecenter) => {
    switch (direction) {
      case "TOPLEFT":
        map.panTo([0, 0], { animate: false });
        break;
      case "TOPRIGHT":
        map.panTo([0, tilecenter.x * 2], { animate: false });
        break;
      case "BOTTOMLEFT":
        map.panTo([-tilecenter.y * 2, 0], { animate: false });
        break;
      case "BOTTOMRIGHT":
        map.panTo([-tilecenter.y * 2, tilecenter.x * 2], { animate: false });
        break;
      default:
        map.panTo([-tilecenter.y, tilecenter.x], { animate: false });
        break;
    }
  };
  const showRugshareDialog = () => {
    const rugshareCondition = window.InterfaceElements.IsAdmin
      ? window.InterfaceElements.ShowRugshare
      : window.InterfaceElements.ShowRugshare && window.flags.hasRugshare;
    const startMode = getFromSessionStorage("startmode") || "";
    if (rugshareCondition && startMode.toLowerCase() === "rugshare") {
      sessionStorage.setItem("startmode", 0);
      dispatchUiState({ type: mainUiActions.SET_SHOW_RUGSHARE, payload: true });
    }
  };
  const getActualZoomFactor = () => {
    const zoomFactor =
      map && layer ? map.getZoomScale(map.getZoom(), 1) * layer.options.heightRatio * dpi : zoom;
    return zoomFactor;
  };
  return (
    <div
      className="tile-container"
      style={{ width: "100%", height: "100%", overflow: "hidden", position: "relative" }}
      ref={containerRef}
    >
      <div id="map" ref={canvasRef}></div>
      <div className={classNames("at-vis-spinner", { "at-vis-spinner--hidden": !customizing })}>
        <div className="at-vis-spinner__text">Updating design... </div>
        <AtSpinner size="sm" />
      </div>
      {/* {!uiState.showLoadingOverlay && (
        <Portal container={document.getElementById("right-sidebar")}>
          <ButtonGroup id="at-zoom-buttons">
            <AtButton className="at-zoom-buttons-zoomIn" icon="zoom-in" />
            <AtButton className="at-zoom-buttons-zoomIn" icon="zoom-out" />
          </ButtonGroup>
        </Portal>
      )} */}
      {window.flags.zoomElement === 0 ? (
        <AtSlider
          isIdle={uiState.isIdle}
          value={zoom}
          zoomFactor={getActualZoomFactor()}
          onRelease={handleZoomChange}
          min={minZoom}
          max={maxZoom}
          stepSize={step}
        />
      ) : !uiState.showLoadingOverlay ? ( //starkallegro
        <Portal container={document.getElementById("right-sidebar")}>
          <ButtonGroup vertical id="at-zoom-buttons">
            <AtButton
              className="at-zoom-buttons-zoomIn"
              icon="zoom-in"
              onClick={() => {
                toggleScout(false);
                let newZoom = zoom + step;
                if (newZoom >= maxZoom) newZoom = minZoom;
                setZoom(newZoom);
              }}
            />
            <AtButton
              className="at-zoom-buttons-zoomIn"
              icon="zoom-out"
              onClick={() => {
                toggleScout(false);
                let newZoom = zoom - step;
                if (newZoom <= minZoom) newZoom = minZoom;
                setZoom(newZoom);
              }}
            />
          </ButtonGroup>
        </Portal>
      ) : (
        <></>
      )}

      {!designDetailState.loading &&
        designDetailState.selectedColor !== -1 &&
        uiState.allowColorCustomization &&
        !uiState.isOneActFolder &&
        designDetailState.custoScout.show &&
        designDetailState.custoScout.containerPoint !== null &&
        designDetailState.custoScout.custoScoutIdforCyr !== "areaSwatchVisualization" && (
          <div
            style={{
              position: "absolute",
              left: `${designDetailState.custoScout.containerPoint.x}px`,
              top: `${designDetailState.custoScout.containerPoint.y}px`,
              zIndex: 100
            }}
            className="at-custo-assistant-scout ui"
            id="areaSwatchVisualization"
          >
            <AreaSwatch
              designColor={
                designDetailState.designDetails.DesignColors[designDetailState.selectedColor]
              }
              colorIndex={designDetailState.selectedColor}
              popoverPosition="auto"
              colorNumber={designDetailState.colorNumbers[designDetailState.selectedColor]}
              handleClick={e => e.stopPropagation()}
              onColorPieClick={(color, e) => {
                dispatchColorList({ type: colorListActions.SELECT_COLOR, payload: color });
              }}
            />
            <AtIcon
              icon="close"
              onClick={() =>
                dispatchDesignDetails({
                  type: designDetailActions.TOOGLE_CUSTOSCOUT,
                  payload: false
                })
              }
            />
          </div>
        )}
    </div>
  );
};

export default Visualization;
