import React, { useState } from "react";
import { CollapsibleSectionHeading } from "../../molecules/CustomCollapse";
import { useMount } from "react-use";
import AppProvider, { CDN_domain } from "../../../api/appProvider";
import Thumbnail from "../../molecules/Thumbnail";
import {
  visualizationsActions,
  visViewModes,
  useVisualizationState,
  useDispatchVisualization
} from "../../../reducers/visualizations.reducer";
import { useDebouncedCallback } from "use-debounce/lib";
import { Collapse } from "@blueprintjs/core";
import strings from "../../../strings";
import { useMyroomState } from "../../../reducers/myroom.reducer";

import { getExtension } from "../../../utils/utils";
import { getPathOffile } from "../../../utils/arrayUtils";
import ReactGA from "react-ga";
import DesignTopViewThumb from "../../molecules/DesignTopViewThumb";
import { mainUiActions, pageViews, useUiDispatch } from "../../../reducers/mainui.reducer";
const myroomThumbUrl = CDN_domain + "v3assets/myroom/My-Room-Thumb.svg";
const RoomList = props => {
  const { scrollIntoView } = props;
  const dispatchUiState = useUiDispatch();

  const { visualizations } = window.flags;

  const { initRoomPath, initShotName } = window.initialData;

  const state = useVisualizationState();
  const myroomState = useMyroomState();
  const dispatch = useDispatchVisualization();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const pageView = window.flags.homeTemplate;
  const showAllRooms = window.flags.visualizations.showAllRoomFiles;
  useMount(() => {
    const handleFetchRooms = async () => {
      const rooms = await AppProvider.fetchRoomList({});
      const roomsControl = window.flags.roomsControl || [];
      var filteredRoomsFolder;
      if (window.flags.homeTemplate == pageViews.STUDIOENTRY) {
        filteredRoomsFolder = rooms.filter(
          item => item.Type === "folder" && item.FullPath !== "Rooms"
        );
        dispatch({ type: visualizationsActions.SET_ROOM_FOLDERS, payload: filteredRoomsFolder });
      }
      if (roomsControl.length) {
        dispatch({ type: visualizationsActions.SET_ROOMS_CONTROL, payload: roomsControl });
      }
      var filteredRooms;
      if (showAllRooms) {
        filteredRooms = rooms.filter(
          item => item.Type === "file" && getExtension(item.FullPath) === "crf3d"
        );
      } else {
        filteredRooms = rooms.filter(
          item =>
            item.Type === "file" &&
            getExtension(item.FullPath) === "crf3d" &&
            getPathOffile(item.FullPath) === "/Rooms"
        );
      }
      if (!filteredRooms.length) return;
      dispatch({
        type: visualizationsActions.SET_ILLUSTRATIONS,
        payload: filteredRooms.map(item => ({ ...item, label: item.Name }))
      });
      var roomsWithThumbs;
      if (window.flags.homeTemplate == pageViews.STUDIOENTRY) {
        roomsWithThumbs = filteredRooms;
      } else {
        roomsWithThumbs = await AppProvider.getRoomThumbnails({ rooms: filteredRooms });
      }
      dispatch({ type: visualizationsActions.SET_ILLUSTRATIONS, payload: roomsWithThumbs });
      let ind = 0;
      const views = ["foldedbackview", "perspectiveview", "myroom"];
      if (initRoomPath && views.indexOf(initRoomPath.toLowerCase()) !== -1) return;
      if (initRoomPath && initRoomPath !== "") {
        ind = roomsWithThumbs.findIndex(
          item => item.FullPath.toLowerCase() === initRoomPath.toLowerCase()
        );
        if (ind < 0) {
          dispatchUiState({
            type: mainUiActions.SET_TOAST_PROPS,
            payload: {
              message: strings.toastMessages.triggeredRoomNotFound,
              intent: "warning",
              timeout: 7000
            }
          });
          ind = 0;
        }
      }
      if (initShotName) {
        dispatch({
          type: visualizationsActions.SET_ACTIVE_NODE,
          payload: { shot: initShotName, ...roomsWithThumbs[ind] }
        });
      } else if (window.flags.homeTemplate == pageViews.STUDIOENTRY) {
        var initRoomFolderIndex = 0;
        var initRoomFileIndex = 0;
        if (initRoomPath && initRoomPath !== "") {
          const initRoomFolder = initRoomPath.toLowerCase().split("/")[1];
          const initRoom = initRoomPath.toLowerCase().split("/")[2];
          initRoomFolderIndex = filteredRoomsFolder.findIndex(
            item => item.Name.toLowerCase() == initRoomFolder.toLowerCase()
          );
          initRoomFileIndex = filteredRoomsFolder[initRoomFolderIndex].files.findIndex(item =>
            item.toLowerCase().includes(initRoom.toLowerCase())
          );
        }
        const index = roomsWithThumbs.findIndex(
          obj => obj.FullPath === filteredRoomsFolder[initRoomFolderIndex].files[initRoomFileIndex]
        );
        dispatch({
          type: visualizationsActions.SET_ACTIVE_NODE,
          payload: roomsWithThumbs[index]
        });

        dispatch({
          type: visualizationsActions.SET_SIMILAR_ROOMS,
          payload: filteredRoomsFolder[initRoomFolderIndex].files
        });
      } else {
        dispatch({ type: visualizationsActions.SET_ACTIVE_NODE, payload: roomsWithThumbs[ind] });
      }
    };
    handleFetchRooms();
  });
  const [debouncedThumbnailClick] = useDebouncedCallback((node, e) => {
    handleThumbnailClick(node, e);
  }, 400);
  const handleThumbnailClick = node => {
    {
      window.flags.homeTemplate === pageViews.CREATEYOURRUG &&
        scrollIntoView(".cyr-roomViewContainer");
    }
    if (node) {
      ReactGA.ga("send", "event", {
        eventCategory: "View", //required
        eventAction: "Clicked Room Thumbnail", //required
        eventLabel: `Clicked on ${node.FullPath}`
      });
    }
    //console.log(node.FullPath)
    // AppAnalytics.sendDesignDetails({ roomPath: node.FullPath }).then(status => {
    //   //console.log("AppAnalytics.sendDesignDetails ~ status", status);
    // });
    dispatch({ type: visualizationsActions.SET_ACTIVE_NODE, payload: node });
    dispatch({ type: visualizationsActions.SET_VIEW_MODE, payload: visViewModes.ROOM_VIEW });
  };
  const handleLoadMyRoom = (node, e) => {
    ReactGA.ga("send", "event", {
      eventCategory: "View", //required
      eventAction: "Clicked My Room", //required
      eventLabel: "Clicked on My Room"
    });
    // AppAnalytics.sendDesignDetails({ roomPath: "Myroom" })
    //   .then(status => {
    //     //console.log("AppAnalytics.sendDesignDetails ~ status", status);
    //   })
    //   .catch(error => {
    //     console.log(" AppAnalytics: send my room clicked Details ~ error", error);
    //   });
    dispatch({ type: visualizationsActions.SET_VIEW_MODE, payload: visViewModes.MY_ROOM });
  };
  const handleMyRoomThumbnailClick = node => {
    dispatch({ type: visualizationsActions.SET_ACTIVE_NODE, payload: node });
    dispatch({ type: visualizationsActions.SET_VIEW_MODE, payload: visViewModes.ROOM_VIEW });
  };
  const getRoomName = item => {
    var roomName = item.label;
    if (!window.flags.language.translateNames) return roomName;
    if (strings.names && strings.names.hasOwnProperty(roomName.toUpperCase())) {
      roomName = strings.names[roomName.toUpperCase()] || roomName;
    }
    return roomName;
  };
  return (
    <>
      <CollapsibleSectionHeading
        title={strings.visualization.illustrationViews}
        isOpen={!isCollapsed}
        handleCollapseIconClick={() => setIsCollapsed(!isCollapsed)}
        handleHeadingClick={() => setIsCollapsed(!isCollapsed)}
      />
      <div className="at-section-container at-vis-thumbs">
        <Collapse isOpen={!isCollapsed}>
          <div className="thumb-container">
            {visualizations.keepDesignTopViewInRoomlist && <DesignTopViewThumb />}
            {state.illustrations.map((item, i) => (
              <Thumbnail
                thumbType="ROOM_THUMBS"
                datatitle={getRoomName(item)}
                active={
                  state.activeNode.id === item.id && state.viewMode === visViewModes.ROOM_VIEW
                }
                thumb={{ ...item, name: getRoomName(item), thumbUrl: item.Thumb }}
                aspect="landscape"
                key={i}
                onThumbnailClick={e => debouncedThumbnailClick(item, e)}
              />
            ))}
            {visualizations.myRoomEnabled && pageView !== pageViews.CONTROLLER && (
              <Thumbnail
                thumbType="MY_ROOM"
                datatitle={strings.myRoom.myRoom}
                active={state.viewMode === visViewModes.MY_ROOM}
                thumb={{ name: strings.myRoom.myRoom, thumbUrl: myroomThumbUrl }}
                aspect="landscape"
                onThumbnailClick={handleLoadMyRoom}
              />
            )}
            {myroomState.savedRooms.map((savedRoom, i) => (
              <Thumbnail
                key={i}
                active={state.viewMode === visViewModes.ROOM_VIEW}
                thumb={savedRoom}
                aspect="landscape"
                onThumbnailClick={() => handleMyRoomThumbnailClick(savedRoom)}
              />
            ))}
          </div>
        </Collapse>
      </div>
      {window.flags.catalogViews && window.flags.catalogViews.length && (
        <>
          <CollapsibleSectionHeading
            title={"Catalog Views"}
            isOpen={!isCollapsed}
            handleCollapseIconClick={() => setIsCollapsed(!isCollapsed)}
            handleHeadingClick={() => setIsCollapsed(!isCollapsed)}
          />
          <div className="at-section-container at-vis-thumbs">
            <div className="thumb-container">
              {window.flags.catalogViews.map(item => (
                <Thumbnail
                  thumbType="ROOM_THUMBS"
                  thumb={item}
                  active={state.viewMode === visViewModes.CATALOG_VIEW}
                  aspect="landscape"
                  onThumbnailClick={e => {
                    dispatch({ type: visualizationsActions.SET_ACTIVE_NODE, payload: item });
                    dispatch({
                      type: visualizationsActions.SET_VIEW_MODE,
                      payload: visViewModes.CATALOG_VIEW
                    });
                  }}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RoomList;
