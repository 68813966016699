/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from "react";
import SidePanel from "../SidePanel";
import { Tabs, Tab, H5 } from "@blueprintjs/core";
import DesignList from "../../organisms/DesignList";
import Favorites from "../../organisms/Favorites";
import strings from "../../../strings";
import AtButton from "../../atoms/AtButton";
import {
  useUiState,
  useUiDispatch,
  mainUiActions,
  pageViews
} from "../../../reducers/mainui.reducer";
import { useVisualizationState, visViewModes } from "../../../reducers/visualizations.reducer";
import SearchResults from "../../organisms/SearchResults";
import SearchBar from "../../molecules/SearchBar";
import AtIcon from "../../atoms/AtIcon";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import DesignSearchBar from "../../organisms/DesignSearch/DesignSearchBar";
import DesignSearchResults from "../../organisms/DesignSearch/DesignSearchResults";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import IllustrationList from "../../organisms/IllustrationList";
import BrandLogo from "../../molecules/BrandLogo";
import CopyrightSection from "../../molecules/CopyrightSection";
import OrdersheetButton from "../OrderSheet/OrdersheetBtn";
import AccountLinks from "../../organisms/AccountLinks";
import { getFromSessionStorage } from "../../../utils/domUtils";
import { getnewIconIfReplaceable } from "../../../middleware/visualization.middleware";
import { showOrdersheetOutside } from "../../../utils/flagUtils";
const OrderSheet = React.lazy(() => import("../OrderSheet"));

const LeftSidebarTabed = props => {
  const { eCatalogDetails } = window.initialData;
  const defTab = window.flags.defaultSelectedTab || 0;
  const defaultSelectedTabInRoomView = window.flags.defaultSelectedTabInRoomView;
  const { collectionTab, artworkTab } = window.flags.leftSidebarTabs;
  const uiState = useUiState();
  const dispatchuiState = useUiDispatch();
  const visualizationState = useVisualizationState();
  const { showLeftSidebar, isIdle } = uiState;
  const [textToSearch, setTextToSearch] = useState("");
  const [showSearchResults, setShowSearchResults] = useState("none");
  const designListState = useDesignListState();
  const { sizeFilter, tagFilter } = designListState;

  const getSelectedTab = () => {
    let defaultTab = 0;
    if (
      eCatalogDetails ||
      visualizationState.viewMode === visViewModes.MY_ROOM ||
      visualizationState.viewMode === visViewModes.ROOM_VIEW
    ) {
      defaultTab = 1;
      if (
        visualizationState.viewMode === visViewModes.ROOM_VIEW &&
        !eCatalogDetails &&
        defaultSelectedTabInRoomView !== defaultTab
      ) {
        defaultTab =
          defaultSelectedTabInRoomView !== undefined && defaultSelectedTabInRoomView !== null
            ? defaultSelectedTabInRoomView
            : defaultTab;
      }
    } else {
      defaultTab = defTab;
    }
    return defaultTab;
  };
  const [selectedTab, setSelectedTab] = useState(getSelectedTab());

  const handleToggleSidebar = () => {
    dispatchuiState({ type: mainUiActions.SET_SHOW_SIDEBAR, payload: { left: !showLeftSidebar } });
  };

  const handleSearch = (e, type) => {
    let text = e.target.value;
    setTextToSearch(text);
    if (text.length > 2) {
      setShowSearchResults(type);
    } else if (text === "") {
      if (type !== "design") {
        setShowSearchResults("none");
      } else {
        if (!sizeFilter.find(item => !!item.selected)) setShowSearchResults("none");
        //else if (tagFilter === "") setShowSearchResults("none");
        else {
          setShowSearchResults(type);
        }
      }
      setTextToSearch("");
    }
  };
  const handleCancel = () => {
    setTextToSearch("");
    if (tagFilter === "") setShowSearchResults("none");
  };
  useEffect(() => {
    let tabs = ["design", "visualization", "none"];
    if (tagFilter !== "") {
      if (selectedTab === 0) setShowSearchResults(tabs[selectedTab]);
      else setShowSearchResults("none");
    } else if (showSearchResults !== "none") {
      setShowSearchResults(tabs[selectedTab]);
    } else {
      setTextToSearch("");
    }
    // if (showSearchResults !== "none") {
    //   setShowSearchResults(tabs[selectedTab]);
    // } else {
    //   setTextToSearch("");
    // }
  }, [selectedTab]);

  useEffect(() => {
    if (tagFilter === "") setShowSearchResults("none");
    else if (selectedTab === 0 && tagFilter !== "") {
      setShowSearchResults("design");
    }
  }, [tagFilter]);

  const handleExpandDesigns = () => {
    dispatchuiState({ type: mainUiActions.SET_PAGE_VIEW, payload: pageViews.FULL_PAGE_THUMB });
  };
  const handleFilterDesigns = (filter, e) => {
    if (!filter && textToSearch.length <= 2) {
      setShowSearchResults("none");
    }
    if (showSearchResults !== "design") setShowSearchResults("design");
  };

  return (
    <SidePanel id="left-sidebar" side="left" show={showLeftSidebar} isIdle={isIdle}>
      <AtButton
        className="at-sidebar__togglebtn togglebtn-left"
        icon={`panel-to-the-${showLeftSidebar ? "left" : "right"}`}
        onClick={handleToggleSidebar}
        text={strings.icontext.sidebarToggleLeft || null}
      />
      {getFromSessionStorage("showlogo") !== 0 && (
        <div className="at-sidebar__content__logo">
          <BrandLogo />
          <div className="logo-tagline">{strings.tagline}</div>
        </div>
      )}

      {window.flags.linksToOtherAccounts.length > 0 && (
        <AccountLinks accountlinks={window.flags.linksToOtherAccounts}></AccountLinks>
      )}
      {!!uiState.showOrderSheet &&
      window.flags.ordersheet.panel === "left" &&
      !showOrdersheetOutside() ? (
        <Suspense fallback={<></>}>
          <OrderSheet />
        </Suspense>
      ) : (
        <div className="at-sidebar__content">
          <Tabs
            className="bp3-focus-disabled at-sidebar__content__tabs at-sidebar-tabs"
            selectedTabId={`tab-${selectedTab}`}
          >
            {!eCatalogDetails && (
              <Tab
                id="tab-0"
                className="at-designs-tab"
                title={
                  <>
                    <H5>
                      {strings.design.designs}
                      {flaginCurrentMode(
                        window.InterfaceElements.IsAdmin,
                        window.flags.fullPageThumb
                      ) && (
                        <AtIcon
                          title="Show all designs"
                          icon={getnewIconIfReplaceable("expand")}
                          onClick={handleExpandDesigns}
                        />
                      )}
                    </H5>
                  </>
                }
                onClickCapture={() => setSelectedTab(0)}
                panel={
                  <div className="at-scroll-on-hover">
                    <DesignSearchBar
                      handleInputChange={e => handleSearch(e, "design")}
                      placeholder={strings.design.searchDesigns}
                      textToSearch={textToSearch}
                      handleCancel={handleCancel}
                      handleFilter={handleFilterDesigns}
                      type="design"
                    />
                    <DesignSearchResults
                      hidden={showSearchResults !== "design"}
                      id="designSearchResults"
                      textToSearch={textToSearch}
                      tagToSearch={tagFilter}
                      type="design"
                    />
                    <DesignList hidden={showSearchResults === "design"} />
                  </div>
                }
              />
            )}
            {collectionTab && (
              <Tab
                id="tab-3"
                className="at-collection-tab"
                title={<H5>{strings.design.collections}</H5>}
                onClickCapture={() => {
                  setSelectedTab(3);
                }}
                panel={
                  <div>
                    <DesignSearchBar
                      handleInputChange={e => handleSearch(e, "design")}
                      placeholder={strings.design.searchDesigns}
                      textToSearch={textToSearch}
                      handleCancel={handleCancel}
                      handleFilter={handleFilterDesigns}
                      type="design"
                    />
                    <DesignSearchResults
                      hidden={showSearchResults !== "design"}
                      id="designSearchResults"
                      textToSearch={textToSearch}
                      type="design"
                    />
                    <DesignList primaryList={false} />
                  </div>
                }
              />
            )}

            {artworkTab && (
              <Tab
                id="tab-4"
                className="at-favorites-tab"
                title={<H5>{strings.favorite.favorites}</H5>}
                onClickCapture={() => {
                  setSelectedTab(4);
                }}
                panel={
                  <div>
                    <DesignSearchBar
                      handleInputChange={e => handleSearch(e, "design")}
                      placeholder={strings.design.searchDesigns}
                      textToSearch={textToSearch}
                      handleCancel={handleCancel}
                      handleFilter={handleFilterDesigns}
                      type="design"
                    />
                    <DesignSearchResults
                      hidden={showSearchResults !== "design"}
                      id="designSearchResults"
                      textToSearch={textToSearch}
                      type="design"
                    />
                    <DesignList primaryList={false} />
                  </div>
                }
              />
            )}
            <Tab
              id="tab-1"
              className="at-visualization-tab"
              title={<H5>{strings.visualization.visualizations}</H5>}
              onClickCapture={() => setSelectedTab(1)}
              panel={
                <div className="at-scroll-on-hover">
                  <SearchBar
                    handleInputChange={e => handleSearch(e, "visualization")}
                    placeholder={strings.visualization.searchVisualizations}
                    textToSearch={textToSearch}
                    handleCancel={handleCancel}
                    type="visualization"
                  />
                  <SearchResults
                    hidden={showSearchResults !== "visualization"}
                    id="visualizationSearchResults"
                    textToSearch={textToSearch}
                    type="visualization"
                  />
                  <div
                    className={`at-sidebar-tabs__view-container ${
                      showSearchResults === "visualization" ? "hidden" : ""
                    } `}
                  >
                    <IllustrationList />
                  </div>
                </div>
              }
            />
            {window.sessionStorage.getItem("mode") !== "stripedesign" && window.flags.hasFavorites && (
              <Tab
                id="tab-2"
                title={<H5>{strings.favorite.favorites}</H5>}
                onClickCapture={() => setSelectedTab(2)}
                panel={
                  <>
                    <Favorites></Favorites>
                  </>
                }
              />
            )}
          </Tabs>
        </div>
      )}

      <div className="at-sidebar__footer">
        {window.flags.hasOrderSheet &&
          window.flags.ordersheet.panel === "left" &&
          !uiState.showOrderSheet && <OrdersheetButton />}

        <CopyrightSection />
      </div>
    </SidePanel>
  );
};

export default LeftSidebarTabed;
