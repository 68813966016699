import React, { useState } from "react";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import { H4, MenuItem, Menu, Popover, Icon } from "@blueprintjs/core";
import { getDesignDimensionsString, getDesignPathInTitle } from "../../../utils/utils";

import { getFromSessionStorage } from "../../../utils/domUtils";
import { useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import strings from "../../../strings";
import DesignDetailControls from "./DesignDetailControls";
import PriceOrDimension from "../../molecules/PriceOrDimension";
import { renderDesignName } from "../../../utils/stringUtils";
import { getDesignName } from "../../../utils/treeutils";
import DesignStory from "../../molecules/DesignStory";

const DesignDetailSection = props => {
  const { price, handleRugShare, handleFavorite, handleDownloadImage } = props;
  const { flags, InterfaceElements } = window;
  const designDetailState = useDesignDetailState();
  const dispatchUiState = useUiDispatch();
  const { designDetails, designName, fullpath } = designDetailState;

  let langOptions = flags.language.langOptions;
  let langFromUrl = getFromSessionStorage("lang");
  let defLang = langFromUrl && langFromUrl !== "" ? langFromUrl : "en";
  if (langOptions && langOptions.length) {
    defLang = langFromUrl && langFromUrl !== "" ? langFromUrl : strings.getLanguage() || null;
    if (!defLang) {
      defLang = "en";
      langOptions = [];
      window.flags.language.langOptions = [];
    }
  }
  const [currentLanguage, setCurrentLanguage] = useState(defLang);

  const renderTitleHeading = (
    replaceDesignNameTildeWithNextLine,
    showDesignPathInTitle,
    fullpath
  ) => {
    return replaceDesignNameTildeWithNextLine ? (
      <H4
        className="at-des-details__title__heading"
        dangerouslySetInnerHTML={{
          __html: showDesignPathInTitle
            ? getDesignPathInTitle(fullpath)
            : renderDesignName(designName, "<br/>")
        }}
      ></H4>
    ) : (
      <H4 className="at-des-details__title__heading">
        {showDesignPathInTitle ? getDesignPathInTitle(fullpath) : renderDesignName(designName)}
      </H4>
    );
  };

  const showPrice =
    flags.hasOrderSheet &&
    (InterfaceElements.ShowPrice || window.flags.designDetailSection.showDesignExhibitionPrice) &&
    parseFloat(price.Value) > 0;
  const showDesignInfo = designDetails.DesignInfo && designDetails.DesignInfo !== "";
  const handleLanguageOptionChange = i => {
    const newLanguage = flags.language.langOptions[i].val;
    setCurrentLanguage(newLanguage);
    dispatchUiState({ type: mainUiActions.SET_LANGUAGE, payload: newLanguage });
    strings.setLanguage(newLanguage);
    if (langFromUrl && langFromUrl !== "") sessionStorage.setItem("lang", newLanguage);
  };
  const hasLangOptions = flags.language.langOptions.length && flags.language.langOptions[0] !== "";

  const getCurrentLanguageText = () => {
    var index = flags.language.langOptions.findIndex(item => item.val === currentLanguage);
    const currentLanguageText = flags.language.langOptions[index].text;
    return currentLanguageText;
  };
  return (
    <>
      <div className="at-des-details__title">
        {renderTitleHeading(
          flags.designDetailSection.designNameTildeWithNextLine,
          flags.designDetailSection.showDesignPathInTitle,
          fullpath
        )}

        {hasLangOptions ? (
          <div className="at-des-details__title__languageDropdown">
            {/* <AtDropdown
              dropdownItems={flags.language.langOptions.map(language => language.text)}
              defaultItemIndex={flags.language.langOptions.findIndex(
                item => item.val === currentLanguage
              )}
              onChange={i => handleLanguageChange(i)}
            /> */}
            <Popover
              boundary="viewport"
              content={
                <Menu className="langoptions-menu">
                  {flags.language.langOptions.map((language, i) => (
                    <MenuItem
                      className="langOptions-menuitem"
                      key={i}
                      text={language.text}
                      onClick={e => handleLanguageOptionChange(i)}
                    />
                  ))}
                </Menu>
              }
              minimal
              position="bottom-left"
              portalContainer={document.getElementById("app-main")}
            >
              <div className="at-des-langOptions">
                <span className="bp3-heading">{getCurrentLanguageText()}</span>
                <Icon icon="caret-down" />
              </div>
            </Popover>
          </div>
        ) : null}

        <DesignDetailControls
          handleRugShare={handleRugShare}
          handleFavorite={handleFavorite}
          handleDownloadImage={handleDownloadImage}
          parentID="at-des-details__title"
        ></DesignDetailControls>
        {flags.accountSpecific.jaipurrugs.showCodeUnderDesignName && fullpath && (
          <p>
            {" "}
            CODE: &nbsp;
            {getDesignName(fullpath).split("~")[0]}
          </p>
        )}
      </div>

      {/* {!flags.colorSeparatedTexture &&
        InterfaceElements.IsAdmin &&
        designDetailState.designDetails.DesignColors && (
          <div className="at-des-details__tex-sel">
            <H5 style={{ marginBottom: "0.5rem" }}>View Design As</H5>
            <AtDropdown
              dropdownItems={Object.entries(flags.textures).map(item => item[1])}
              onChange={handleTextureChange}
              defaultItemIndex={designDetailState.designDetails.DesignColors[0].Material}
            />
          </div>
        )} */}
      {flags.interface.showStory.design && (
        <>
          <DesignStory />
        </>
      )}

      <PriceOrDimension
        showPrice={showPrice}
        price={price}
        strInPrice={flags.ordersheet.strInPrice}
        showDesignDimensions={flags.designDetailSection.showDesignDimensions}
        designDimensionsStr={getDesignDimensionsString({
          designDetails: designDetails,
          showDesignDimensions: flags.designDetailSection.showDesignDimensions,
          showOneDimensionIfSquare: flags.designDetailSection.showOneDimensionIfSquare,
          showDimensionInInches: flags.designDetailSection.showDimensionInInches
        })}
      ></PriceOrDimension>

      {window.flags.designDetailSection.blockDesignName && (
        <div>
          {renderTitleHeading(
            flags.designDetailSection.designNameTildeWithNextLine,
            flags.designDetailSection.showDesignPathInTitle,
            fullpath
          )}
          {/* <H4 className="at-des-details__title__heading">
            {flags.designDetailSection.showDesignPathInTitle
              ? getDesignPathInTitle(fullpath)
              : renderDesignName()}
          </H4> */}
        </div>
      )}
      {showDesignInfo && (
        <div className="at-des-details__design-info">{designDetails.DesignInfo}</div>
      )}
    </>
  );
};
export default DesignDetailSection;
