import AppProvider from "../api/appProvider";
import { getFromSessionStorage, readImage } from "./domUtils";

export const createCanvas = (w, h) => {
  var canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  return canvas;
};

export function applyEdgeFilter(canvas, image) {
  const pix = 4;
  const blur = "2px";
  const brightness = "50%";

  const w = canvas.width,
    h = canvas.height;

  var designCtx = canvas.getContext("2d");

  var filterCanvas = createCanvas(w, h);
  var designfilterCtx = filterCanvas.getContext("2d");
  designfilterCtx.filter = `blur(${blur}) brightness(${brightness})`;
  designfilterCtx.drawImage(image, 0, 0, w, h);

  const designfilterData = designfilterCtx.getImageData(0, 0, pix, h);
  designCtx.putImageData(designfilterData, 0, 0);

  const designfilterData2 = designfilterCtx.getImageData(0, 0, w, pix);
  designCtx.putImageData(designfilterData2, 0, 0);

  const designfilterData3 = designfilterCtx.getImageData(0, h - pix, w, pix);
  designCtx.putImageData(designfilterData3, 0, h - pix);

  const designfilterData4 = designfilterCtx.getImageData(w - pix, 0, pix, h);
  designCtx.putImageData(designfilterData4, w - pix, 0);
  return canvas;
}
export function applyBWMask(maskCanvas, imageUrl, maskUrl, callback) {
  //console.log(imageUrl, maskUrl);
  const { width, height } = maskCanvas;
  const tempCanvas = createCanvas(width, height);
  const maskCtx = maskCanvas.getContext("2d");
  const tempCtx = tempCanvas.getContext("2d");

  const img = new Image();
  img.src = imageUrl;
  img.crossOrigin = "Anonymous";
  img.onload = () => {
    maskCanvas.width = width;
    maskCanvas.height = height;
    maskCtx.drawImage(img, 0, 0, width, height);
    const maskImg = new Image();
    maskImg.src = maskUrl;
    maskImg.crossOrigin = "Anonymous";
    maskImg.onload = () => {
      tempCanvas.width = width;
      tempCanvas.height = height;
      tempCtx.drawImage(maskImg, 0, 0, width, height);

      let imgData = maskCtx.getImageData(0, 0, width, height);
      let maskData = tempCtx.getImageData(0, 0, width, height);

      for (var i = 0; i < maskData.data.length; i += 4) {
        imgData.data[i + 3] = 255 - maskData.data[i];
      }
      maskCtx.putImageData(imgData, 0, 0);
      callback(maskCanvas);
    };
  };
}
export const applyMask = (canvas, imgUrl, maskUrl) => {
  const { width, height } = canvas;
  const tempCanvas = createCanvas(width, height);
  const ctx = canvas.getContext("2d");
  const tempCtx = tempCanvas.getContext("2d");

  const readUrls = [readImage(imgUrl), readImage(maskUrl)];
  return Promise.all(readUrls).then(images => {
    const image = images[0];
    const mask = images[1];

    ctx.drawImage(image, 0, 0, width, height);
    tempCtx.drawImage(mask, 0, 0, width, height);

    let imgData = ctx.getImageData(0, 0, width, height);
    let maskData = tempCtx.getImageData(0, 0, width, height);

    for (let i = 0; i < maskData.data.length; i += 4) {
      imgData.data[i + 3] = 255 - maskData.data[i];
    }
    ctx.putImageData(imgData, 0, 0);
  });
};

export const bnwToTransparency = (canvas, bnwUrl, amount) => {
  const { width, height } = canvas;
  const ctx = canvas.getContext("2d");

  const image = new Image();
  image.src = bnwUrl;
  image.crossOrigin = "Anonymous";
  image.onload = function() {
    ctx.drawImage(image, 0, 0, width, height);

    let imageData = ctx.getImageData(0, 0, width, height);
    for (var i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i + 3] = (255 - imageData.data[i]) * amount;
      imageData.data[i] = 0;
      imageData.data[i + 1] = 0;
      imageData.data[i + 2] = 0;
    }
    ctx.putImageData(imageData, 0, 0);
  };
};

export const resizeCanvas = (canvas, width, height) => {
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
};
export const clearCanvas = (canvas, w, h) => {
  canvas.getContext("2d").clearRect(0, 0, w, h);
};
export const downloadImageData = (canvas, name, mime) => {
  const mimetype = mime === "jpg" ? "jpeg" : mime;
  const downloadBlob = blob => {
    var url = URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.href = url;
    anchor.setAttribute("download", name);
    let pageview = getFromSessionStorage("pageview") || "";
    if (pageview.indexOf("app") !== -1 && window.isNativeApp) {
      const imageFileName = name.substr(0, name.lastIndexOf("."));
      if (window.callWebView) {
        AppProvider.uploadRoomviewBlob({ blob: blob }).then(response => {
          const data = {
            image: response,
            fileName: imageFileName,
            type: mime
          };
          window.callWebView(JSON.stringify(data));
        });
      }
    } else {
      setTimeout(function() {
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, name);
        } else {
          anchor.click();
        }
      }, 100);
    }
  };
  const type = `image/${mimetype}`;
  if (canvas.toBlob) {
    canvas.toBlob(downloadBlob, type, 0.95);
    return;
  }
  const dataurl = canvas.toDataURL(type, 0.95);
  downloadBlob(dataURItoBlob(dataurl));
};

// const downloadCanvasFromDataurl = (dataurl, name) => {
//   readImage(dataurl).then(image => {
//     const testCanvas = createCanvas(image.width, image.height);
//     const ctx = testCanvas.getContext("2d");
//     ctx.drawImage(image, 0, 0, image.width, image.height);

//     downloadImageData(testCanvas, name, "jpg");

//     return testCanvas;
//   });
// };
export const canvasToBlobPromise = (canvas, imageQuality = 0.75) =>
  new Promise((resolve, reject) => {
    if (canvas.toBlob) canvas.toBlob(resolve);
    if (canvas.msToBlob) canvas.msToBlob(resolve);
    let dataurl = canvas.toDataURL("image/jpeg", imageQuality);
    // downloadCanvasFromDataurl(dataurl, "testImage"); //testing size of canvases
    // let dataurl2 = canvas.toDataURL();
    // downloadCanvasFromDataurl(dataurl2, 'test2image');
    resolve(dataURItoBlob(dataurl));
  });
export const dataURItoBlob = (dataURI)=> {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const dataURLtoBlobURL = dataurl => {
  return new Promise(resolve => {
    let blob = dataURItoBlob(dataurl);
    const url = URL.createObjectURL(blob);
    resolve(url);
  });
};
/**
 * * Stitch canvas to crop so that artifacts do not appear
 *
 */
export const cropStitchCanvas = ({ origCanvas, canvas }) => {
  const { width: canvasWidth, height: canvasHeight } = origCanvas;
  const origCtx = origCanvas.getContext("2d");
  const { width, height } = canvas;
  const ctx = canvas.getContext("2d");

  if (width === canvasWidth && height === canvasHeight) {
    ctx.drawImage(origCanvas, 0, 0);
    return;
  }
  const overlapSize = 20;
  const overlapsx = canvasWidth - width / 2 - overlapSize;
  const overlapsy = canvasHeight - height / 2 - overlapSize;
  const wid = width / 2;
  const hgt = height / 2;
  const tl = origCtx.getImageData(0, 0, wid, hgt);
  const tr = origCtx.getImageData(overlapsx, 0, wid + overlapSize, hgt);
  const bl = origCtx.getImageData(0, overlapsy, wid, hgt + overlapSize);
  const br = origCtx.getImageData(overlapsx, overlapsy, wid + overlapSize, hgt + overlapSize);
  const trCanvas = createCanvas(tr.width, tr.height);
  const trCtx = trCanvas.getContext("2d");
  trCtx.putImageData(tr, 0, 0);
  trCtx.globalCompositeOperation = "destination-out";
  let trgrd = trCtx.createLinearGradient(0, 0, overlapSize, 0);
  trgrd.addColorStop(0, "black");
  trgrd.addColorStop(1, "transparent");
  trCtx.fillStyle = trgrd;
  trCtx.fillRect(0, 0, overlapSize, tr.height);
  // document.body.appendChild(trCanvas);

  const blCanvas = createCanvas(bl.width, bl.height);
  const blCt = blCanvas.getContext("2d");
  blCt.putImageData(bl, 0, 0);
  blCt.globalCompositeOperation = "destination-out";
  let blgrd = blCt.createLinearGradient(0, 0, 0, overlapSize);
  blgrd.addColorStop(0, "black");
  blgrd.addColorStop(1, "transparent");
  blCt.fillStyle = blgrd;
  blCt.fillRect(0, 0, bl.width, overlapSize);
  // document.body.appendChild(blCanvas);

  const brCanvas = createCanvas(br.width, br.height);
  const brCt = brCanvas.getContext("2d");
  brCt.putImageData(br, 0, 0);
  brCt.globalCompositeOperation = "destination-out";
  let brgrd = brCt.createLinearGradient(0, 0, 0, overlapSize);
  brgrd.addColorStop(0, "black");
  brgrd.addColorStop(1, "transparent");
  brCt.fillStyle = brgrd;
  brCt.fillRect(0, 0, br.width, overlapSize);

  let brgrd1 = brCt.createLinearGradient(0, 0, overlapSize, 0);
  brgrd1.addColorStop(0, "black");
  brgrd1.addColorStop(1, "transparent");
  brCt.fillStyle = brgrd1;
  brCt.fillRect(0, 0, overlapSize, bl.height);

  ctx.putImageData(tl, 0, 0);
  ctx.drawImage(trCanvas, width - tr.width, 0);
  ctx.drawImage(blCanvas, 0, height - bl.height);
  ctx.drawImage(brCanvas, width - br.width, height - br.height);
};
