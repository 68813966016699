import AppProvider from "../api/appProvider";
import { calculateZoomLevelFromZoom } from "../components/organisms/Visualization/visualizationutils";
import { findWhere } from "../utils/arrayUtils";
import { downloadImageData } from "../utils/canvasutils";
import { MD5 } from "../utils/md5";
import { isMobileDevice } from "../utils/utils";

export  const generateHash = (details, fullpath = "") => {
  const des = JSON.stringify(details);
  // const key = sessionStorage.getItem("apikey")
  return MD5(des + fullpath);
};
export const resolveZoomValue = () => {
  let zoom = window.flags.designView.defaultZoom;
  if (AppProvider.isStripedDesignMode || (isMobileDevice && window.flags.designView.zoomForMobile))
    zoom = 0.75;
  else if (window.InterfaceElements.IsJpeg) {
    zoom =
      window.flags.designView.defaultZoom <= window.flags.designView.maxZoomJpeg
        ? window.flags.designView.defaultZoom
        : 0.75;
  }
  return zoom;
};

const isSmallerThan = (value, maxvalue) => {
  var x = value <= maxvalue ? value : maxvalue;
  return x;
};
const isGreaterThan = (value, minvalue) => {
  var x = value >= minvalue ? value : minvalue;
  return x;
};
const minZoomForJpeg = (minZoomByFlag, defaultZoom) => {
  const zoom =
    isGreaterThan(minZoomByFlag, 0) && isSmallerThan(minZoomByFlag, defaultZoom)
      ? minZoomByFlag
      : 0;
  return zoom;
};

export const getZoomParameters = () => {
  const isJpeg = window.InterfaceElements.IsJpeg;
  const w2w = window.InterfaceElements.IsWallToWall;
  const minZoom = window.flags.designView.minZoom;
  const maxZoom = window.flags.designView.maxZoom;
  const defaultZoom = window.flags.designView.defaultZoom;
  const minZoomJpeg = window.flags.designView.minZoomJpeg;
  const maxZoomJpeg = window.flags.designView.maxZoomJpeg;

  let zoomValues = {
    minZoom: isJpeg && w2w ? 0.5 : isJpeg ? minZoomForJpeg(minZoomJpeg, defaultZoom) : minZoom,
    maxZoom: window.InterfaceElements.IsJpeg ? maxZoomJpeg : maxZoom,
    step: window.InterfaceElements.IsJpeg ? 0.1 : 0.25
  };
  window.flags.designView.minZoom = zoomValues.minZoom;
  window.flags.designView.maxZoom = zoomValues.maxZoom;

  return zoomValues;
};

export const getnewIconIfReplaceable = value => {
  const replaceableIconList = window.flags.interface?.iconsReplace;
  let newIcon = value;
  if (replaceableIconList && replaceableIconList.length) {
    const icon = findWhere(replaceableIconList, "orig", value);
    if (icon) {
      newIcon = icon.new;
    }
  }
  return newIcon;
};

export const handleDownloadDesign = async ({
  zoomlevel,
  watermarkOptions,
  designDetails,
  designName,
  fullpath,
  hash,
  felt
}) => {
  //const zoomlevel = calculateZoomLevelFromZoom(zoom, clampZoom);
  // const watermarkOptions = {
  //   hasWatermark: window.flags.designView.hasDesignWaterMark,
  //   logoUrl: window.InterfaceElements.LogoUrl,
  //   ...window.flags.designView.watermarkOptions
  // };
  return AppProvider.getRenderedDesign({
    designDetails,
    designName,
    fullpath,
    hash,
    felt,
    zoom: zoomlevel === 4 ? 2 : zoomlevel,
    watermarkOptions
    // logoUrl: window.flags.designView.hasDesignWaterMark ? window.InterfaceElements.LogoUrl : null
  }).then(canvas => {
    designDetails.IsIrregular
      ? downloadImageData(canvas, `${designName}.png`, "png")
      : downloadImageData(canvas, `${designName}.jpg`, "jpg");
  });
};

export const getDesigns = async ({ folder }) => {
console.log("getDesigns -> folder", folder);
  const nestedDesignList = await AppProvider.fetchDesignList({ struct: false });
  let origFiles = nestedDesignList
    .filter(file => file.Type === "file" && file.FullPath.indexOf("/.") === -1)
    .map(file => {
      return { ...file, fullPath: file.FullPath, name: file.Name };
    });
  if (folder) {
    const foldername = folder.toLowerCase();
    origFiles = origFiles.filter(file => {
      if (file.FullPath.toLowerCase().indexOf(foldername) >= 0) return file;
    });
  }
  //console.log("window.getDesigns= -> origFiles", origFiles);
  return origFiles;
};