import React, { useState } from "react";
import { InputGroup, FormGroup, Card, H6, H4 } from "@blueprintjs/core";
import AppProvider, { CDN_domain } from "../../../api/appProvider";
import AtButton from "../../atoms/AtButton";
import ReactDelayRender from "react-delay-render";
import Fade from "../../higher-order-components/Fade";
import { AtSpinner } from "../../atoms/AtSpinner";
import { useMount } from "react-use";

import classNames from "classnames";
import { getCustomClass } from "../../../utils/domUtils";
// const filemanagerDomains = [
//   "https://v3.explorug.com",
//   "https://v3rc/explorug.com",
//   "https://v3dev.explorug.com",
//   "http://localhost:3000"
// ];
const LoginPage = props => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSigningIn, setisSigningIn] = useState(false);

  const loginPage = sessionStorage.getItem("login") || "";
  const [logoSrc, setLogoSrc] = useState("https://explorug.net/new/explorug-logo.png");

  const [isLoading, setIsLoading] = useState(true);
  useMount(() => {
    if (loginPage === "") {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const image = new Image();
    image.src = CDN_domain+"v3assets/loginPageLogos/" + loginPage + ".jpg";
    image.onload = () => {
      setLogoSrc(image.src);
      setIsLoading(false);
    };
    image.onerror = () => {
      console.log("logo not found");
      setIsLoading(false);
    };
  });

  function handleSubmit(event) {
    event.preventDefault();
    setisSigningIn(true);
    AppProvider.fetchApiKey({ username, password })
      .then(key => {
        if (!key) {
          setIsError(true);
          setisSigningIn(false);
        } else {
          // if (inIframe()) {
          //   for (let domain of filemanagerDomains) {
          //     window.top.postMessage({ apiKey: key }, domain);
          //   }
          //   return;
          // }
          //replace forcelogin param if present and reload
          // window.history.replaceState &&
          //   window.history.replaceState(
          //     null,
          //     "",
          //     window.location.pathname +
          //       window.location.search.replace(/[?&]forcelogin+/, "").replace(/^&/, "?")
          //   );
          window.location.href = window.location.href
            .replace(/[?&]forcelogin+/, "")
            .replace(/^&/, "?");
          // window.location.reload();
        }
      })
      .catch(err => {
        setIsError(true);
        setisSigningIn(false);
      });
  }

  const getSpecificLoginPageClass = loginPage => {
    let loginPageClass = "";
    if (loginPage && loginPage !== "") {
      loginPageClass = `separate-loginpage ${loginPage.replace(/ /g, "")}-loginpage`;
    }
    return loginPageClass;
  };
  return (
    <div  className={classNames("app-main", getCustomClass())}>
      
      <div className={classNames("at-login-screen", getSpecificLoginPageClass(loginPage))}>
        <div className="at-promotionals">
          <Card>
            <H4>A refreshed experience</H4>
            <p>
              exploRUG now provides a better experience for your clients.
              <br />
              Besides the look and the features, this update also has significant performance
              improvements.
            </p>
            <AtButton
              text="Here's a walkthrough"
              intent="primary"
              tertiary
              onClick={() =>
                window.open(
                  "https://alternativetechnology.zendesk.com/hc/en-us/articles/900001316283-exploRUG-v3-Walkthrough"
                )
              }
            />
          </Card>
        </div>
        <div className="login-field">
          <div>
            <img
              className={classNames({ hidden: isLoading })}
              id="login-page-logo"
              src={logoSrc}
              alt="explorug logo"
              width="200"
            ></img>
            <H4 className="" style={{ margin: "1rem 0 1.5rem 0" }}>
              Sign in to your explorug
            </H4>
            <form onSubmit={handleSubmit} style={{ width: "20rem" }}>
              <FormGroup label="Username">
                <InputGroup
                  className="at-login-input"
                  type="text"
                  onChange={e => setUserName(e.target.value)}
                  autoFocus
                  onFocus={e => e.target.select()}
                />
              </FormGroup>
              <FormGroup label="Password">
                <InputGroup
                  className="at-login-input"
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  onFocus={e => e.target.select()}
                />
              </FormGroup>
              <Fade show={isError}>
                <div className={` bp3-callout bp3-intent-danger`} style={{ marginBottom: "1rem" }}>
                  Please recheck your username and password
                </div>
              </Fade>
              <AtButton intent="primary" type="submit">
                <AtSpinner
                  size="xs"
                  className={(isSigningIn ? "show " : "") + "signing-spinner "}
                />
                <H6 className={(isSigningIn ? "shift-right " : "") + "signin-span-text "}>
                  {isSigningIn ? "Signing in" : "Sign in"}
                </H6>
              </AtButton>
            </form>
          </div>
          <div className="privacy-policy" id="div-privacy">
            <a target="_blank" rel="noopener noreferrer"  href="https://explorug.net/PrivacyPolicy.php">
              Privacy Policy
            </a>
            <a target="_blank" rel="noopener noreferrer"  href="https://explorug.net/termsofuse.php">
              Terms of Use
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReactDelayRender({ delay: 0 })(LoginPage);
